import Connection from "../core/ApiConnection";

export class MediosService {

    async getList() {
        return await Connection.get('/medios').then(res => res.data.items);
    }

    async saveData(data) {
        return await Connection.post('/medios', data).then(res => res.data);
    }

    async updateDate(data) {
        return await Connection.put('/medios/' + data._ID, data).then(res => res.data);
    }

    async deleteData(data) {
        return await Connection.delete('/medios/' + data).then(res => res.data);
    }

    async deleteItems(data) {
        return await Connection.post('/medios/delete-items', data).then(res => res.data);
    }

}