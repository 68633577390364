import Connection from "../core/ApiConnection";

export class AuthService {
    async getLogin(data) {
        return await Connection.post( 'login', data ).then( res => res.data ); 
    }

    async getLogout() {
        return await Connection.post( 'logout' ).then( res => res.data );
    }

    async getCsrf() {
        return await Connection.get('/sanctum/csrf-cookie').then( res => res.data );
    }
    
}