import React, { useState, useEffect, useContext, createContext } from 'react';

export const authContext = createContext(null);

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={ auth }>{ children }</authContext.Provider>
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [auth, setAuth] = useState([]);
    const [checkPermissions, setCheckPermissions] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [role, setRole] = useState("");

    useEffect( () => {
        const user =  JSON.parse( localStorage.getItem('user') );
        
        if ( user !== null ) {
            const abilities = user._ABILITIES;
            const rol = user._ROLNAME[0];
            setAuth(user);
            setCheckPermissions(abilities);
            setRole(rol);
            setIsAuthenticated(true);
        }

    }, []);

    return [ auth, setAuth, isAuthenticated, checkPermissions, setCheckPermissions, role ];
}


