import React, { useState, useRef, useEffect } from 'react';
import LeftSidebar from '../../components/LeftSidebar';
import { backgroundColor, iconsColor, iconsTemplate } from '../../core/utils';
import { Dialog } from 'primereact/dialog';
import DesignsUpload from '../../components/DesignsUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toast } from 'primereact/toast';
import { toastAlert } from '../../core/utils';
import ExpandSidebar from '../../components/ExpandSidebar';
import ButtonsActions from '../../components/ButtonsActions';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { AssignmentService } from '../../service/AssignmentService';
import { Can } from '../../components/PermissionGate';
import { useAuth } from '../../hooks/useAuth';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';

function DesignUpload({ visible, hide, items, updateData, updateNotify }) {

    const service = new AssignmentService();
    const { 0:auth } = useAuth();

    const [openModalUpload, setOpenModalUpload] = useState(false);
    const [dataId, setDataId] = useState(null);
    const [titleModal, setTitleModal] = useState("");
    const [active, setActive] = useState(true);
    const [__current, setCurrentFormat] = useState(null);
    const toast = useRef(null);
    
    const [visibleFullScreen, setVisibleFullScreen] = useState(false);
    const [currentSocial, setCurrentSocial] = useState(null);
    const [currentItem, setCurrenteItem] = useState([]);
    const [openModalNotify, setOpenModalNotify] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [textNotify, setTextNotify] = useState("");

    const onOver = (data, social) => {
        let color = backgroundColor(social);
        data.classList.add(color);
    }

    const onLeave = (data, social) => {
        let color = backgroundColor(social);
        data.classList.remove(color);
    }

    const openFullScreen = (id, item, social, dimension) => {
        setDataId(id);
        setCurrenteItem(item);
        setCurrentSocial(social);
        setCurrentFormat(dimension);
        setVisibleFullScreen(true);
    }
    
    const hideFullScreen = () => {
        setVisibleFullScreen(false);
    }

    const selectionModalOpen = (id, items, socials, dimensions) => {
        const check = Object.values(items._UPLOADED).filter( (ft) => ft._FILEFORMAT === socials + " " + dimensions );
        
        if (check.length === 0) {
            openModal(items, socials, dimensions);
        } else {
            openFullScreen(id, check, socials, dimensions);
        }
    }

    useEffect( () => {
        verifyUploaded();
    }, [items]);

    const verifyUploaded = () => {
        let objectFormats  = items._FORMATS;
        let totalUploaded = items._UPLOADED;
        let totalFormats = 0;
        
        if ( objectFormats !== undefined && totalUploaded !== undefined ) {
            const vr = Object.values(objectFormats).map( (formats) => {
                totalFormats += formats.socialFormat.length;
            });
  
            if ( totalFormats === totalUploaded.length ) {
                setActive(false);
            } else {
                setActive(true);
            }

            return vr;
        }
    }
 
    const socialMedia = (

        (items._FORMATS !== undefined) ?
            Object.values(items._FORMATS).map( (format, i) => {
                const social = format['socialMedia'];
                const icon = iconsTemplate(social);
                let str = social.toUpperCase();

                return (
                    <div className="text-900 w-full md:flex-order-0 flex-order-1 align-items-center" key={i}>
                        {format.socialFormat.map((dimension, x) => (
                            <div 
                                key={x}
                                id={`div`+x}
                                className="
                                    border-1 
                                    p-4 mb-2 
                                    border-round 
                                    flex flex-column 
                                    md:flex-row 
                                    align-items-start 
                                    md:align-items-center 
                                    md:justify-content-between 
                                    cursor-pointer surface-card surface-border"
                                onMouseEnter={ (e) => onOver(e.target, social) }
                                onMouseLeave={ (e) => onLeave(e.target, social) }
                                onClick={ (e) => selectionModalOpen(items._IDASSIGNMET, items, social, dimension) }
                            >
                                <div className="flex align-items-start">
                                    {
                                        (social === 'TIKTOK') ? 
                                            <FontAwesomeIcon icon={['fab', 'tiktok']} className='mr-2' style={{ fontSize: '2em', color: iconsColor(social) }}/> :
                                            <i className={ `mr-2 pi ` + icon }  style={{ fontSize: '2em', color: iconsColor(social) }}></i>
                                    }
                                     
                                    <div>
                                        <div className="font-medium mb-2 text-900">{str}</div>
                                        <span className="p-1 border-round text-600">{dimension}</span>
                                    </div>
                                </div>
                                <div className="ml-0 md:ml-8 mt-2 md:mt-0 p-1 px-2 flex align-items-center  text-600">
                                    {
                                        (items._UPLOADED.some((ft) => ft._FILEFORMAT === social + " " + dimension) ) ?
                                            <FontAwesomeIcon icon={faCheckDouble} className='mr-2 text-xl text-green-600' /> :
                                            <i className="pi pi-upload mr-2 text-xl text-yellow-600"></i>
                                    }
                                    
                                </div>
                            </div>
                            
                        ))}
                    </div>
                );
            }) : null
    );

    const openModal = (data, social, format) => {
        setDataId(data);
        setTitleModal(social +' '+ format);
        setCurrentFormat(format);
        setOpenModalUpload(true);
    }

    const hideModal = () => {
        setOpenModalUpload(false);
    }

    const openModalNot = () => {
        setOpenModalNotify(true);
    }

    const hideModalNot = () => {
        setOpenModalNotify(false);
    }

    const openAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    } 

    const sendNotifyUdload = async () => {
        setLoadingButton(true);

        try {
            const data = {
                _ID: items._IDASSIGNMET,
                _DESIGNER: items._DESIGNER,
                _OBSERVATION: textNotify
            }
            
            await service.notifyDesigns(data).then( resp => {
                setLoadingButton(false);

                if ( resp._SUCCESS ) {
                    updateNotify(resp._ITEMS);
                    setTextNotify("");
                    hideModalNot();
                    hide()
                    toastAlert("success", resp._MESSAGE, toast);
                } else {
                    toastAlert("warn", resp._MESSAGE, toast);
                }
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }

    }

    const notifyUploadFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideModalNot } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ sendNotifyUdload } />
        </>
    );
    

    return (
        <div>
            <Toast ref={toast} style={{ fontSize: '14px' }} />
            <LeftSidebar
                onVisible={visible}
                onHide={() => hide()}
                onWidth={ 450 }
            >
                <h2 className='text-color'>Design Upload</h2>

                <div className='h-full'>
                    { socialMedia }
                </div>

                <Dialog 
                    visible={openModalUpload} 
                    style={{ width: '550px' }} 
                    header={`Design Upload ` + titleModal.toUpperCase()} modal  onHide={hideModal}>

                    <DesignsUpload 
                        data={ dataId }
                        format={ titleModal }
                        alert={ (variant, message) => openAlert(variant, message) }
                        updateData={ updateData }
                        hide={ () => hideModal() }
                        uploadNotify={verifyUploaded}
                    />
                </Dialog>


                <Dialog 
                    visible={openModalNotify} 
                    style={{ width: '550px' }} 
                    header={`Notify Design Upload`} 
                    modal  
                    onHide={hideModalNot} 
                    footer={notifyUploadFooter}>
                    
                    <div className="flex align-items-center justify-content-center">
                        <div className="field col-12">
                            <label htmlFor="observation" className="font-semibold">
                                Observation:
                            </label>
                            <InputTextarea 
                                rows={2} 
                                cols={50} 
                                value={textNotify} 
                                onChange={(e) => setTextNotify(e.target.value)} />
                        </div>
                    </div>
                </Dialog>

                {
                    (items._IDDESIGNER === auth._ID) ||
                    (auth._ROLNAME === "Design Supervisor") ||
                    (auth._ROLNAME === "Supervisor") ||
                    (auth._ROLNAME === "Administrator") ? 
                        <ButtonsActions 
                            cancel={() => hide()}
                            text={`Notify`}
                            submit={ () => openModalNot() }
                            isNotify={active}
                        /> : null
                }

            </LeftSidebar>
            
            <ExpandSidebar 
                visible={visibleFullScreen}
                id={ dataId }
                hide={ () => hideFullScreen() }
                items={ currentItem || [] }
                social={ currentSocial }
                format={ __current }
                updateItems= { updateData }
                updateButton={ verifyUploaded }
            />
        </div>
    )
}

export default DesignUpload;