import React, { createContext, useEffect, useState, useContext } from "react";
import { NotificationService } from "../service/NotificationService";
import { useAuth } from "./useAuth";

export const notificationsContext = createContext(null);

export function ProvideNotifications({ children }) {
    const notification = useProvideNotifications();
    return <notificationsContext.Provider value={ notification }>{ children }</notificationsContext.Provider>
}

export const useNotifications = () => {
    return useContext(notificationsContext);
}

export function useProvideNotifications() {
    const { 0:auth } = useAuth();

    const dataService = new NotificationService();

    const [notifications, setNotifications] = useState([]); 
    const [counter, setCounter] = useState(0);

    useEffect( () => {
        getData();
        const getUpdate = setInterval( () => {
            getData();
        }, 300000);

        return () => clearInterval(getUpdate);
        
    }, [auth]);

    const getData = async() => {
        
        if ( localStorage.getItem('token') !== null ) {
            const data = {
                '_ID': auth._ID
            }
            
            try {
                await dataService.getList(data).then( data => {
                    if ( data._ITEMS ) {
                        setNotifications(data._ITEMS);
    
                        let filtered = data._ITEMS.filter( (item) => item._READ === null );
                        
                        setCounter(filtered.length);
                    }
                });
            } catch (error) {
                
                console.log(error);
            }
            
        }

    }

    return [ notifications, setNotifications, counter, setCounter ];
}