import React from 'react';
import { LoginRoutes, HomeRoutes } from './routes';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import "./assets/Custom.css";
import { HashRouter } from 'react-router-dom';
import { ProvideAuth } from './hooks/useAuth';
import Login from './modules/auth/Login';
import { ProvideNotifications } from './hooks/useNotifications';
import { library } from '@fortawesome/fontawesome-svg-core';
//import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as Icons from '@fortawesome/free-brands-svg-icons'

//localStorage.clear();

//console.log(localStorage.getItem('token'));

function App() {

    const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fab' && key !== 'prefix')
    .map((icon) => Icons[icon]);

    library.add(...iconList);

    return (
        <ProvideAuth>
            <ProvideNotifications>
                <HashRouter>
                    {
                        localStorage.getItem('token') ? <HomeRoutes /> : <Login />
                    }
                </HashRouter>
            </ProvideNotifications>
        </ProvideAuth>
    );
}

export default App;