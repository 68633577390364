import React, { useState, useEffect, useRef, memo } from 'react';
import classNames from 'classnames';
import { Route, useLocation, BrowserRouter, Switch, Redirect, Router, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from '../AppTopbar';
import { AppFooter } from '../AppFooter';
import { AppMenu } from '../AppMenu';
import { AppConfig } from '../AppConfig';

import { List } from '../modules/social';
import { List as Formats } from '../modules/formats';
import { AccountList } from '../modules/accounts';
import { ModulesList } from '../modules/module';
import { RolsList } from '../modules/rols';
import { UserList } from '../modules/users';
import { CampaignsList } from '../modules/campaigns';
import { MediosList } from '../modules/medios';
import { RequestList } from '../modules/requests';
import { RequestIt } from '../modules/requestit';
import { DesignsList } from '../modules/designs'
import { ArchivedList } from '../modules/archived';
import { AssignmentsLists } from '../modules/assignments';
import Dashboard from '../components/Dashboard';
import ApprovalsList from '../modules/requests/approvals/ApprovalsList';
import Approved from '../modules/requests/approvals/Approved';
import NotApproved from '../modules/requests/approvals/NotApproved';
import NotifyList from '../modules/notifications/NotifyList';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import { Toast } from 'primereact/toast';
import { toastAlert } from '../core/utils';
import ProtectedRoute from './ProtectedRoute';
import Page404 from '../modules/auth/Page404';
import Page401 from '../modules/auth/Page401';
import { useAuth } from '../hooks/useAuth';
import { Can } from '../components/PermissionGate';
import { useNotifications } from '../hooks/useNotifications';
import Alert from '../components/Alert';

function HomeRoutes() {

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const toast = useRef(null);
    const location = useLocation();
    const [_rol, setRol] = useState("");

    PrimeReact.ripple = true;

    const { 5:role } = useAuth();
    
    const { 2:counter } = useNotifications();

    let menuClick = false;
    let mobileTopbarMenuClick = false;
    
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [
        {
            label: 'Home',
            items: [{
                label: 'Dashboard', 
                icon: 'pi pi-fw pi-home', 
                to: '/dashboard',
            }]
        },
        {
            label: 'Modules', icon: 'pi pi-fw pi-sitemap',
            items: [
                Can('campaigns.read') ? { label: 'Campaigns',  icon: 'pi pi-fw pi-tags', to: '/modules/campaigns/' } : "",
                Can('requests.read') ? { label: 'Design Request', icon: 'pi pi-fw pi-arrows-h', to: '/modules/requests/' } : "",
                Can('assignments.read') ? { label: 'Assignments', icon: 'pi pi-fw pi-sliders-v', to: '/modules/assignments/' } : "",
                //Can('designs.read') ? { label: 'Designs', icon: 'pi pi-fw pi-images', to: '/modules/designs/' } : "",
                Can('archived.read') ? { label: 'Archived', icon: 'pi pi-fw pi-box', to: '/modules/archived/' } : "",
                Can('IT Request.read') ? { label: 'IT Request', icon: 'pi pi-fw pi-list', to: '/modules/requestit/' } : "",
            ]
        },
        ( Can('formats.read') || 
          Can('social media.read') || 
          Can('accounts.read') ||
          Can('modules.read') ||
          Can('medios.read') ||
          Can('roles.read') ||
          Can('users.read') ) ? {
            label: 'Settings', icon: 'pi pi-fw pi-sitemap', 
            items: [
                Can('formats.read') ? { label: 'Formats', icon: 'pi pi-fw pi-th-large', to: '/modules/formats/' } : "",
                Can('social media.read') ? { label: 'Social Media', icon: 'pi pi-fw pi-list', to: '/modules/social/' } : "",
                Can('accounts.read') ? { label: 'Accounts', icon: 'pi pi-fw pi-briefcase', to: '/modules/accounts/' } : "",
                Can('modules.read') ? { label: 'Modules', icon: 'pi pi-fw pi-folder', to: '/modules/module/' } : "",
                Can('medios.read') ? { label: 'Medios', icon: 'pi pi-fw pi-tags', to: '/modules/medios/' } : "",
                Can('roles.read') ? { label: 'Roles', icon: 'pi pi-fw pi-lock', to: '/modules/rols/' } : "",
                Can('users.read') ? { label: 'Users', icon: 'pi pi-fw pi-users', to: '/modules/users/' } : "",
            ]
        } : {}
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    const routes = [
        {
            path: '/modules/campaigns/',
            component: CampaignsList,
            permissions: ['campaigns.read']
        },
        {
            path: '/modules/social/',
            component: List,
            permissions: ['social media.read']
        },
		{
            path: '/modules/formats/',
            component: Formats,
            permissions: ['formats.read']
        },
		{
            path: '/modules/accounts/',
            component: AccountList,
            permissions: ['accounts.read']
        },
		{
            path: '/modules/module/',
            component: ModulesList,
            permissions: ['modules.read']
        },
		{
            path: '/modules/rols/',
            component: RolsList,
            permissions: ['roles.read']
        },
		{
            path: '/modules/requests/',
            component: RequestList,
            permissions: ['requests.read']
        },
        {
            path: '/modules/requestit/',
            component: RequestIt,
            permissions: ['IT Request.read']
        },
		{
            path: '/modules/ApprovalsList/',
            component: ApprovalsList,
            permissions: ['requests.read']
        },
		{
            path: '/modules/archived/',
            component: ArchivedList,
            permissions: ['archived.read']
        },
		{
            path: '/modules/assignments/',
            component: AssignmentsLists,
            permissions: ['assignments.read']
        },
        {
            path: '/modules/medios/',
            component: MediosList,
            permissions: ['medios.read']
        },
        {
            path: '/modules/users/',
            component: UserList,
            permissions: ['users.read']
        },
    ];

    const customRoutes = () => {
        return routes
        .filter( route => 
            route.permissions.some( routePermission => 
            Can(routePermission) ) )
        .map( (route, i) => <Route path={route.path} component={route.component} key={i}/> );
    }

    return (
        <React.Fragment>
            
            <div className={wrapperClass} onClick={onWrapperClick}>

                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar 
                    onToggleMenuClick={onToggleMenuClick} 
                    layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} 
                    onMobileTopbarMenuClick={onMobileTopbarMenuClick} 
                    onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} 
                    counter={ counter }
                />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={ layoutColorMode } />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Switch>

                            <Route exact path="/">
                                <Redirect to="/dashboard" />
                            </Route>

                            <Route exact path="/dashboard" >
                                <Dashboard location={location} />
                            </Route>
                            
                            { customRoutes() }

                            <Route path="/modules/notifications" component={ NotifyList } />

                            <Route path="*" component={ Page401 } />
                        </Switch>
                    </div>
                    
                    <AppFooter layoutColorMode={layoutColorMode} />
                </div>
                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={ inputStyle } onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={ layoutColorMode } onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={ mobileMenuActive } unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
            
                    
        </React.Fragment>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
  };
  

export default HomeRoutes;