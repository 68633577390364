import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { CampaignsService } from '../../service/CampaignsService';
import ReactPlayer from 'react-player/lazy';
import { Chip } from 'primereact/chip';
import { Link } from 'react-router-dom';
import RightSidebar from '../../components/RightSidebar';
import CampaingView from '../campaigns/ViewData';
import FlagEEUU from '../../assets/images/flag-eeuu.png';
import FlgSpain from '../../assets/images/flag-spain.png';
import ButtonsApprovals from '../../components/ButtonsApprovals';
import { iconsTemplate } from '../../core/utils';
import { useAuth } from '../../hooks/useAuth';
import ButtonsAssigned from '../../components/ButtonsAssigned';
import { downloadFiles } from '../../core/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ViewRequest({ items, setItems, onAlert, campaigns, dataList, setDataList, closeViewData, updateDesignerAssignment }) {

    const dataService = new CampaignsService(); //Call Service

    const { 0:auth } = useAuth();

    const [loadingButton, setLoadingButton] = useState(false);
    const [deleteDataDialogFile, setDeleteDataDialogFile] = useState(false);
    const [deleteDataDialogResource, setDeleteDataDialogResource] = useState(false);
    const [dataFile, setDataFile] = useState([]);
    const [__designed, setAssignment] = useState(items._ASSIGNED);
    let __d = false;
    let __a = false;

    const [__approve, setStatusApprove] = useState(false);

    const [itemsCampaign, setItemsCampaing] = useState(null);

    const [visibleRight, setVisibleRight] = useState(false);

    const deleteResources = async() => {
        try {
            const data = {
                _ID: items._ID,
                _IDFILE: dataFile._id,
                _TYPE: dataFile.type,
                _RESOURCE: dataFile.resource
            }

            await dataService.deleteResource(data).then( response => {
                if ( response._MESSAGE ) {
                    hideDeleteDataDialogResource();
                    setDataFile([]);
                    setItems(response._ITEMS);
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('success', response._MESSAGE);
                }
            });

        } catch (error) {
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    const deleteFiles = async() => {
        const data = {
            _ID: items._ID,
            _IDFILE: dataFile._id,
            _FILE : dataFile.filename,
        }

        try {
            await dataService.deleteFile(data).then( response => {
                if ( response._MESSAGE ) {
                    hideDeleteDataDialog();
                    setDataFile([]);
                    setItems(response._ITEMS);
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('success', response._MESSAGE);
                }
            });
        } catch (error) {
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    } 

    const hideDeleteDataDialog = () => {
        setDeleteDataDialogFile(false);
    }

    const hideDeleteDataDialogResource = () => {
        setDeleteDataDialogResource(false);
    }

    const hideRight = () => {
        setVisibleRight(false);
    }

    const handleCampaingView = () => {
        setVisibleRight(true);
        const selected = campaigns.filter( (item) => item._ID === items._IDCAMPAIGN ).map( (find) => find )[0];
        setItemsCampaing(selected);
       
    }

    const deleteDataDialogFooterFile = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteFiles } />
        </>
    );

    const deleteDataDialogFooterResource = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialogResource } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteResources } />
        </>
    );

    const isUrgent = () => {
        
        return (
            <>
                <div 
                    className='font-semibold'
                    style={{ color: (items._URGENT !== 'null') ? '#4BAAF5' : '#6EBE71' }}>
                    { (items._URGENT !== false) ? 'YES' : 'NO' }</div>
            </>
        )
    }

    const socialMedia = (

        (items._FORMATS !== undefined) ?
            Object.values(items._FORMATS).map( (format, i) => {
                const social = format['socialMedia'];
                const icon = iconsTemplate(social);
                
                return (
                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 inline-flex align-items-center mb-2" key={i}>
                        {
                            (social === 'TIKTOK') ? 
                                <FontAwesomeIcon icon={['fab', 'tiktok']} /> :
                                <i className={ `pi` +' '+ icon }></i>
                        }

                        {
                            format.socialFormat.map( (dimension, x) => (
                                <div key={x}>
                                    <Chip label={ dimension } className="ml-2 mr-2 w-max" />
                                </div>
                            ))
                        }
                    </div>
                )
            }) : null
    );

    const traditionalFormat = (

        (items._FORMATS !== undefined) ?
            Object.values(items._FORMATS).map( (format, i) => {
                const social = format['socialMedia'];
                const icon = iconsTemplate(social);
                let str = format.socialMedia;
                let trd = str.toUpperCase();
                
                return (
                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 inline-flex align-items-center mb-2" key={i}>
                     
                        <i className={ `pi` +' '+ icon }></i>

                        {
                            format.socialFormat.map( (dimension, x) => (
                                <div key={x}>
                                    <Chip label={` ${trd} - ${dimension}` } className="ml-2 mr-2 w-max" />
                                </div>
                            ))
                        }
                    </div>
                )
            }) : null
    );

    const handleLanguages = () => {
        if (items._LANGUAGES !== undefined && items._LANGUAGES !== null) {
            const languages = items._LANGUAGES;
            const medios = languages.split(',');
    
            return (
                medios.map( (language, i) => (
                    <Chip label={ language } image={ (language === "ENGLISH") ? FlagEEUU : FlgSpain } className="ml-2 mr-2 w-max"  key={ i } />
                ))
            )
        }
    }

    const socialResources = (
        (items._RESOURCES !== undefined) ?
            Object.values(items._RESOURCES).map( (resource, x) => {
                let url  = resource.resource;
                let segm = url.split('.');
                return (
                    <li className="py-2 surface-border" key={x}>
                        <a 
                            className="
                                p-ripple 
                                cursor-pointer 
                                flex 
                                align-items-center 
                                justify-content-between 
                                hover:surface-200 
                                transition-colors 
                                transition-duration-150 
                                border-round p-2"
                                onClick={ () => window.open(resource.resource, '_blank') } >
                            { 
                                (resource.type === "Video") ? 
                                <ReactPlayer url={ resource.resource } width={100} height={60} style={{ borderRadius: '10px' }} className="test" /> :
                                    ( segm[0] !== 'https://letusclaimcons' ) ? 
                                        <Image 
                                            src={ resource.resource } 
                                            alt={ resource.resource } 
                                            width={100} height={60} 
                                            imageStyle={{ borderRadius: '10px' }} /> : 
                                            <i className="pi pi-image" style={{'fontSize': '3em'}}></i>
                            }
                                <div style={{ marginLeft: '20px' }}>
                                    <span className="block text-900 mb-1">{ resource._id }</span>
                                </div>
                                <i className="ml-auto pi pi-chevron-right text-600 text-sm"></i>
                                <span role="presentation" className="p-ink" style={{ height: '255px', width: '255px', top: '-98.5px', left: '-89.5px' }}></span>
                        </a>
                    </li>
                )
            }) : null
    );

    const requestFiles = (
        (items._FILES !== undefined) ?
            (items._FILES).map( (file, i) => {
                let fileExt = file.filename;
                const [ext, ...fileName] = fileExt.split('.').reverse();
                return (
                    <li className="py-2 surface-border" key={i}>
                        <a 
                            className="
                                p-ripple 
                                cursor-pointer 
                                flex 
                                align-items-center 
                                justify-content-between 
                                hover:surface-200 
                                transition-colors 
                                transition-duration-150 
                                border-round p-2"
                            >
                                {
                                    ( ext === 'mp4' || ext === 'jpg' || ext === 'png' ) ?
                                        ( ext === 'mp4' ?
                                            <ReactPlayer 
                                                url={ file.pathUrl } 
                                                width={100} 
                                                height={60} 
                                                style={{ borderRadius: '10px' }} 
                                                className="test" /> : 
                                            <Image 
                                                src={ file.pathUrl } 
                                                alt={ fileExt } 
                                                preview 
                                                width={100} 
                                                height={60} 
                                                imageStyle={{ borderRadius: '10px' }} /> ) : 
                                                
                                            ( ext === 'pdf' ? 
                                                <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                                    <i className="pi pi-file-pdf" style={{'fontSize': '3em'}}></i></div> : 
                                                <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                                    <i className="pi pi-file-word" style={{'fontSize': '3em'}}></i></div> )
                                }
                               
                                <div style={{ marginLeft: '20px' }}>
                                    <span className="block text-900 mb-1">{ file._id }</span>
                                </div>
                                
                                <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                    <i className="ml-auto mr-5 pi pi-download text-600 text-xl"></i>
                                </div>
                                
                        </a>
                    </li>
                )
            }) : null
    );      

    const statusTemplate = () => {
        if ( items._STATUS !== undefined ) {
            const status     = items._STATUS;
            const allStatus  = status.request_status;
            const lastStatus = allStatus[ allStatus.length - 1 ];

            isApprovals(lastStatus.status);
    
            return (
                <>
                    <span className='custom-badge bg-bluegray-700'>
                        { lastStatus.status }
                    </span>
                </>
            )
        }
    }

    const isApprovals = (data) => {

        if (data === "SENT FOR REVIEW") {
            __d = true;
        }

        if (data === "APPROVED") {
            __a = true;
        }
    }

    const isDisabled = () => {
        let __isDisabled;
        if (items._STCAMPAIGN !== null) {
            //const stCampign = items._STCAMPAIGN.campaign_status.slice(-1);
            //const lastStatus = stCampign.some( (s) => s.status === "IN PROGRESS" );
            const urgent = items._URGENT; 
    
            if (items._STCAMPAIGN['campaign_status']['status'] === "IN PROGRESS") {
                __isDisabled = false;
            } else {
                if (urgent === true) {
                    __isDisabled = false;
                } else {
                    __isDisabled = true;
                }
            } 
        } else {
            __isDisabled = false;
        }

        return __isDisabled;
    }

    return (
        <div>
            <div className="surface-0">
                <div className="font-medium text-3xl mb-3 text-color">Request Information</div>
               
                <ul className="list-none p-0 m-0">
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Status</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> { statusTemplate() }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Urgent</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> { isUrgent() }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Type</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._TYPE }</div>
                    </li>
                    {
                        items._CAMPAIGN ?
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Campaign</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Link to={`#`} onClick={ () => handleCampaingView() } 
                                        className='transition-delay-100 transition-colors transition-duration-300 text-underline'>
                                        { items._CAMPAIGN }
                                    </Link>
                                </div>
                            </li> : <></>
                    }
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Languages</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            { handleLanguages() }
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Account</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._ACCOUNT }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Handle date</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._DHANDLE }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Publication date</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._DPUBLIC }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Text</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" style={{ whiteSpace: "break-spaces" }}>{ items._CONCEPT }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Caption</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" style={{ whiteSpace: "break-spaces" }}>{ items._CAPTION }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Description</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" style={{ whiteSpace: "break-spaces" }}>{ items._DESCRIPTION }</div>
                    </li>

                    {
                        (items._TYPEFORMAT === 'traditional' ) ?
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Traditional Format</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                    { traditionalFormat }
                                </div>
                            </li> : 
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Social Media</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                    { socialMedia }
                                </div>
                            </li>
                    }

                    {
                        ( items._ISVIDEO === 'YES' ) ? 
                            <React.Fragment>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">This is a video request</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        { items._ISVIDEO }
                                    </div>
                                </li>

                                <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Video Time</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        { items._TIME }
                                    </div>
                                </li>

                                <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Video Subtitle</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        { items._SUBTITLE }
                                    </div>
                                </li>

                            </React.Fragment>
                            : null
                    }

                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <h5>Online Resources</h5>
                        <ul className="list-none p-0 m-0 w-full">
                            { socialResources }
                        </ul>
                    </li>

                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <h5>Files</h5>
                        <ul className="list-none p-0 m-0 w-full">
                            { requestFiles }
                        </ul>
                    </li> 

                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Register by</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._REGISTER }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Assigned</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._ASSIGNED }</div>
                    </li>
                </ul>
            </div>

            <Divider className='m-0' />

            { 
                auth._ROLNAME !== "Community Manager" && auth._ROLNAME !== "Designer" && 
                    __d && <ButtonsApprovals 
                            id={ items._ID } 
                            onAlert={ onAlert } 
                            dataList={ dataList }
                            setDataList={ setDataList }
                            closeViewData={closeViewData}
                        /> 
            }

            { 
                auth._ROLNAME !== "Community Manager" && auth._ROLNAME !== "Designer" && 
                    __a && items._ASSIGNED === null && <ButtonsAssigned 
                            id={items._ID}
                            hidePrevious={ closeViewData }
                            dataList={dataList}
                            setAssignment={ setAssignment }
                            updateDesigner={updateDesignerAssignment}
                            disable={ isDisabled() }
                        />
            }

            <RightSidebar
                onVisible={ visibleRight }
                onHide={ () => hideRight() }
                onWidth={ 550 }
            >
                <CampaingView items={ itemsCampaign } />
            </RightSidebar>

            <Dialog visible={ deleteDataDialogResource } style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooterResource} onHide={hideDeleteDataDialogResource}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    { dataFile && <span>Are you sure you want to delete <b>{ dataFile.resource }</b>?</span> }
                </div>
            </Dialog>

            <Dialog visible={ deleteDataDialogFile } style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooterFile} onHide={hideDeleteDataDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    { dataFile && <span>Are you sure you want to delete <b>{ dataFile.filename }</b>?</span> }
                </div>
            </Dialog>
        </div>
        
    )
}

export default ViewRequest;