import React,  { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Toast } from "primereact/toast";
import { SocialService } from "../../service/SocialService";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { stringToFirstUppercase, toastAlert } from "../../core/utils";
import { RadioButton } from 'primereact/radiobutton';
import LeftSidebar from "../../components/LeftSidebar";
import ButtonsActions from "../../components/ButtonsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EditAdd({ title, onVisible, onHide, onFormats, addItemList, updateItemList, setLoading, items, setItems }) { 

    const dataService = new SocialService();

    const [nameSocial, setNameSocial] = useState(null);
    const [selectedFormats, setSelectedFormats] = useState([]);
    const [todoFormat, setTodoFormat] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const toast = useRef(null);

    useEffect( () => {
        if ( title === "Edit" ) {
            setNameSocial(items._NAME);

            if ( items._FORMATS !== null ) {
                const dArray = [];
                items._FORMATS.map( (f, i) => {
                    f.dimensions.map( (d, u) =>{
                        const dd = f.format + '-' + d.dimension;
                        dArray.push(dd);
                        builderFormats(dArray);
                    });
                });
                const strign  = dArray.join(',');
                const spliter = strign.split(','); 
                setSelectedFormats(spliter);
                setSelectedIcon(items._ICON);
            }

        } else {
            setNameSocial(null);
            setSelectedIcon(null);
            setSelectedFormats([]);
        }
    }, [items]);

    const __formats = onFormats.map( ( m, i ) => {
        const data = [];
        const items = [];

        m._DIMENSIONS.map( (t, p) => (
            items[p]= {
                label: t.dimension, value: m._NAME + '-' + t.dimension 
            }
        ));

        return data[i] = {
            name: m._NAME, value: m._NAME,
            items
        };
    });

    const onFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ onHide } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ () => sendData() } />
        </>
    );

    const builderFormats = (event) => {
        setSelectedFormats(event);
        
        const data = [...todoFormat];

        event.map( (o, i) => {
            const optSelected = o.split('-');
            const optArray = { format: optSelected[0], dimension: optSelected[1] };
            data[i] = optArray;  
        });

        if (event.length !== data.length) {
            data.splice(-1);
        } 

        setTodoFormat(data);
    }

    const sendData = async () => {

        setLoading(true);
        setLoadingButton(true);

        try {

            let route;
            if (title === "Edit") {
                route = dataService.updateData;
            } else {
                route = dataService.saveData;
            }
            
            const data = {
                _ID     : (title === "Edit") ? items._ID : 0,
                _NAME   : nameSocial,
                _ICON   : selectedIcon,
                _FORMATS: todoFormat
            }; 

            await route(data).then( data => {
                setLoading(false);
                setLoadingButton(false);
                if ( data._SUCCESS ) {
                    onHide();
                    
                    if ( data._OPERATION === "add" ) {
                        addItemList(data._ITEMS);
                    } else {
                        setItems(data._ITEMS);
                        updateItemList(data._ITEMS);
                    }

                    toastAlert('success', data._MESSAGE, toast );
                } else {
                    toastAlert('warn', data._MESSAGE, toast );
                }
            });

        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }

    }

    const icons = [
        { className: 'pi pi-instagram', key: 0 },
        { className: 'pi pi-youtube', key: 1 },
        { className: 'pi pi-linkedin', key: 2 },
        { className: 'pi pi-twitter', key: 3 },
        { className: 'pi pi-telegram', key: 4 },
        { className: 'pi pi-vimeo', key: 5 },
        { className: 'pi pi-facebook', key: 6 },
        { className: 'fas tiktok', key: 7 },
    ];

    const setIcons = (
        icons.map( (icon, i) => {
            return (
                <div className="field col-3" key={i}>
                    <div className="field-radiobutton">
                        <RadioButton 
                            inputId={ `icon_` +i } 
                            name="icon" 
                            value={icon.className} 
                            onChange={ (e) => setSelectedIcon(e.value) }
                            checked={ selectedIcon === icon.className }
                        />
                        {
                            (icon.className === 'fas tiktok') ? 
                                <label htmlFor={ `icon_` +i } ><FontAwesomeIcon icon={['fab', 'tiktok']} /></label> :
                                <label htmlFor={ `icon_` +i } ><i className={ `text-xl ` + icon.className}></i></label>
                        }
                    </div>
                </div>
            )
        })
    );

    return (
        <div>
            <Toast ref={toast} style={{ fontSize: '14px' }} />

            <LeftSidebar
                onVisible={ onVisible }
                onHide={ () => onHide() }
                onWidth={ 450 }
            >
                <h2 className="text-color">Social Media {title}</h2>

                <div className="p-fluid formgrid grid mt-3">

                    <div className="field col-12">
                        <label htmlFor="name" className="font-semibold">Name</label>
                        <InputText
                            id="social"
                            name="social"
                            placeholder="Facebook, Instagram..."
                            value={ stringToFirstUppercase(nameSocial) || '' }
                            required
                            onChange={ e => setNameSocial(e.target.value) }
                        />
                    </div>
                </div>

                <div className="p-fluid formgrid grid mt-3">

                    <div className="field col-12">
                        <label htmlFor="name" className="font-semibold">Icon</label>
                        <div className="p-fluid formgrid grid">
                            { setIcons }
                        </div>
                    </div>
                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-12">
                        <label htmlFor="formats" className="font-semibold">Formats & Dimensions</label>
                        <MultiSelect 
                            options={ __formats }  
                            optionLabel="label" 
                            optionGroupLabel="name" 
                            optionGroupChildren="items"
                            placeholder="Choose..." 
                            value={ selectedFormats }
                            display="chip"
                            filter
                            onChange={ (e) =>{ builderFormats(e.value); }}
                        />
                    </div>

                </div>

                <ButtonsActions 
                    cancel={ () => onHide() }
                    submit={ () => sendData() }
                    loading={loadingButton}
                    disabled={loadingButton}
                />
            </LeftSidebar>
            
        </div>
    );
}

export default EditAdd;
