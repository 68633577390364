import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { convertTimestamp } from '../core/utils';
import { iconStatus, colorStatus } from '../core/utils';
import { RequestService } from '../service/RequestService';
import ButtonsActions from './ButtonsActions';
import { InputTextarea } from 'primereact/inputtextarea';

function TimeLineRequests({ id, items, setLoading, onAlert, dataList, setDataList, urgent, campaign, isApproved, onHide }) {

    const dataService = new RequestService();

    const statuses = [
        { status: 'SAVED', key: 0 }, 
        { status: 'SENT FOR REVIEW', key: 1 }, 
        /*{ status: 'APPROVED', key: 2 },
        { status: 'NOT APPROVED', key: 3 },*/
    ];

    const [loadingButton, setLoadingButton] = useState(false);
    const [modalChangeStatus, setModalChangeStatus] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState(items);
    const [selectedCurrent, setSelectedCurrent] = useState(items);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [observations, setObservations] = useState("");

    const allStatus = (
        items.map( (s, i) => {
            return (
                <div className="my-5 flex" key={i}>
                    <div className="flex flex-column align-items-center" style={{ width: "2rem" }}>
                        <span className="text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: "2.5rem", minHeight: "2.5rem", background: colorStatus(s.status) }}>
	                        <i className={ iconStatus(s.status) }></i>
                        </span>
                        <div className="h-full" style={{ width: "2px", minHeight: "4rem", background: colorStatus(s.status) }}></div>
                    </div>

                    <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                        <div className="mb-3">
                            <span className="text-900 font-medium inline-block mr-3">{ s.user }</span>
                            <span className="text-500 text-sm">{ convertTimestamp(s.date) }</span>
                        </div>

                        {
                            s.observation && 
                                <div className="line-height-3 text-700 mb-3 paragraph">
                                    <p className='font-semibold'>Observations:</p>
                                    { s.observation }
                                </div>
                        }


                        <div className="line-height-3 text-700 mb-3">
                            <h5 className='font-semibold text-color'>{ s.status }</h5>
                        </div>
                    </div>
                </div>
            )
        })
    );

    const openChangeStatus = () => {
        setModalChangeStatus(true);
    }

    const hideModalStatusDialog = () => {
        setModalChangeStatus(false);
    }
    
    const modalStatusFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ () => { hideModalStatusDialog(); reject(); } } />
            <Button label="Yes" loading={loadingButton} disabled={ buttonDisabled }  icon="pi pi-check" className="p-button-text" onClick={ (e) => confirm(e) } />
        </>
    );

    const confirm = (event) => {

        let selected  = selectedStatuses[ selectedStatuses.length - 1 ];
        
        confirmPopup({
            target: event.currentTarget,
            message: (selected.status === "CANCELED") ? 'Are you sure to cancel this campaign? This action is irreversible!' : 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };

    const reject = () => {
        setButtonDisabled(true);
        let current = items[ items.length - 1 ];
        let reject = [...selectedStatuses];
        let selected  = selectedStatuses[ selectedStatuses.length - 1 ];
        let index = reject.indexOf(selected.status);
        if ( current.status !== selected.status ) {
            reject.splice(index, 1);
            setSelectedStatuses(reject);
        }
    };

    const accept = () => {
        onStatusChange();
    };

    const CheckboxStatus =  (
        statuses.map( (st, i) => {
            let current  = selectedCurrent[ selectedCurrent.length - 1 ];
            const sel    = [...selectedStatuses];

            const isChecked  = sel.some((item) => item.status === st.status);
            const isDisabled = !isChecked && (sel.filter((i) => i.status).length > selectedCurrent.length);
    
            return (
                
                <div key={i} className="field-checkbox" >
                    <Checkbox inputId={ st.key } 
                        name={st.status} 
                        value={st}
                        className="check"
                        onChange={ (e) => { onStatusValueChange(e); }} 
                        checked={ isChecked } 
                        disabled={ ( current.status === "SAVED" ) ?
                                (selectedCurrent.length !== i) 
                            : (selectedCurrent.length !== i &&
                                st.status !== "CANCELED" && 
                                st.status !== "PAUSED" || isDisabled ) } 

                    />
                    <label htmlFor={ st.key }>{ st.status }</label>
                </div>
            
            )
        })
    );

    const onStatusValueChange = (e) => {
        let _selectedStatuses = [...selectedStatuses];

        if (e.checked) {
            setButtonDisabled(false);
            _selectedStatuses.push(e.value);
        }
        else {
            setButtonDisabled(true);
            for (let i = 0; i < _selectedStatuses.length; i++) {
                const selectedST = _selectedStatuses[i];
                
                if (selectedST.key === e.value.key) {
                    _selectedStatuses.splice(i, 1);
                    break;
                }
            }
        }

        setSelectedStatuses(_selectedStatuses);
    }

    const updateStatusList = (id, status) => {
        let data = [...dataList];
        let newItem = [...items];

        data.filter( st => st._ID === id ).map( (s, i) => {
            s._STATUS.request_status.push( JSON.parse(status) );
        });

        newItem.push(JSON.parse(status));
        
        setSelectedStatuses(newItem);
        setDataList(data);
    }

    const onStatusChange = async () => {
        
        try {
            let last = selectedStatuses.pop();

            const data = {
                _ID: id,
                _STATUS: last.status,
                _URGENT: urgent,
                _CAMPAIGN: campaign,
                _OBSERVATION: observations
            }
            
            await dataService.getStatusChange(data).then( response => {
                if ( response._SUCCESS === true ) {
                    updateStatusList(response._ID, JSON.stringify(response._STATUS) );
                    hideModalStatusDialog();
                    onAlert( 'success', response._MESSAGE );
                } else {
                    onAlert( 'warn', response._MESSAGE );
                }
            });

        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    return (
        <div>
            <h3 className='text-color'>Status</h3>
            <ConfirmPopup />
            { allStatus }

            {
                !isApproved &&
                    <div className='mt-8'>
                        <ButtonsActions 
                            cancel={ () => onHide() }
                            submit={ () => openChangeStatus() }
                            isView={ false }
                            text="Change"
                        />
                    </div>
            }

            <Dialog visible={modalChangeStatus} style={{ width: '35vw' }} header="Change status" modal footer={modalStatusFooter} onHide={hideModalStatusDialog}>
                { CheckboxStatus }
                <div className="p-fluid formgrid grid mt-5">
                    <div className="field col-12">
                        <label htmlFor="name" className="font-semibold">Observations</label>
                        <InputTextarea 
                            rows={3} 
                            cols={20} 
                            value={observations} 
                            onChange={ (e) => setObservations(e.target.value) } 
                            autoResize 
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default TimeLineRequests;