import React, {useEffect, useState} from 'react';
import LeftSidebar from '../../components/LeftSidebar';
import FlagEEUU from '../../assets/images/flag-eeuu.png';
import FlgSpain from '../../assets/images/flag-spain.png';
import { Chip } from 'primereact/chip';

export default function ViewRequestUpload({items, visible, hide}) {


    const uploads = (
        (items._UPLOADED !== undefined) ?
            Object.values(items._UPLOADED).map( (resource, x) => {
                let ld = items._LANGUAGES;
                let split = ld.split(',');

                return (
                    <React.Fragment key={x}>
                        <li className="py-2 surface-border">
                        <label className="block mb-2"><b>Uploaded Date</b> </label>
                            {resource._FILEDATE}
                        </li>
                        {
                            (split[1] === 'ENGLISH' || split[0] === 'ENGLISH' || items._LANGUAGES === 'ENGLISH') ?
                                <li className="py-2 surface-border">

                                    <a className="
                                        p-ripple 
                                        cursor-pointer 
                                        flex 
                                        align-items-center 
                                        justify-content-between 
                                        hover:surface-200 
                                        transition-colors 
                                        transition-duration-150 
                                        border-round p-2"
                                        onClick={ () => window.open(resource._FILEURLENGLISH, '_blank') }
                                    >
                                        <div style={{ marginLeft: '20px' }}>
                                            <span className="block text-900 mb-1">
                                                <Chip label={ 'ENGLISH' } image={  FlagEEUU } className="mr-2 w-max"  />
                                            </span>
                                        </div>
                                    </a> 
                                </li>: null
                        }

                        {
                            (split[1] === 'SPANISH' || split[0] === 'SPANISH' || items._LANGUAGES === 'SPANISH') ?
                                <li className="py-2 surface-border">
                                    <a className="
                                        p-ripple 
                                        cursor-pointer 
                                        flex 
                                        align-items-center 
                                        justify-content-between 
                                        hover:surface-200 
                                        transition-colors 
                                        transition-duration-150 
                                        border-round p-2"
                                        onClick={ () => window.open(resource._FILEURLSPANISH, '_blank') }
                                    >
                                        <div style={{ marginLeft: '20px' }}>
                                            <span className="block text-900 mb-1">
                                                <Chip label={ 'SPANISH' } image={  FlgSpain } className="mr-2 w-max"  />
                                            </span>
                                        </div>
                                    </a> 
                                </li>: null
                        }
                    </React.Fragment>
                )
            }) : null
    ); 
    
    return (
        <LeftSidebar
            onVisible={ visible }
            onHide={ () => hide() }
            onWidth={ 500 }
        >
            <div className="surface-0 pb-8">
                <div className="font-medium text-3xl mb-3 text-color">Request It Uploaded</div>
                <ul className="list-none p-0 m-0">
                    {uploads}
                </ul>
                
            </div>
        </LeftSidebar>
    )
}
