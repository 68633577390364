import React, { useState, useRef, useEffect } from 'react';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ImageUpload from '../../components/ImageUpload';
import { toastAlert } from '../../core/utils';
import { AuthService } from '../../service/AuthService';
import { useAuth } from '../../hooks/useAuth';
import EditAdd from './EditAdd';
import LeftSidebar from '../../components/LeftSidebar';

function Profile() {

    const dataService = new AuthService();

    const { 0:auth, 1:setAuth } = useAuth();

    const [isShown, setIsShown] = useState(false);
    const [showAvatarDialog, setShowAvatarDialog] = useState(false);
    const [isAvatar, setIsAvatar] = useState("");
    const [loadingButton, setLoadingButton] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalLogout, setShowModalLogout] = useState(false);

    const toast = useRef(null);

    useEffect( () => {
        if ( auth !== "" ) {
            setIsAvatar(auth._AVATAR);
        }
    }, [auth]);

    const showModal = () => {
        setShowAvatarDialog(true);
    }

    const hideModal = () => {
        setShowAvatarDialog(false);
    }

    const showLogout = () => {
        setShowModalLogout(true);
    }

    const hideLogout = () => {
        setShowModalLogout(false);
    }

    const onShowModalEdit = () => {
        setShowModalEdit(true);
    }

    const onHideModalEdit = () => {
        setShowModalEdit(false);
    }

    const OpenAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const updateAvatarList = (avatar) => {
        let data = [...auth];
    
        const index = auth.map( function(x) { return x._ID; } ).indexOf(auth._ID);
        data[index]._AVATAR = avatar;
        
        setAuth(data);

    }

    const handleLogout = () => {
        setLoadingButton(true);
        hideLogout();
        
        dataService.getLogout().then( response => {
            setLoadingButton(false);
            if ( response.success ) {
                toastAlert( 'success', response.message, toast );
                setTimeout( () => {
                    localStorage.clear();
                    window.location.replace("/");
                }, 1000);
            } else {
                toastAlert( 'warn', response.message, toast );
            }
            
        }).catch( error => {
            setLoadingButton(false);
            toastAlert( error.variant, error.data, toast );
        });
    }

    const logoutDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideLogout } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ () => handleLogout() } />
        </>
    );


    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "10px" }}>
      
            <Toast ref={toast} />
            <div className='grid'>
            
                <div className='col-12 flex justify-content-center'>
                    <div 
                        style={{ position: "relative", width: "40%" }}
                        onMouseEnter={ () => setIsShown(true) } 
                        onMouseLeave={ () => setIsShown(false) }
                    >

                        <Avatar image={ isAvatar } className="mr-2" size="xlarge" shape="circle" style={{ width: "150px", height: "150px" }} >
                            { isShown &&
                                <div className='overlay' onClick={showModal}>
                                    <div style={{ color: '#FFF', textAlign: 'center' }}>
                                        <i className="pi pi-camera"></i>
                                        <p style={{ fontSize: '12px' }}>Change</p>
                                    </div>
                                </div> 
                            }
                        </Avatar>
                    </div>
                </div>

                <div className='col-12 mt-3 text-center title-h'>
                    <h2>{ auth._ROLNAME }</h2>
                </div>

                <div className='div-container'>

                    <div className='grid'>
                        <div className='col-6'>
                            <p className='p-text'>Name</p>
                        </div>

                        <div className='col-6 text-right'>
                            <p className='p-text'>{ auth._NAME }</p>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-6'>
                            <p className='p-text'>Lastname</p>
                        </div>

                        <div className='col-6 text-right'>
                            <p className='p-text'>{ auth._LASTNAME }</p>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-6'>
                            <p className='p-text'>Email</p>
                        </div>

                        <div className='col-6 text-right'>
                            <p className='p-text'>{ auth._EMAIL }</p>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-6'>
                            <p className='p-text'>Phone</p>
                        </div>

                        <div className='col-6 text-right'>
                            <p className='p-text'>{ auth._PHONE }</p>
                        </div>
                    </div>

                    <Divider />

                   <div className='grid'>
                        <div className='col-6'>
                            <Button 
                                icon="pi pi-pencil" 
                                tooltip='Edit' 
                                tooltipOptions={{ position: 'bottom' }} 
                                disabled={loadingButton}
                                onClick={ () => onShowModalEdit() }
                            />
                            
                        </div>
                        <div className='col-6 text-right'>
                            <Button 
                                icon="pi pi-power-off" 
                                className="p-button-danger" 
                                tooltip='Logout' 
                                tooltipOptions={{ position: 'bottom' }} 
                                onClick={ () => showLogout() }
                                loading={loadingButton}
                            />
                        </div>
                    </div>

                </div>

            </div>
      
            <Dialog visible={showAvatarDialog} style={{ width: '500px' }} header="Change avatar" modal  onHide={ hideModal }>
                <div className="flex align-items-center justify-content-center">
                    <ImageUpload 
                        id={ auth._ID } 
                        setIsAvatar={ setIsAvatar } 
                        onHideModal={ hideModal }
                        setAlert={ OpenAlert }
                        updateAvatarList={ updateAvatarList }
                    />
                </div>
            </Dialog>

            <EditAdd 
                visible={ showModalEdit }
                hide={ onHideModalEdit }
                items={ auth }
                onAlert={ OpenAlert }
                title="Edit" 
            /> 

            <Dialog visible={showModalLogout} style={{ width: '450px' }} header="Confirm" modal footer={logoutDataDialogFooter} onHide={hideLogout}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>Do you want to <b>Logout?</b></span> 
                </div>
            </Dialog>

        </div>
    )
}

export default Profile;