import React, { createContext, useState, useEffect, useContext } from 'react';
import { ModuleService } from '../service/ModuleService';

const modulesContext = createContext(null);

export function ProvideModule({ children }) {
    const modules = useProvideModule();
    return <modulesContext.Provider value={modules}>{ children }</modulesContext.Provider>
}

export const useModules = () => {
    return useContext(modulesContext);
}

export function useProvideModule() {

    const dataService = new ModuleService();

    const [module, setModule] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async() => {
        try {
            await dataService.getList().then(data => {
                setModule(data); 
            });
        } catch (error) {
            console.log(error);
        }
    }


    return [ module ];

}