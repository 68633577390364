import React,  { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { toastAlert } from "../../core/utils";
import LeftSidebar from "../../components/LeftSidebar";
import { Dropdown } from "primereact/dropdown";
import { Avatar } from 'primereact/avatar';
import ButtonsActions from "../../components/ButtonsActions";
import { AssignmentService } from "../../service/AssignmentService";
import { Badge } from 'primereact/badge';

function EditAdd({ idRequest, onVisible, onHide, designers, dataList, setAssignment, updateDesignerAssignment }) { 

    const dataService = new AssignmentService();

    const [loadingButton, setLoadingButton] = useState(false);
    const [selDesigner, setSelDesigner] = useState("");
    const [__info, setCurrentInfo] = useState(false);
    const [__current, setCurrentDesigner] = useState(null);
    const [__total, setRequestTotal] = useState(0); 
    const toast = useRef(null);

    const __designers = designers.map( (d, i) => {
        const data = [];
        
        return data[i] = {
            name: d._NAME + ' ' + d._LASTNAME, value: d._ID, img: d._AVATAR
        };
        
    });

    const designerOptionTemplate = (option) => {
        return (
            <div className="flex justify-content-start align-items-center">
                <Avatar image={option.img} shape="circle" />
                <div className="ml-2">{option.name}</div> 
            </div>
        );
    }

    const selectedDesignerTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex justify-content-start align-items-center">
                    <Avatar image={option.img} shape="circle" />
                    <div className="ml-2">{option.name}</div> 
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const sendData = async () => {
        setLoadingButton(true);

        try {
            const data = {
                _IDREQUEST: idRequest,
                _DESIGNER: selDesigner
            }

            await dataService.saveData(data).then( data => {
                setLoadingButton(false);

                if ( data._SUCCESS ) {

                    toastAlert("success", data._MESSAGE, toast);
                    
                    setTimeout( () => {
                        updateDesignerAssignment(data._ITEMS);
                        hideAndRemove();
                    }, 500);

                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });

        } catch (error) {
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }

    }

    const handleValues = (e) => {
        setSelDesigner(e.value);
        setCurrentInfo(true);

        const filter = designers.find( (it) => it._ID === e.value );

        designerRequestTotal(filter);

        setCurrentDesigner(filter);

    }

    const designerRequestTotal = (data) => {
        const fullname = data._NAME + ' ' + data._LASTNAME;

        let total = 0;
        
        if (dataList) {
            for (let list of dataList) {
                if (list._ASSIGNED === fullname) {
                    total++;
                }
            }
        }
        
        setRequestTotal(total);
    }

    const removeDesigner = () => {
        setSelDesigner("");
        setCurrentInfo(false);
    }

    const hideAndRemove = () => {
        removeDesigner();
        onHide();
    }

    return (
        <div>
            
            <LeftSidebar
                onVisible={ onVisible }
                onHide={ () => onHide() }
                onWidth={500}
            >

                <Toast ref={toast} style={{ fontSize: '14px' }} />
                <div className="p-fluid formgrid grid">
                    <div className="field col-12">
                        <h2 className="text-color">Assign Request</h2>
                    </div>

                    <div className="field col-12">
                        <label htmlFor="name" className="font-semibold">Designers</label>

                        <Dropdown 
                            value={selDesigner}
                            options={__designers} 
                            optionLabel="name" 
                            filter 
                            filterBy="name" 
                            placeholder="Select a Designer"
                            onChange={ (e) => handleValues(e) }
                            itemTemplate={ designerOptionTemplate }
                            valueTemplate={ selectedDesignerTemplate }
                        />
                    </div>

                    {
                        __info && 
                            <div className="field col-12">
                                <div className="p-2">
                                    <div className="shadow-2 p-4 surface-card" style={{ borderRadius: '1rem' }}>
                                        <div className="flex justify-content-between align-items-baseline">
                                            <h5 className="mb-4 text-900 font-medium">Designer Information</h5>
                                            <Button
                                                icon="pi pi-trash"
                                                className="p-button-rounded p-button-danger p-button-text"
                                                tooltip='Remove'
                                                tooltipOptions={{ position: 'top' }}
                                                onClick={ () => removeDesigner() }
                                            />
                                        </div>
                                        
                                        <div className="flex">
                                            <div className="flex flex-column w-9 pr-4">
                                                <span className="mb-3 text-900 font-medium text-green-700">{__current._NAME} {__current._LASTNAME} </span>
                                                <span className="mb-3">
                                                    <div className="mb-1"><span className="text-900 font-medium">Email: </span>{__current._EMAIL}</div>
                                                    <div className="mb-1"><span className="text-900 font-medium">Phone: </span>{__current._PHONE}</div>
                                                    <div className="mb-1"><span className="text-900 font-medium">Total assignments: </span><Badge value={`${__total}`} ></Badge></div>
                                                </span>
                                            </div>
                                            <div className="w-3 flex align-items-start justify-content-end">
                                                <Avatar image={__current._AVATAR} size="xlarge" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                    }

                </div>

                <ButtonsActions 
                    cancel={ () => hideAndRemove() }
                    submit={ () => sendData() }
                    loading={ loadingButton }
                />
                
            </LeftSidebar>
        </div>
    );
}

export default EditAdd;
