import React from 'react';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';

function ChangePassword({ setFieldPassword }) {

    const header = <h6>Pick a password</h6>;

    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 6 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div>
            <div className="p-fluid formgrid grid">
                <div className="field col-12">
                    <label htmlFor="password">New password</label>
                    <Password 
                        id="password"
                        type="password"
                        name="password"
                        required
                        toggleMask 
                        header={header}
                        footer={footer} 
                        onChange={ (e) => { setFieldPassword(e.target.value) }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;