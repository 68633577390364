import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { dateFormat } from '../core/utils';

function DatePicker({ onValue, onId, onName, onChanges }) {
    let today = new Date();
    let minDate = new Date();
    minDate.getDate(today);

    let yearA = today.getFullYear();
    let yearN = today.getFullYear() + 1;

    const [ valueDate, setValueDate ] = useState("");
    const [ selectedOption, setSelectedOption ] = useState(null);
    
    useEffect( () => {
        const dateMongobd = onValue.split('-');

        const date =  dateFormat(dateMongobd[2] + '-' + dateMongobd[1] + '-' + dateMongobd[0], 'yyyy-MM-dd');
        
        setValueDate( new Date(date + 'T00:00:00') );
        
    }, [onValue]);   

    return (
        <div>
            <Calendar 
                id={ onId }
                name={ onName }
                minDate={ minDate }
                dateFormat="dd-mm-yy"
                value={ valueDate  }
                onChange={ (e) => onChanges(e) }
                monthNavigator 
                yearNavigator 
                yearRange={`${yearA}:${yearN}`}
            />
        </div>
    )
}

export default DatePicker;