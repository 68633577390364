import React, { useState } from 'react';
import LeftSidebar from '../../components/LeftSidebar';
import FlagEEUU from '../../assets/images/flag-eeuu.png';
import FlgSpain from '../../assets/images/flag-spain.png';
import { Chip } from 'primereact/chip';
import { iconsTemplate, tab } from '../../core/utils';
import { Image } from 'primereact/image';
import ReactPlayer from 'react-player/lazy';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import DesignsUpload from '../../components/DesignsUpload';
import { downloadFiles } from '../../core/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ViewRequest({ visible, hide, items }) {

    const socialResources = (
        (items._RESOURCES !== undefined) ?
            Object.values(items._RESOURCES).map( (resource, x) => {
                let url  = resource.resource;
                let segm = url.split('.');
                return (
                    <li className="py-2 surface-border" key={x}>
                        <a 
                            className="
                                p-ripple 
                                cursor-pointer 
                                flex 
                                align-items-center 
                                justify-content-between 
                                hover:surface-200 
                                transition-colors 
                                transition-duration-150 
                                border-round p-2" onClick={ () => window.open(resource.resource, '_blank') }>
                            { 
                                (resource.type === "Video") ? 
                                    <ReactPlayer url={ resource.resource } width={100} height={60} style={{ borderRadius: '10px' }} className="test" /> :
                                    
                                    ( segm[0] !== 'https://letusclaimcons' ) ? 
                                        <Image 
                                            src={ resource.resource } 
                                            alt={ resource.resource } 
                                            width={100} height={60} 
                                            imageStyle={{ borderRadius: '10px' }} /> : 
                                            <i className="pi pi-image" style={{'fontSize': '3em'}}></i>
                            }
                                <div style={{ marginLeft: '20px' }}>
                                    <span className="block text-900 mb-1">{ resource._id }</span>
                                </div>
                                <i className="ml-auto pi pi-chevron-right text-600 text-sm"></i>
                                <span role="presentation" className="p-ink" style={{ height: '255px', width: '255px', top: '-98.5px', left: '-89.5px' }}></span>
                        </a>
                    </li>
                )
            }) : null
    );

    const isUrgent = () => {
        
        return (
            <>
                <div 
                    className='font-semibold'
                    style={{ color: (items._URGENT !== 'null') ? '#4BAAF5' : '#6EBE71' }}>
                    { (items._URGENT !== false) ? 'YES' : 'NO' }</div>
            </>
        )
    }

    const socialMedia = (

        (items._FORMATS !== undefined) ?
            Object.values(items._FORMATS).map( (format, i) => {
                const social = format['socialMedia'];
                const icon = iconsTemplate(social);
                return (
                    <div 
                        className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 inline-flex align-items-center mb-2" 
                        key={i} 
                    >
                        {
                            format.socialFormat.map( (dimension, x) => (
                                <div 
                                    key={x} 
                                    tooltip='Design Upload'
                                    tooltipOptions={{ position: 'top' }}>
                                    <Chip className='mr-3' template={ 
                                            <span className='p-chip p-component w-max'> 
                                                {
                                                    (social === 'TIKTOK') ? 
                                                        <FontAwesomeIcon icon={['fab', 'tiktok']} /> :
                                                        <i className={ `pi` +' '+ icon }></i>
                                                }
                                                {tab} <span className='p-chip-text'>{dimension}</span> 
                                            </span> 
                                        } 
                                    />
                                </div>
                            ))
                        }
                    </div>
                )
            }) : null
    );

    const traditionalFormat = (

        (items._FORMATS !== undefined) ?
            Object.values(items._FORMATS).map( (format, i) => {
                const social = format['socialMedia'];
                const icon = iconsTemplate(social);
                let str = format.socialMedia;
                let trd = str.toUpperCase();
                
                return (
                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 inline-flex align-items-center mb-2" key={i}>
                     
                        <i className={ `pi` +' '+ icon }></i>

                        {
                            format.socialFormat.map( (dimension, x) => (
                                <div key={x}>
                                    <Chip label={` ${trd} - ${dimension}` } className="ml-2 mr-2 w-max" />
                                </div>
                            ))
                        }
                    </div>
                )
            }) : null
    );

    const requestFiles = (
        (items._FILES !== undefined) ?
            (items._FILES).map( (file, i) => {
                let fileExt = file.filename;
                const [ext, ...fileName] = fileExt.split('.').reverse();
                return (
                    <li className="py-2 surface-border" key={i}>
                        <a 
                            className="
                                p-ripple 
                                cursor-pointer 
                                flex 
                                align-items-center 
                                justify-content-between 
                                hover:surface-200 
                                transition-colors 
                                transition-duration-150 
                                border-round p-2"
                            >
                                {
                                    ( ext === 'mp4' || ext === 'jpg' || ext === 'png' ) ?
                                    ( ext === 'mp4' ?
                                        <ReactPlayer 
                                            url={ file.pathUrl } 
                                            width={100} 
                                            height={60} 
                                            style={{ borderRadius: '10px' }} 
                                            className="test" /> : 
                                        <Image 
                                            src={ file.pathUrl } 
                                            alt={ fileExt } 
                                            preview 
                                            width={100} 
                                            height={60} 
                                            imageStyle={{ borderRadius: '10px' }} /> ) : 
                                            
                                        ( ext === 'pdf' ? 
                                            <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                                <i className="pi pi-file-pdf" style={{'fontSize': '3em'}}></i></div> : 
                                            <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                                <i className="pi pi-file-word" style={{'fontSize': '3em'}}></i></div> )
                                }
                               
                                <div style={{ marginLeft: '20px' }}>
                                    <span className="block text-900 mb-1">{ file._id }</span>
                                </div>
                                
                                <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                    <i className="ml-auto mr-5 pi pi-download text-600 text-xl"></i>
                                </div>
                                
                        </a>
                    </li>
                )
            }) : null
    );    

    const handleLanguages = () => {
        if (items._LANGUAGES !== undefined && items._LANGUAGES !== null) {
            const languages = items._LANGUAGES;
            const medios = languages.split(',');
    
            return (
                medios.map( (language, i) => (
                    <Chip label={ language } image={ (language === "ENGLISH") ? FlagEEUU : FlgSpain } className="ml-2 mr-2 w-max"  key={ i } />
                ))
            )
        }
    }

    return (
        <div>
            <LeftSidebar
                onVisible={visible}
                onHide={() => hide()}
                onWidth={ 600 }
            >
                <h2 className='text-color'>Request Information</h2>

                <div className="surface-0">
                
                    <ul className="list-none p-0 m-0">
                
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <div className="text-500 w-6 md:w-2 font-medium">Urgent</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> { isUrgent() }</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <div className="text-500 w-6 md:w-2 font-medium">Type</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._TYPE }</div>
                        </li>
                        {
                        items._CAMPAIGN ?
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Campaign</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                    { items._CAMPAIGN }
                                </div>
                            </li> : <></>
                         }
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <div className="text-500 w-6 md:w-2 font-medium">Languages</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                { handleLanguages() }
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <div className="text-500 w-6 md:w-2 font-medium">Account</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._ACCOUNT }</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <div className="text-500 w-6 md:w-2 font-medium">Handle date</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._DHANDLE }</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <div className="text-500 w-6 md:w-2 font-medium">Publication date</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._DPUBLIC }</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Text</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" style={{ whiteSpace: "break-spaces" }}>{ items._CONCEPT }</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <div className="text-500 w-6 md:w-2 font-medium">Caption</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" style={{ whiteSpace: "break-spaces" }}>{ items._CAPTION }</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <div className="text-500 w-6 md:w-2 font-medium">Description</div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" style={{ whiteSpace: "break-spaces" }}>{ items._DESCRIPTION }</div>
                        </li>
                        
                        {
                            (items._TYPEFORMAT === 'traditional' ) ?
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Traditional Format</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        { traditionalFormat }
                                    </div>
                                </li> : 
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Social Media</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        { socialMedia }
                                    </div>
                                </li>
                        }

                        {
                            ( items._ISVIDEO === 'YES' ) ? 
                                <React.Fragment>
                                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                        <div className="text-500 w-6 md:w-2 font-medium">This is a video request</div>
                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                            { items._ISVIDEO }
                                        </div>
                                    </li>

                                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                        <div className="text-500 w-6 md:w-2 font-medium">Video Time</div>
                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                            { items._TIME }
                                        </div>
                                    </li>

                                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                        <div className="text-500 w-6 md:w-2 font-medium">Video Subtitle</div>
                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                            { items._SUBTITLE }
                                        </div>
                                    </li>

                                </React.Fragment>
                                : null
                        }

                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                            <h5>Online Resources</h5>
                            <ul className="list-none p-0 m-0 w-full">
                                { socialResources }
                            </ul>
                        </li>

                        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <h5>Files</h5>
                        <ul className="list-none p-0 m-0 w-full">
                            { requestFiles }
                        </ul>
                    </li> 
                    </ul>
                </div>

            </LeftSidebar>
        </div>
    )
}

export default ViewRequest;