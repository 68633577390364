import Connection from "../core/ApiConnection";

export class NotificationService {
    async getList(data) {
        return await Connection.post('/notifications', data).then(res => res.data);
    }

    async setMarkAsRead(data) {
        return await Connection.post('/notifications-mark', data).then(res => res.data);
    }

    async deleteUnitNotification(data) {
        return await Connection.post('/delete-notification', data).then(res => res.data);
    }

    async deleteAllNotifications() {
        return await Connection.post('/delete-all-notification').then(res => res.data);
    }

    async setMarkAllNotifications() {
        return await Connection.post('/notifications-all-mark').then(res => res.data);
    }
}