import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Panel } from 'primereact/panel';
import { stringToFirstUppercase } from '../core/utils';
import { SCOPES, SCOPE } from '../core/RolePemissions';
import { useProvideModule } from '../hooks/useModules';

function Permissions({ selections, _permissions = [], _selected }) {

    const [selectedPermissions, setSelectedPermissions] = useState(_selected);
    const [selectedAllPermissions, setSelectedAllPermissions] = useState([]);
    const [__permissions, setPermissions] = useState(SCOPES);

    const { 0:module } = useProvideModule();

    useEffect(() => {
        setSelectedPermissions(_permissions);
    }, []);

    const selectedAll = (e) => {
        setSelectedAllPermissions(e);
        const data = [];
        
        if (e) {
            module.map( (module) => {
                SCOPES.map( (scope) => {
                    data.push(module._MODULE + '.' + scope.key);
                });
            });
        } else {
            data.splice(0, data.length);
        }

        selections(data);
        setSelectedPermissions(data);
    }

    const permissions = (
        <React.Fragment>
            <div className="p-fluid formgrid grid mt-3">
                <div className='col-6'>
                    <div className="field-checkbox">
                        <Checkbox 
                            inputId='all'
                            name="all"
                            value={ selectedAllPermissions }
                            onChange={ (e) => selectedAll(e.checked) }
                            checked={ 
                                selectedAllPermissions
                            }
                        />
                        <label htmlFor='all' className='font-semibold'>Select All</label>
                    </div>
                </div>
            </div>

            <div className="p-fluid formgrid grid mt-3">
                {   
                    module.map(( d, x ) => {
                        if (d._MODULE === 'medios' || 
                            d._MODULE === 'formats' ||
                            d._MODULE === 'social media' || 
                            d._MODULE === 'accounts' ||
                            d._MODULE === 'modules' ||
                            d._MODULE === 'roles' ||
                            d._MODULE === 'IT Request') {
                            return (
                                <div className="col-6 mb-3" key={x}>
                                    <Panel header={ stringToFirstUppercase(d._MODULE) }>
                                        
                                        {
                                            SCOPE.map( (p, i) => { 
                                                return (
                                                    <div key={i}>
                                                        <div className="field-checkbox">
                                                            <Checkbox 
                                                                inputId={ d._MODULE + '.' + p.key}  
                                                                name={ p.key }
                                                                value={ d._MODULE + '.' + p.key }
                                                                onChange={ (e) => onValueChange(e) }
                                                                checked={ 
                                                                    selectedPermissions.some( (item) => item === d._MODULE + '.' + p.key )
                                                                }
                                                            />
                                                            <label htmlFor={ d._MODULE + '.' + p.key }>{ stringToFirstUppercase(p.key) }</label>
                                                        </div>
                                                    </div> 
                                            )})
                                        }
                                    </Panel>
                                    
                                </div>
                            )
                        } else {
                            
                            return (
                                <div className="col-6 mb-3" key={x}>
                                    <Panel header={ stringToFirstUppercase(d._MODULE) }>
                                        
                                        {
                                            SCOPES.map( (p, i) => { 
                                                return (
                                                    <div key={i}>
                                                        <div className="field-checkbox">
                                                            <Checkbox 
                                                                inputId={ d._MODULE + '.' + p.key}  
                                                                name={ p.key }
                                                                value={ d._MODULE + '.' + p.key }
                                                                onChange={ (e) => onValueChange(e) }
                                                                checked={ 
                                                                    selectedPermissions.some( (item) => item === d._MODULE + '.' + p.key )
                                                                }
                                                            />
                                                            <label htmlFor={ d._MODULE + '.' + p.key }>{ stringToFirstUppercase(p.key) }</label>
                                                        </div>
                                                    </div> 
                                            )})
                                        }
                                    </Panel>
                                    
                                </div>
                            )
                        }
                    })
                }
            </div>
        </React.Fragment>

    );

    const onValueChange = (e) => {
     
        let _selectedPermissions = [...selectedPermissions];

        if (e.checked) {
            _selectedPermissions.push(e.value);
        }
        else {
            for (let i = 0; i < _selectedPermissions.length; i++) {
                const selectedPermission = _selectedPermissions[i];

                if (selectedPermission === e.value) {
                    _selectedPermissions.splice(i, 1);
                    break;
                }
            }
        }

        selections(_selectedPermissions);

        setSelectedPermissions(_selectedPermissions);
        
    }

    return (
        <div>
            { permissions }
        </div>
    )
}

export default Permissions;