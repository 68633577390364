import React, { useState, useEffect, useRef, memo } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import EditAdd from './EditAdd';
import ViewData from './ViewData';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import LeftSidebar from '../../components/LeftSidebar';
import { UserService } from '../../service/UserService';
import { RolService } from "../../service/RolService";
import { toastAlert } from '../../core/utils';
import Permissions from '../../components/Permissions';
import UserPermissions from './UserPermissions';
import { Can } from '../../components/PermissionGate';

function UserList() {

    const dataService = new UserService();
    const rolsService = new RolService();

    //console.log(localStorage.getItem('token'));

    const [dataList, setDataList] = useState([]);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [updateDataDialog, setUpdateDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [modalTitle, setModalTitle] = useState("Add");
    const [loading, setLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState([]);
    const [checked, setChecked] = useState(null);
    const [rol, setRol] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [visibleEditAdd, setVisibleEditAdd] = useState(false);
    const [visibleLeftPermissions, setVisibleLeftPermissions] = useState(false);
    const [filters2, setFilters2] = useState(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    const [abilities, setAbilities] = useState([]);
    
    useEffect(() => {
        getData();
        getRols();
        initFilters1();
    }, []);

    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'urgent': { value: null, matchMode: FilterMatchMode.IN },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'types': { value: null, matchMode: FilterMatchMode.IN },
        });
        setGlobalFilterValue2('');
    }

    const getData = async () => {
        try {
            
            setLoading(true);
            await dataService.getList().then(data => {

                setDataList(data);
                let item = [...currentStatus];
                data.map( (row, index) => {
                    item[index] = { value: row._ID, status: row._STATUS };
                });
                setCurrentStatus(item);
                
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const getRols = async () => {
        try {
            await rolsService.getList().then( data => {
                setRol(data);
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const hideUpdateDataDialog = () => {
        setUpdateDataDialog(false);
    }

    const confirmDeleteData = (data) => {
        setDataId(data._ID);
        setDataGlobal(data);
        setDeleteDataDialog(true);
    }

    const addItemList = (row) => {
        let data = [...dataList];
        data.splice(0, 0, row);
        setDataList(data);
    }

    const updateItemList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index] = {
            _ID: row._ID,
            _NAME: row._NAME,
            _LASTNAME: row._LASTNAME,
            _EMAIL: row._EMAIL,
            _PHONE: row._PHONE,
            _AVATAR: row._AVATAR,
            _ROLNAME: row._ROLNAME,
            _STATUS: row._STATUS,
            _POSITION: row._POSITION
        };
        
        setDataList(data);
        
    }

    const removeItemList = (row) => {
        let data = [...dataList];
        data.splice(row, 1);
        setDataList(data);
    }

    const deleteDataSelected = async() => {
        
        try {
            setLoading(true);
            setLoadingButton(true);
            await dataService.deleteData(dataId).then(data => {
                if (data._SUCCESS) {
                    removeItemList(dataGlobal);
                    setLoading(false);
                    setLoadingButton(false);
                    setDeleteDataDialog(false);
                    setVisibleLeft(false);
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setVisibleEditAdd(true);
    }

        
    const confirmUpdateStatus = async(e, data) => {
        setChecked(e.target.value);
        setDataId(data._ID);
        setDataGlobal(data);
        setUpdateDataDialog(true);
    }

    const onStatusChange = async (e) => {
        try {
            setLoading(true);
            setLoadingButton(true);
            const data = {
                _ID:    dataId,
                _CHECK: checked
            }

            await dataService.changeStatus(data).then( data => {

                if (data._SUCCESS) {
                    updateStatusList(data._ITEMS);
                    setLoading(false);
                    setLoadingButton(false);
                    setUpdateDataDialog(false);
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
                
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const updateStatusList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index]._STATUS = row._STATUS;
        
        setDataList(data);
        
    }

    const viewData = async(data) => {
        setItem(data);
        setVisibleLeft(true);
    }

    const closeViewData = () => {
        setItem([]);
        setVisibleLeft(false);
    }

    const openEditAdd = async() => {
        setModalTitle("Add");
        setItem([]);
        setVisibleEditAdd(true);
    }

    const closeEditAdd = () => {
        setVisibleEditAdd(false);
    }

    const onAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {
                    (Can('users.create')) ? 
                        <Button 
                            label="New" 
                            icon="pi pi-plus" 
                            className="p-button-success mr-2" 
                            onClick={ openEditAdd } />
                        : <></>
                }
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                { rowData._NAME } { rowData._LASTNAME }
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                { rowData._EMAIL }
            </>
        );
    }

    const positionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Position</span>
                { rowData._POSITION }
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Status</span>
                {
                    (Can('users.status')) ? 
                        <InputSwitch 
                            name='status'
                            checked={ rowData._STATUS } 
                            id={ rowData._ID }
                            tooltip="Change status"
                            tooltipOptions={{ position: 'top' }}
                            onChange={ (e) => { confirmUpdateStatus(e, rowData); }} /> : <></>
                }
            </>
        )
    }

    const openEditAddPermisssions = (row) => {
        setItem(row);
        setAbilities(row._ABILITIES);
        setVisibleLeftPermissions(true);
    }

    const updatedPermissions = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index]._ABILITIES = row._ABILITIES;
        
        setDataList(data);
    }

    const closeEditAddPermisssions = () => {
        setVisibleLeftPermissions(false);
    }

    const actionBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <div className="actions">
                {
                    Can('users.read') ?
                        <Button
                            icon="pi pi-eye"
                            id={ rowData._ID }
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Show'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => viewData(rowData) }
                        /> : <></>
                }

                {
                    Can('users.update') ?
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Edit'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => editData(rowData) }
                        /> : <></>
                }

                <Button
                    icon="pi pi-shield"
                    className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                    tooltip='Permissions'
                    tooltipOptions={{ position: 'top' }}
                    onClick={ () => openEditAddPermisssions(rowData) }
                />

                {
                    Can('users.delete') ?
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                            tooltip='Delete'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => confirmDeleteData(rowData) }
                            disabled={ rowData._POSITION === "Administrator" }
                        /> : <></>
                }
            </div>
        );
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Users Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );

    
    const updateDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideUpdateDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ onStatusChange } />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={dataList}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                        emptyMessage="No users found." header={header} responsiveLayout="scroll" loading={loading}
                        filters={filters2} 
                        filterDisplay="menu" 
                        globalFilterFields={['_NAME', '_LASTNAME', '_EMAIL', '_POSITION', '_STATUS']}
                    >
                        {/*<Column field="_id" header="ID" body={ idBodyTemplate } style={{ width: '2%' }}></Column>*/}
                        <Column field="user_name" header="Name" body={ nameBodyTemplate }></Column>
                        <Column field="email" header="Email" body={ emailBodyTemplate }></Column>
                        <Column field="position" header="Position" body={ positionBodyTemplate }></Column>
                        <Column field="status" header="Status" body={ statusBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } ></Column>
                    </DataTable>

 
                    <EditAdd
                        visible={visibleEditAdd}
                        hide={ closeEditAdd }
                        addItemList={ addItemList }
                        updateItemList={ updateItemList }
                        title={ modalTitle }
                        items={ item }
                        setLoading={ setLoading }
                        roles={ rol }
                        onItems={ setItem }
                        onAlert={ onAlert }
                    />

                    <LeftSidebar
                        onVisible={ visibleLeft }
                        onHide={ closeViewData }
                        onWidth={ 550 }
                    >
                        <ViewData 
                            items={ item }
                            onEdit={ editData }
                            onDelete= { confirmDeleteData }
                            dataList={ dataList }
                            setDataList={ setDataList }
                            setAlert={ toastAlert }
                        />
                    </LeftSidebar>

                    <LeftSidebar 
                        onVisible={ visibleLeftPermissions }
                        onHide={ closeEditAddPermisssions }
                        onWidth={ 600 }
                    >
                        <UserPermissions 
                            id={ item._ID }
                            role={ item._ROLNAME } 
                            permissions={ abilities }  
                            hide={ closeEditAddPermisssions }
                            onAlert={ onAlert }
                            setAbilities={ updatedPermissions }
                            setLoading={ setLoading }
                        />
                    </LeftSidebar>

                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Are you sure you want to delete <b>{dataGlobal._NAME}</b>?</span> }
                        </div>
                    </Dialog>

                    <Dialog visible={updateDataDialog} style={{ width: '450px' }} header="Update status!" modal footer={updateDataDialogFooter} onHide={hideUpdateDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Do you want to change the status of this user <b>{dataGlobal._NAME}</b>?</span> }
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(UserList, comparisonFn);
