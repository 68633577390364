import React, { useRef } from 'react';
import LeftSidebar from '../../components/LeftSidebar';
import { useNotifications } from '../../hooks/useNotifications';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import { NotificationService } from '../../service/NotificationService';
import { toastAlert } from '../../core/utils';
import { Toast } from 'primereact/toast';

function NewNotifications({ visible, hide }) {

    const dataService = new NotificationService();
    const { 0:notifications, 1:setNotifications, 3:setCounter } = useNotifications();
    const toast = useRef(null);

    const readNotification = async(id) => {
    
        try {
            const data = {
                '_ID': id,
            }
            await dataService.setMarkAsRead(data).then( data => {
                if (data._SUCCESS) {
                    setNotifications(data._ITEMS);
                    let filtered = data._ITEMS.filter( (item) => item._READ === null );
                    setCounter(filtered.length);

                    toastAlert('success', data._MESSAGE, toast);
                } else {
                    toastAlert('warn', data._MESSAGE, toast);
                }
                
            });
        } catch (error) {
            toastAlert(error.variant, error.data);
        }
    }

    const footer = (id, row) => {
        const string = row.split(' ');
        const day   = string[0];
        const month = string[1];
        const date  = string[2];
        const year  = string[3];
        const time  = string[4];
        const merid = string[5];

        return (
            <div className='p-fluid grid text-sm font-semibold text-color'>
                <span className='col-5'><i className='pi pi-calendar text-sm font-semibold'></i> { day }, { month } { date } { year } </span>
                <span className='col-5'><i className='pi pi-clock text-sm font-semibold'></i> { time } { merid }</span>
                <span className='col-2 flex justify-content-end cursor-pointer' onClick={ () => readNotification(id) }>
                    <i className='pi pi-check text-sm font-semibold' style={{ color: '#16A34A' }}></i>
                </span>
            </div>
        );
    }

    const emptyNotifications = (
        <span className='text-lg font-semibold'>No new notifications</span>
    );

    const notification = notifications.filter( (item) => item._READ === null ).map( (n, i) => {
        return (
            <Card title={ n._TITLE } style={{  marginBottom: '1em' }} footer={ () => footer(n._ID, n._DATE) } key={i}>
                <p className="m-0 p-0">
                    { n._MESSAGE }
                </p>
            </Card>
        )
    });

    return (
        <>
            <Toast ref={ toast } />
            <LeftSidebar
                onVisible={ visible }
                onHide={ hide }
                onWidth={ 450 }
            >
                <h2 className='text-color'>New Notifications</h2>

                { notification.length > 0 ? notification : emptyNotifications }

                <div className='mt-6'>
                    <div className='absolute z-2 bottom-0 right-0 w-full surface-200 border-top-1 border-400'>
                        <div className='flex justify-content-center p-3'>
                            <Link to='/modules/notifications/' onClick={ () => hide() } className="font-semibold">View all notifications</Link> 
                        </div>
                    </div>
                </div>
            </LeftSidebar>
        </>
    )
}

export default NewNotifications;