export const SCOPES = [
    {key: "create"},
    {key: "read"},
    {key: "update"},
    {key: "delete"},
    {key: "status"}
]

export const SCOPE = [
    {key: "create"},
    {key: "read"},
    {key: "update"},
    {key: "delete"},
]