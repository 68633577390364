import React, { useState, useEffect, useRef, memo } from 'react';
import CampignView from '../campaigns/ViewData';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import LeftSidebar from '../../components/LeftSidebar';
import { CampaignsService } from '../../service/CampaignsService';
import { MediosService } from "../../service/MediosService";
import { toastAlert } from '../../core/utils'; 
import StatusTimeline from '../../components/StatusTimeline';
import { useAuth } from '../../hooks/useAuth';
import { Can } from '../../components/PermissionGate';
import { ProgressBar } from 'primereact/progressbar';

function ArchivedList() {
    const dataService = new CampaignsService();
    const mediosService = new MediosService();

    const [dataList, setDataList] = useState([]);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [updateDataDialog, setUpdateDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [modalTitle, setModalTitle] = useState("Add");
    const [loading, setLoading] = useState(false);
    const [medios, setMedios] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [visibleStatus, setVisibleStatus] = useState(false);
    const [allsStatus, setAllsStatus] = useState([]);
    const [__percentage, setPercentageProgress] = useState(0);
    const [_records, setRecords] = useState([]);

    const { 3:checkPermissions } = useAuth();
    
    useEffect(() => {
        getData();

        if ( checkPermissions.some( (item) => item === 'medios.read' ) ) {
            getMedios();
        }
        
    }, [checkPermissions]);

    const getData = async () => {
        try {
            
            setLoading(true);
            await dataService.getList().then(data => {
                const filter = data.filter( (item) => item._STATUS._STATUS === "FINISHED" );
                setDataList(filter);
                setLoading(false);

            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }
    }


    const getMedios = async () => {
        try {
            await mediosService.getList().then( data => {
                setMedios(data);
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const confirmDeleteData = (data) => {
        setDataId(data._ID);
        setDataGlobal(data);
        setDeleteDataDialog(true);
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setShowAdd(true);
    }

    const viewData = async(data) => {
        setItem(data);
        setVisibleLeft(true);
    }

    const closeViewData = () => {
        setItem([]);
        setVisibleLeft(false);
    }

    const viewStatus = async(id, data, records) => {
        setDataId(id);
        setAllsStatus(data);
        setRecords(records);
        setVisibleStatus(true);
    }

    const closeViewstatus = () => {
        setDataId(null);
        setAllsStatus([]);
        setVisibleStatus(false);
    }

    const onAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                { rowData._NAME }
            </>
        );
    }

    const startDatetBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                { rowData._START }
            </>
        );
    }

    const endDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                { rowData._END }
            </>
        );
    }
    
    const statusBodyTemplate = (rowData) => {

        let statuses  = rowData._STATUS;
        let allStatus = statuses;
        let records   = rowData._RECORDS;

        return (
            <>
                <span className="p-column-title">Status</span>

                {
                    (Can('campaigns.status')) ? 
                        <Button 
                            label={ allStatus._STATUS } 
                            className="p-button-text" 
                            onClick={ () => viewStatus(rowData._ID, allStatus, records) }  
                        /> : <h6 className="text-color">{ allStatus.status }</h6>
                }
            </>
        )
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Register by</span>
                { rowData._REGISTER }
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
                {
                    Can('campaigns.read') ? 
                        <Button
                            icon="pi pi-eye"
                            id={ rowData._ID }
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Show'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => viewData(rowData) }
                        /> : <></>
                }
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Campaigns Archived Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" ></Toolbar>

                    <DataTable ref={dt} value={dataList} stripedRows rowHover
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} campaigns"
                        globalFilter={globalFilter} emptyMessage="No campaigns found." header={header} responsiveLayout="scroll" loading={loading}>
                        {/*<Column field="_id" header="#" body={ idBodyTemplate } style={{ width: '3%' }}></Column>*/}
                        <Column field="name" header="Name" body={ nameBodyTemplate }></Column>
                        <Column field="start" header="Start date" body={ startDatetBodyTemplate }></Column>
                        <Column field="end" header="End date" body={ endDateBodyTemplate }></Column>
                        <Column field="status" header="Status" body={ statusBodyTemplate }></Column>
                        <Column field="register" header="Register by" body={ emailBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '20%' }}></Column>
                    </DataTable>

                    <LeftSidebar
                        onVisible={ visibleLeft }
                        onHide={ closeViewData }
                        onWidth={ 600 }
                    >
                        <CampignView 
                            items={ item }
                            onEdit={ editData }
                            onDelete= { confirmDeleteData }
                            dataList={ dataList }
                            setDataList={ setDataList }
                            setAlert={ toastAlert }
                            setLoading={ setLoading }
                            onAlert={ onAlert }
                            setItems={ setItem }
                        />
                    </LeftSidebar>

                    <LeftSidebar
                        onVisible={ visibleStatus }
                        onHide={ closeViewstatus }
                        onWidth={ 450 }
                    >
                        <StatusTimeline 
                            items={ allsStatus }
                            setLoading={ setLoading }
                            id={ dataId }
                            onAlert={ onAlert }
                            setDataList={ setDataList }
                            dataList={ dataList }
                            hide={ closeViewstatus }
                            records={ _records }
                        />
                    </LeftSidebar>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(ArchivedList, comparisonFn);