import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { createId } from '../core/utils';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

function AddResources({ formResources, setFormResources, onDeleteresource }) {

    const [selectedIndex, setSelectedIndex] = useState("");

    const toast = useRef(null);

    const dropdownValues = [
        { name: 'Video', value: 'Video' },
        { name: 'Image', value: 'Image' }
    ];

    const addFields = () => {
        let object = {
            _id: "",
            type: "",
            resource: ""
        }

        setFormResources([...formResources, object]);
    }

    const removeFields = (index) => {
        let data = [...formResources];
        data.splice(index, 1);
        setFormResources(data);
    }

    const handleResourceChange = (event, index) => {

        const newData = formResources.map( (resources, i) => {

            if (index === i) {
                 return {
                    ...resources,
                    _id: createId(),
                    type : (event.target.name === "type") ? event.value : resources.type,
                    resource:  (event.target.name === "resource") ? event.target.value : resources.resource
                };
            } else {
                return resources;
            }

        });
        
        setFormResources(newData);
    }

    let selectedData;
    const confirm = (values, index) => {
        setSelectedIndex(index);
        selectedData = values;
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };

    const accept = () => {
        let data = [...formResources];
        data.splice(selectedIndex, 1);
        setFormResources(data);

        if (selectedData._id !== "") {
            onDeleteresource(selectedData);
        }
    }

    const reject = () => {
        return;
    }

    return (
        <div className="p-fluid formgrid grid">
        <Toast ref={toast} />

            <div className="field col-12">
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <span style={{ background: "#22C55E", borderRadius: '5px', padding: '5px', cursor: 'pointer', color: '#fff' }} onClick={ () => { addFields(); }}>
                        <i className="pi pi-plus" style={{ fontSize: '.8rem', color: "#fff" }}></i>
                        add resources
                    </span>
                </div>
            </div>

            {
                (formResources !== undefined && formResources.length > 0 && formResources !== null) ?
                    formResources.map( (form, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="field col-4">
                                    <label htmlFor="type">Type</label>
                                    <Dropdown
                                        options={ dropdownValues }
                                        optionLabel="name"
                                        optionValue="value"
                                        placeholder="Choose..."
                                        name="type"
                                        value={ form.type }
                                        onChange={ event => handleResourceChange(event, index)}
                                    />
                                </div>

                                <div  className={`field ${(formResources.length > 0) ? 'col-7' : 'col-8'} `}>
                                    <label htmlFor="resource">Url</label>
                                    <InputText
                                        id="resource"
                                        type="text"
                                        name="resource"
                                        value={ form.resource }
                                        required
                                        onChange={ event => handleResourceChange(event, index) }
                                    />
                                </div>

                                <div className="field col-1 md:my-4" style={{ textAlign: 'right' }}>
                                    <Button
                                        icon="pi pi-trash"
                                        className="p-button-text p-button-danger"
                                        onClick={ (e) => { (form._id !== "") ? confirm(form, index) : removeFields(index) }}>
                                    </Button>
                                </div>
                            </React.Fragment>
                        )
                    })
                : <div className="w-full text-center"><h5>No added resources</h5></div>
            }

            <ConfirmDialog />

        </div>
    )
}

export default AddResources;