import React, { useEffect, useState, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { Card } from "primereact/card";
import { downloadFiles, converFromBytes, convertTimestamp } from "../core/utils";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ButtonsActions from "./ButtonsActions";
import { SelectButton } from "primereact/selectbutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Tag } from "primereact/tag";
import { toastAlert } from "../core/utils";
import { Toast } from "primereact/toast";
import { AssignmentService } from "../service/AssignmentService";
import { ProgressBar } from "primereact/progressbar";
import { Avatar } from 'primereact/avatar';
import { InputText } from 'primereact/inputtext';
import FlagEEUU from '../assets/images/flag-eeuu.png';
import FlgSpain from '../assets/images/flag-spain.png';
import { Image } from "primereact/image";
import UpdateDesignUpload from "../modules/assignments/UpdateDesignUpload";

function ExpandSidebar({ items, visible, hide, id, updateItems, updateButton }) {
    const service = new AssignmentService();

    const [currentItem, setCurrentItem] = useState([]);
    const [_dimension, setDimension] = useState({});
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [optionValue, setOptionValue] = useState(null);
    const [observation, setObservation] = useState(null);
    const toast = useRef(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [_comment, setComment] = useState("");
    const [openModalUpload, setOpenModalUpload] = useState(false);


    const options = ["APPROVED", "DISAPPROVE"]; 

    useEffect(() => {
        setCurrentItem(items[0]);
    }, [items]);

    const openModalUpdate = () => {
        setOpenModalUpload(true);
    }

    const hideModalUpdate = () => {
        setOpenModalUpload(false);
    }

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const isLoader = loading ? "flex justify-content-center align-content-center block min-h-screen" : "hidden";

    const grid = loading ? "hidden" : "grid";

    const renderFooter = () => {
        return <ButtonsActions text={"YES"} cancel={() => closeModal()} submit={() => dataSend()} loading={loadingButton} />;
    };

    const statusTemplate = (data) => {
        let find;
        let variant;
        let text;
        let icon;

        if (data !== undefined) {
            find = data.slice(-1);
            if (find[0] === "SAVED") {
                text = "SAVED";
                variant = "info";
                icon = "pi pi-save";
            } else if (find[0] === "APPROVED") {
                text = "APPROVED";
                variant = "success";
                icon = "pi pi-check-circle";
            } else {
                text = "DISAPPROVED";
                variant = "danger";
                icon = "pi pi-times";
            }
        }

        return <Tag value={text} icon={icon} severity={variant} className="text-sm"></Tag>;
    };

    const confirmDeleteData = () => {
        setDeleteDataDialog(true);
    };

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    };

    const onAlert = (event, message) => {
        toastAlert(event, message, toast);
    };

    const deleteDataDialogFooter = <ButtonsActions cancel={() => hideDeleteDataDialog()} text="Delete" submit={() => deleteData()} loading={loadingButton} />;

    const dataSend = async () => {
        setLoadingButton(true);
        try {
            const data = {
                _ID: id,
                _IDFILE: currentItem._FILEID,
                _OPTION: optionValue,
                _OBSERVATION: observation,
            };

            await service
                .approveDesign(data)
                .then((response) => {
                    if ( response._SUCCESS ) {
                        toastAlert("success", response._MESSAGE, toast);
                        let resp = response._ITEMS;
                        let up = resp._UPLOADED;
                        let filter = up.filter((item) => item._FILEID === currentItem._FILEID);
                        setCurrentItem(filter[0]);
                        updateItems(up);
                    } else {
                        toastAlert("warning", response._MESSAGE, toast);
                    }
                })
                .finally(() => {
                    setLoadingButton(false);
                    setOptionValue(null);
                    setObservation(null);
                    closeModal();
                });
        } catch (error) {
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    };

    const deleteData = async () => {
        setLoadingButton(true);

        try {
            const data = {
                _ID: id,
                _IDFILE: currentItem._FILEID,
            };

          await service.designDelete(data).then((response) => {
                setLoadingButton(false);

                if ( response._SUCCESS ) {
                    updateItems(response._ITEMS);
                    updateButton();
                    hideDeleteDataDialog();
                    hide();
                    toastAlert("success", response._MESSAGE, toast);
                } else {
                    toastAlert("warning", response._MESSAGE, toast);
                }
            });
        } catch (error) {
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    };

    const addComments = async () => {
        setLoadingButton(true);
        try {
            const data = {
                _ID: id,
                _IDFILE: currentItem._FILEID,
                _TITLE: currentItem._FILENAME,
                _COMMENTS: _comment,
            };

            await service
                .addComments(data)
                .then((response) => {
                    if (response._SUCCESS) {
                        let resp = response._ITEMS;
                        let up = resp._UPLOADED;
                        let filter = up.filter((item) => item._FILEID === currentItem._FILEID);
                        setCurrentItem(filter[0]);
                        updateItems(response._ITEMS);
                        toastAlert("success", response._MESSAGE, toast);
                    } else {
                        toastAlert("warning", response._MESSAGE, toast);
                    }
                })
                .finally(() => {
                    setLoadingButton(false);
                    closeModal();
                    setComment("");
                });
        } catch (error) {
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    };

    
    const commentsTemplate = (data) => {
    
        if (data !== undefined) {
            let reverse = data.toReversed();
            return (
                (data.length > 0) ?
                reverse.map( (comment, i) => (
                        <Card key={i} className="mb-2">
                            <div className="py-3 flex md:align-items-start  flex-column md:flex-row">
                                <Avatar image={ comment.user.avatar } size="large" />
                                <div className="ml-5">
                                    <span className="text-900 font-medium block mb-2">{ comment.user.name }</span>
                                    <div className="text-700 mb-2">{ comment.comment }</div>
                                    <a className="text-primary cursor-pointer">
                                        <i className="pi pi-calendar text-sm mr-2"></i>
                                        <span>{ comment.date }</span>
                                    </a>
                                </div>
                            </div>
                        </Card> 
                    )) :
                    <Card><h5>No Comments</h5></Card>
                    
            );
        }
    };

    const footerComments = (
        <div className="mb-1" style={{ width: '40%' }}>
            <span className="p-input-icon-right" style={{ width: '100%' }}>
                <i className="pi pi-telegram cursor-pointer" onClick={ () => addComments() } style={{ 'fontSize': '1.5em', marginTop: '-10px' }} />

                <InputTextarea 
                    className="border-1 
                               border-round-top-xl 
                               border-round-bottom-xl 
                               border-solid w-full 
                               border-pink-600" 
                    rows={1} 
                    value={_comment} 
                    placeholder="Add Comments"
                    onChange={(e) => setComment(e.target.value)} />
            </span>
        </div>
    )

    return (
        <div>
            <Toast ref={toast} />
            <Sidebar visible={visible} fullScreen onHide={() => hide()}>
                <div className={isLoader}>
                    <div
                        className="scalein animation-duration-1000 animation-iteration-infinite flex align-items-center justify-content-center
                            font-bold border-round m-2 px-5 py-3"
                    >
                        <h5>Loading...</h5>
                    </div>
                </div>
                <div className={grid}>
                    <div className="col-7">
                        <h2 className="text-color">Design Request</h2>
                        {currentItem !== undefined ? (
                            <Card>
                                <div className={loading ? `hidden` : `block`}>
                                    {/**{fileType(currentItem._FILETYPE, currentItem._FILEURL, currentItem._FILENAME)}


                                    <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://letusclaimcons.sharepoint.com/:i:/g/Ee7BAUC-wXdNhop743WuK5gBNAkJTqhVEo31H_z3EWJIyw?e=baq3rr' />" }} /> */ }
                                    <div className="flex justify-content-start align-items-center mb-2 mt-2">
                                        <h5>{currentItem._FILENAME}</h5>
                                    </div>

                                    <div className="flex justify-content-end align-items-center mb-2">

                                        <Button 
                                            tooltip="Approve" 
                                            tooltipOptions={{ position: "top" }} 
                                            className="p-button-success p-button-sm mr-1" 
                                            style={{
                                                background: '#fff',
                                                color: '#000'
                                            }}
                                            onClick={() => openModal()} >
                                            <i className="pi pi-check" style={{ fontSize: "1.5em", color: 'green' }}></i>
                                            <span className="px-3">Approve</span>
                                        </Button>

                                        {
                                            (currentItem._FILEURLENGLISH) ?
                                                <React.Fragment>
                                                    <Button 
                                                        className="p-button-sm mr-1 p-1" 
                                                        aria-label="English"
                                                        tooltip="View-EN"
                                                        tooltipOptions={{ position: "top" }}
                                                        style={{
                                                            background: '#fff',
                                                            color: '#000'
                                                        }}
                                                        onClick={() => downloadFiles(currentItem._FILEURLENGLISH, currentItem._FILENAME)}
                                                    >
                                                        <Image src={FlagEEUU} imageClassName="w-2rem" />
                                                        <span className="px-3">View</span>
                                                    </Button>
                                                    
                                                </React.Fragment>
                                                 : null
                                        }

                                        {
                                            (currentItem._FILEURLSPANISH) ?
                                                <React.Fragment>
                                                    <Button 
                                                        className="p-button-sm mr-1 p-1" 
                                                        aria-label="English"
                                                        tooltip="View-ESP"
                                                        tooltipOptions={{ position: "top" }}
                                                        style={{
                                                            background: '#fff',
                                                            color: '#000'
                                                        }}
                                                        onClick={() => downloadFiles(currentItem._FILEURLSPANISH, currentItem._FILENAME)}
                                                    >
                                                        <Image src={FlgSpain} imageClassName="w-2rem" />
                                                        <span className="px-3">View</span>
                                                    </Button>
                                                    
                                                </React.Fragment>
                                                 : null
                                        }

                                        <Button 
                                            tooltip="Delete" 
                                            tooltipOptions={{ position: "top" }} 
                                            className="p-button-warning p-button-sm" 
                                            style={{
                                                background: '#fff',
                                                color: '#000'
                                            }}
                                            onClick={openModalUpdate} >
                                            <i className="pi pi-replay" style={{ fontSize: "1.5em", color: 'red' }}></i>
                                            <span className="px-3">Update</span>
                                        </Button>
                                    </div>

                                    <ul className="list-none m-0 p-0">
                              
                                        <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                                            <span className="text-600 font-medium text-base">Format</span>
                                            <span className="text-900 font-medium text-base">{currentItem._FILEFORMAT}</span>
                                        </li>
                                        <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                                            <span className="text-600 font-medium text-base">Created</span>
                                            <span className="text-900 font-medium text-base">{currentItem._FILEDATE}</span>
                                        </li>

                                        <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                                            <span className="text-600 font-medium text-base">Status</span>
                                            <span className="text-900 font-medium text-base">{statusTemplate(currentItem._FILESTATUS)}</span>
                                        </li>
                                    </ul>
                                </div>
                            </Card>
                        ) : null}
                    </div>

                    <div className="col-5" style={{
                        height: '700px',
                        overflowY: 'scroll',
                        paddingBottom: '50px'
                    }}>
                        <h2 className="text-color">Comments</h2>

                        {
                            (currentItem !== undefined) ?
                                commentsTemplate(currentItem._COMMENTS) 
                                : null
                        }

                        <div className="grid">
                            <div className="col-12 absolute z-2 bottom-0" style={{ paddingRight: '50px' }} >
                                { footerComments }
                            </div>
                        </div>
            
                    </div>
                </div>

                {
                    ( currentItem !== undefined ) ?
                        <Dialog 
                            visible={openModalUpload} 
                            style={{ width: '550px' }} 
                            header={`Upload Design Upload ` +  currentItem._FILEFORMAT } modal  onHide={hideModalUpdate}>

                            <UpdateDesignUpload 
                                data={ items[0] }
                                updateData={ updateItems }
                                hide={ () => hideModalUpdate() }
                                uploadNotify={updateButton}
                                id={id}
                                closeExpand={() => hide()}
                                onAlert={(event, message) => onAlert(event, message)}
                            />
                        </Dialog> : null
                }

                <Dialog header="Approve Design" visible={modalVisible} style={{ width: "40vw" }} footer={renderFooter()} onHide={() => closeModal()}>
                    <div className="p-fluid formgrid grid mt-2 mb-4">
                        <div className="field col-12 mb-5">
                            <SelectButton value={optionValue} options={options} onChange={(e) => setOptionValue(e.value)} />
                        </div>

                        <div className="field col-12">
                            <label htmlFor="observation" className="font-semibold">
                                Observation:
                            </label>
                            <InputTextarea rows={1} cols={2} value={observation || ""} onChange={(e) => setObservation(e.target.value)} />
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={deleteDataDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                    <div className="flex align-items-center justify-content-center pb-8">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                        <span>
                            Do you want to remove this <b>Design</b>?
                        </span>
                    </div>
                </Dialog>

                <Dialog visible={loadingButton} style={{ width: "450px" }} showHeader={false} modal onHide={loadingButton}>
                    <div className="text-center">
                        <div className="grid">
                            <div className="col-12">
                                <p className="text-2xl p-4">
                                    <b>Please wait...</b>
                                </p>
                            </div>
                            <div className="col-12">
                                <ProgressBar mode="indeterminate" className="w-full text-color h-1rem"></ProgressBar>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Sidebar>
        </div>
    );
}

export default ExpandSidebar;
