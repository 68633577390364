import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { toastAlert }from '../core/utils';
import { AssignmentService } from '../service/AssignmentService';
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import ButtonsActions from './ButtonsActions';

function DesignsUpload({ data, format, alert, updateData, hide, uploadNotify }) {

    const dataService = new AssignmentService();

    const [totalSize, setTotalSize] = useState(0);
    const toast = useRef(null);
    const fileUploadRef = useRef(null);
    const [upload, setUpload] = useState([]);
    const [__urlEnglish, setUrlEnglish] = useState('');
    const [__urlSpanish, setUrlSpanish] = useState('');
    const [fileName, setFileName] = useState('');
    const [_language, setLanguage] = useState([]);


    const [loadingButton, setLoadingButton] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect( () => {
        countLanguage();
    }, [data._LANGUAGES]);
    
    const countLanguage = () => {
        let ld = data._LANGUAGES;
        let split = ld.split(',');
        setLanguage(split);
    }

    const onTemplateSelect = ({ files }) => {
        const [file] = files;
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
            setUpload(file);
            setTotalSize(e.loaded);
        }; 

        fileReader.readAsDataURL(file);
    }

    const uploadFile = async () => {
        setLoading(true);
        try {
            
            let formData = new FormData();
            formData.append("_ID", data._IDASSIGNMET);
            formData.append("_IDCAMPAIGN", data._IDCAMPAIGN);
            formData.append("_FORMAT", format);
            formData.append('_FILE', upload);

            await dataService.designUpload(formData).then( data => {
                if ( data._SUCCESS ) {
                    updateData(data._ITEMS);
                    alert("success", data._MESSAGE, toast);
                } else {
                    alert("warn", data._MESSAGE, toast);
                }
            }).finally( () => {
                setLoading(false);
                hide();
            });

        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const sendData = async() => {
        setLoading(true);
        try {
            const datas = {
                _FILENAME: fileName,
                _ID: data._IDASSIGNMET,
                _IDCAMPAIGN: data._IDCAMPAIGN,
                _FORMAT: format,
                _FILEURLENGLISH: __urlEnglish,
                _FILEURLSPANISH: __urlSpanish,
            }
            
            await dataService.addDesigns(datas).then( res => {
                if ( res._SUCCESS ) {
                    updateData(res._ITEMS);
                    uploadNotify();
                    alert("success", res._MESSAGE, toast);
                } else {
                    alert("warn", res._MESSAGE, toast);
                }
            }).finally( () => {
                setLoading(false);
                hide();
            });
        } catch (error) {
            alert(error.variant, error.data);
        }
    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 200000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 20 MB`} style={{width: '300px', height: '20px', marginLeft: 'auto'}}></ProgressBar>
            </div>
        );
    }

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center justify-content-around flex-wrap">
                <div className="flex align-items-center" style={{width: '40%'}}>
                    <img alt={file.name} role="presentation" src={ file.objectURL } width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Drag and Drop Image Here</span>
            </div>
        )
    } 

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

    return (
        <div className='w-full'>
            <Toast ref={toast} />

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

            {/** <FileUpload 
                ref={ fileUploadRef } 
                name="_FILE" 
                accept="image/*" 
                multiple 
                maxFileSize={ 20000000 }
                onSelect={onTemplateSelect} 
                onError={onTemplateClear} 
                onClear={onTemplateClear}
                headerTemplate={headerTemplate} 
                itemTemplate={itemTemplate} 
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} 
                customUpload 
                uploadOptions={uploadOptions} 
                cancelOptions={cancelOptions} 
                uploadHandler={ () => uploadFile() } />*/} 

                <div className="field">
                    <label htmlFor="name" className="block">Design Name</label>
                    <span className="p-input-icon-left w-full">
                        <i className="pi pi-image text-pink-500" />
                        <InputText 
                            id="name"
                            value={fileName}
                            onChange={ (text) => setFileName(text.target.value) }
                            className="block w-full" 
                            placeholder='Design Campaign 001' 
                        />
                    </span>
                </div>

                {
                    (_language[0] === 'ENGLISH' || _language[1] === 'ENGLISH' || data._LANGUAGES === "ENGLISH") ? 
                        <div className={`field ${(data._LANGUAGES === "ENGLISH") ? 'mb-8' : ""}`}>
                            <label htmlFor="url" className="block">Url from Design <b>ENGLISH</b> </label>
                            <span className="p-input-icon-left w-full">
                                <i className="pi pi-globe text-pink-500" />
                                <InputText 
                                    id="url" 
                                    aria-describedby="url-help" 
                                    value={__urlEnglish}
                                    onChange={ (text) => setUrlEnglish(text.target.value) }
                                    className="block w-full" 
                                    placeholder='https://letusclaimcons.sharepoint.com' 
                                />
                            </span>
                            <small id="url-help" className="block">Enter the url of the design.</small>
                        </div> : null
                }

                {
                    (_language[1] === 'SPANISH' || _language[0] === 'SPANISH' || data._LANGUAGES === 'SPANISH') ? 
                        <div className="field mb-8">
                            <label htmlFor="url" className="block">Url from Design <b>SPANISH</b></label>
                            <span className="p-input-icon-left w-full">
                                <i className="pi pi-globe text-pink-500" />
                                <InputText 
                                    id="url" 
                                    aria-describedby="url-help" 
                                    value={__urlSpanish}
                                    onChange={ (text) => setUrlSpanish(text.target.value) }
                                    className="block w-full" 
                                    placeholder='https://letusclaimcons.sharepoint.com' 
                                />
                            </span>
                            <small id="url-help" className="block">Enter the url of the design.</small>
                        </div> : null
                }

            <ButtonsActions
                cancel={() => hide() }
                submit={ () => sendData() }
            />

            <Dialog visible={loading} style={{ width: '450px' }} showHeader={false} modal onHide={loading}>
                <div className="text-center">
                    <div className="grid">
                        <div className="col-12">
                            {
                                format &&
                                    <p className="text-2xl p-4"> 
                                        Uploading design format <b>{ format }</b>, please wait...
                                    </p>
                            }
                        </div>
                        <div className="col-12">
                            <ProgressBar mode="indeterminate" className='w-full text-color h-1rem' ></ProgressBar>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default DesignsUpload;