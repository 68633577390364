import React, { useEffect, useState, useRef } from 'react';
import { RequestItService } from '../../service/RequestItService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Can } from '../../components/PermissionGate';
import LeftSidebar from '../../components/LeftSidebar';
import EditAdd from './EditAdd';
import { separateString, toastAlert } from '../../core/utils';
import { Tag } from 'primereact/tag';
import ViewData from './ViewData';
import ViewRequestUpload from './ViewRequestUpload';

export default function RequestItList() {
    const dataService = new RequestItService();

    const [dataList, setDataList] = useState([]);
    const [item, setItem] = useState([]);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [filters2, setFilters2] = useState(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [ showAdd, setShowAdd ] = useState(false);
    const [modalTitle, setModalTitle] = useState("Add");
    const [ isUserIt, setUserIt ] = useState([]);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [modalUploadRequest, setModalUploadRequest] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect( () => {
        let isMounted = true; 
        
        if (isMounted) {
            getData();
            getUsersIt();
        }

        return () => { isMounted = false };
    }, []);

    const getData = async () => {
        try {         
            setLoading(true);
            await dataService.getList().then(data => {
                setDataList(data);
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const getUsersIt = async () => {
        try {
            await dataService.searchUsersIt().then( data => {
                setUserIt(data);
            });
        } catch (error) {
            onAlert(error.variant, error.data);
        }
    }

    const openNew = () => {
        setModalTitle("Add");
        setItem([]);
        setShowAdd(true);
    }

    const hideNew = () => {
        setShowAdd(false);
    }

    const openModalRequestUpload = (items) => {
        setItem(items);
        setModalUploadRequest(true);
    }

    const hideModalRequestUpload = () => {
        setItem([]);
        setModalUploadRequest(false);
    }

    const addItemList = (row) => {
        let data = [...dataList];
        data.splice(0, 0, row);
        setDataList(data);
    }

    const updateItemList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);

        data[index] = {
            _ID: row._ID,
            _TITLE: row._TITLE,
            _DESCRIPTION: row._DESCRIPTION,
            _LANGUAGES: row._LANGUAGES,
            _RESOURCES: row._RESOURCES,
            _FILES: row._FILES,
            _DATEDELIVERY: row._DATEDELIVERY,
            _DATEASSIGN: row._DATEASSIGN,
            _UPLOADED: row._UPLOADED,
            _NOTIFY: row._NOTIFY,
            _IDASSIGNED: row._IDASSIGNED,
            _REGISTER: row._REGISTER,
            _ASSIGNED: row._ASSIGNED
        };
        
        setDataList(data);
    }

    const removeItemList = (row) => {
        let data = [...dataList];
        data.splice(row, 1);
        setDataList(data);
    } 

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {
                    (Can('IT Request.create')) ? 
                        <Button 
                            label="New" 
                            icon="pi pi-plus" 
                            className="p-button-success mr-2" 
                            onClick={ openNew } />
                        : <></>
                }
            </React.Fragment>
        )
    }

    const onAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Request It Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Search..." />
            </span>
        </div>
    );

    const titlesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                { rowData._TITLE }
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                { separateString(rowData._DESCRIPTION) }
            </>
        );
    }

    const registerBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Register</span>
                { rowData._REGISTER }
            </>
        );
    }

    const assignedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Assigned</span>
                { rowData._ASSIGNED }
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Delivery</span>
                { rowData._DATEDELIVERY }
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        let status;

        if (rowData._NOTIFY === false) {
            status = (<Tag className="mr-2" severity="warning" value='SAVED' />);
        } else {
            status = (<Tag className="mr-2" severity="success" value='NOTIFIED'/>);
        }
        return (
            <>
                <span className="p-column-title">Status</span>
                { status }
            </>
        );
    }

    const viewData = async(data) => {
        setItem(data);
        setVisibleLeft(true);
    }

    const closeViewData = () => {
        setItem([]);
        setVisibleLeft(false);
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setShowAdd(true);
    }

    const confirmDeleteData = (data) => {
        setDataId(data._ID);
        setDataGlobal(data);
        setDeleteDataDialog(true);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const deleteDataSelected = async() => {
        
        try {
            setLoading(true);
            setLoadingButton(true);
            await dataService.deleteData(dataId).then(data => {
                setLoading(false);
                setLoadingButton(false);
                if (data._SUCCESS) {
                    removeItemList(dataGlobal);
                    setDeleteDataDialog(false);
                    setVisibleLeft(false);
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {
                    Can('IT Request.read') ? 
                        <Button
                            icon="pi pi-eye"
                            id={ rowData._ID }
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Show'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => viewData(rowData) }
                        /> : <></>
                }

                {
                    Can('IT Request.read') ? 
                        <Button
                            icon="pi pi-list"
                            id={ rowData._ID }
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='View Request'
                            tooltipOptions={{ position: 'top' }}
                            disabled={ rowData._NOTIFY === false }
                            onClick={ () => openModalRequestUpload(rowData) }
                        /> : <></>
                }

                {
                    Can('IT Request.update') ? 
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Edit'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => editData(rowData) }
                            disabled={ rowData._NOTIFY === true }
                        /> : <></>
                }

                {
                    Can('IT Request.update') ? 
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                            tooltip='Delete'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => confirmDeleteData(rowData) }
                            disabled={ rowData._NOTIFY === true }
                        /> : <></>
                }
            </div>
        );
    }

    const deleteDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );

    const rowClass = (rowData) => {
        return {
            'row-assigned': rowData._NOTIFY === true
        }
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={dataList} 
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                        emptyMessage="No request found." header={header} responsiveLayout="scroll" loading={loading}
                        filters={filters2} 
                        filterDisplay="menu" 
                        rowClassName={ rowClass }
                        globalFilterFields={['_TITLE', '_DESCRIPTION', '_DESIGNER', '_REGISTER', '_DATEASSIGN']}
                    >
                        
                        <Column field="titles"  filterField='titles' body={titlesBodyTemplate} filterMatchMode={FilterMatchMode.IN} showFilterMatchModes={false}  header="Title" ></Column>
                        <Column field="text" header="Description" body={descriptionBodyTemplate} ></Column>
                        <Column field="description"  filterField='status' body={statusBodyTemplate}  header="Status" ></Column>
                        <Column field="register"  header="Register by" body={registerBodyTemplate} ></Column>
                        <Column field="assigned" header="Assigned" body={assignedBodyTemplate} ></Column>
                        <Column field="datedelivery" header="Date Delivery" body={dateBodyTemplate} ></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '25%' }}></Column>

                    </DataTable>

                    <EditAdd
                        visible={ showAdd }
                        hide={ hideNew }
                        addItemList={ addItemList }
                        updateItemList={ updateItemList }
                        title={ modalTitle }
                        items={ item }
                        setLoading={ setLoading }
                        onItems={ setItem }
                        onAlert={ onAlert }
                        isUser={ isUserIt.items || [] }
                    />

                    <ViewData 
                        visible={visibleLeft}
                        closeViewData={closeViewData}
                        items={item}
                        dataUpload={updateItemList}
                        alert={onAlert}
                        loading={loading}
                        setLoading={setLoading}
                    />

                    <ViewRequestUpload 
                        visible={modalUploadRequest}
                        hide={() => hideModalRequestUpload()}
                        items={item}
                    />

                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Do you want to remove the Request It <b>{dataGlobal._TITLE}</b>?</span> }
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}
