import React, { useState }  from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import LeftSidebar from './components/LeftSidebar';
import Profile from './modules/users/Profile';
import LogoDark from './assets/images/logo-dark.png';
import LogoLight from './assets/images/logo-dark.png';
import CountUp from 'react-countup';
import NewNotifications from './modules/notifications/NewNotifications';

export const AppTopbar = (props) => {
    
    const history = useHistory();

    const [visibleLeft, setVisibleLeft] = useState(false);
    const [visibleNotifications, setVisibleNotifications] = useState(false);

    const viewData = async(data) => {
        setVisibleLeft(true);
    }

    const closeViewData = () => {
        setVisibleLeft(false);
    }

    const viewNotifications = async() => {
        setVisibleNotifications(true);
    }

    const closeViewNotifications = () => {
        setVisibleNotifications(false);
    }

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={ props.layoutColorMode === 'light' ? LogoDark : LogoLight } alt="logo"/>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                
                <li>
                    <button className="p-link layout-topbar-button" onClick={ () => viewNotifications() }>
                        <React.Fragment>
                            <i className="pi pi-bell mr-4 p-text-secondary p-overlay-badge">
                                <div className='p-badge p-component p-badge-no-gutter'> 
                                    <CountUp style={{ display:'block', fontSize: '0.8rem' }} end={ props.counter } duration={1.5} />
                                </div>
                            </i>
                        </React.Fragment>
                        <span>Notifications</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={ () => { viewData() }} >
                        <i className="pi pi-user"/>
                        <span>Profile</span>
                    </button>
                </li>
            </ul>

            <LeftSidebar
                onVisible={ visibleLeft }
                onHide={ closeViewData }
                onWidth={ 550 }
            >
                <Profile />
            </LeftSidebar>

            <NewNotifications
                visible={ visibleNotifications }
                hide={ closeViewNotifications }
            />
        </div>
    );
}
