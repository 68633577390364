import React, { useState, useEffect } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from 'primereact/accordion';
import LeftSidebar from "../../components/LeftSidebar";
import DatePicker from "../../components/DatePicker";
import { Dropdown } from "primereact/dropdown";
import { Avatar } from 'primereact/avatar';
import { useAuth } from "../../hooks/useAuth";
import AddResources from "../../components/AddResources";
import ButtonsActions from "../../components/ButtonsActions";
import { Can } from "../../components/PermissionGate";
import ResourcesUpload from "../../components/ResourcesUpload";
import ReactPlayer from "react-player";
import { Image } from 'primereact/image';
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { downloadFiles } from "../../core/utils";
import { RequestItService } from '../../service/RequestItService';

export default function EditAdd({ title, items, onItems, addItemList, updateItemList, visible, hide, setLoading, onAlert, isUser }) {
    
    const dataService = new RequestItService();
    
    const { 0:auth } = useAuth();

    const [loadingButton, setLoadingButton] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [formResources, setFormResources] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [dataFile, setDataFile] = useState([]);
    const [deleteDataDialogFile, setDeleteDataDialogFile] = useState(false);
    const [__text, setTextModal] = useState('Name');
    const [selDesigner, setSelDesigner] = useState("");
    

    const [dataFields, setDatafields] = useState({ 
        fields: {
            titles: "",
            languages: "",
            description: "",
            date: ""
        }
    }); 

    useEffect(() => {
   
        if (title === "Edit") {

            let language;
            if ( items._LANGUAGES !== undefined && items._LANGUAGES !== null) {
                const languages = items._LANGUAGES;
                language = languages.split(',');
            }

            setDatafields({
                fields: {
                    titles: items._TITLE,
                    languages: language,
                    description: items._DESCRIPTION,
                    date: items._DATEDELIVERY || '',
                }
            });

            setFormResources(items._RESOURCES);
            setSelDesigner(items._IDASSIGNED);

            if ( items._FILES !== null && items._FILES !== undefined ) {
                setCurrentFiles(items._FILES);
            }
            
        } else {

            setDatafields({
                fields: {
                    titles: "",
                    languages: "",
                    description: "",
                    date: ""
                }
            });

            setFormResources([]);
            setCurrentFiles([]);
            setSelDesigner("");
        }

        

    }, [items]);
    
    const __it =  isUser.map( (d, i) => {
        const data = [];
        
        return data[i] = {
            name: d._NAME + ' ' + d._LASTNAME, value: d._ID, img: d._AVATAR
        };

    }) 

    const designerOptionTemplate = (option) => {
        return (
            <div className="flex justify-content-start align-items-center">
                <Avatar image={option.img} shape="circle" />
                <div className="ml-2">{option.name}</div> 
            </div>
        );
    }

    const selectedDesignerTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex justify-content-start align-items-center">
                    <Avatar image={option.img} shape="circle" />
                    <div className="ml-2">{option.name}</div> 
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const handleValues = (e) => {
        setSelDesigner(e.value);
    }

    let today = new Date();
    let minDate = new Date();
    minDate.getDate(today);

    const clearData = () => {
        setDatafields({
            fields: {
                titles: "",
                languages: "",
                description: "",
                date: ""
            }
        });
        
        setFormResources([
            {
                _id: "",
                type: "",
                resource: ""
            }
        ]);
        setSelectedFiles([]);
    }

    const convertDate = (e) => {
        const date   = e.value;
        const year   = date.getFullYear();
        const month  = String(date.getMonth() + 1).padStart(2, '0');
        const day    = String(date.getDate()).padStart(2, '0');
        const joined = [day, month, year].join('-');

        setDatafields({ fields: { ...dataFields.fields, [e.target.name]: joined } });
    }

    const sendData = async (__sent) => {

        setLoading(true);
        setLoadingButton(true);

        let route;

        if (title === "Edit") {
            route = dataService.updateData;
            setTextModal('Updating');
        } else {
            route = dataService.saveData;
            setTextModal('Creating');
        }

        try {
            const formData = new FormData();

            formData.append( '_ID', (items._ID !== undefined) ? items._ID : 0 );
            formData.append( '_TITLE', dataFields.fields.titles );
            formData.append( '_LANGUAGES', dataFields.fields.languages );
            formData.append( '_DESCRIPTION', dataFields.fields.description );
            formData.append( '_DATEDELIVERY', dataFields.fields.date );
            formData.append( '_RESOURCES', JSON.stringify(formResources) );
            formData.append( '_REGISTER', auth._ID ); 
            formData.append( '_ASSIGNED', selDesigner ); 

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("_FILES[]", selectedFiles[i]);
            } 

            await route(formData).then( data => {
                setLoading(false);
                setLoadingButton(false);

                if ( data._SUCCESS ) {
                    hide();
                    clearData();

                    if ( data._OPERATION === "add" ) {
                        addItemList(data._ITEMS);
                    } else {
                        onItems(data._ITEMS);
                        updateItemList(data._ITEMS);
                    }

                    onAlert("success", data._MESSAGE);

                } else {
                    onAlert("warn", data._MESSAGE);
                }
            }).finally( () => {
                setLoadingButton(false);
            });

        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }

    }

    const deleteResources = async(resource) => {
        
        try {
            const data = {
                _ID: items._ID,
                _IDFILE: resource._id,
                _TYPE: resource.type,
                _RESOURCE: resource.resource
            }

            await dataService.deleteResource(data).then( response => {
                if ( response._MESSAGE ) {
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('success', response._MESSAGE);
                }
            });

        } catch (error) {
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    const RequestLanguages = [
        {label: 'ENGLISH', value: 'ENGLISH'},
        {label: 'SPANISH', value: 'SPANISH'}
    ];

    const deleteFiles = async() => {
  
        setLoadingButton(true); 

        const data = {
            _ID: items._ID,
            _FILE : dataFile.filename,
            _IDFILE: dataFile.pathId,
            _URLFILE: dataFile.pathUrl
        }

        try {
            await dataService.deleteFile(data).then( response => {
 
                setLoadingButton(false); 

                if ( response._MESSAGE ) {
                    hideDeleteDataDialog();
                    setDataFile([]);
                    onItems(response._ITEMS);
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('warn', response._MESSAGE);
                }
            });
        } catch (error) {
 
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }  

    const confirmDeleteDataFile = (e) => {
        setDataFile(e);
        setDeleteDataDialogFile(true);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialogFile(false);
    }

    const deleteDataDialogFooterFile = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ () => deleteFiles() } />
        </>
    );



    return (
        <div>
            <LeftSidebar
                onVisible={ visible }
                onHide={ () => hide() }
                onWidth={ 600 }
            >
                <div className="p-fluid formgrid grid">
                    <div className="field col-7">
                        <h2 className="text-color">Request It {title}</h2>
                    </div>
                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-6">
                        <label htmlFor="name" className="font-semibold">Title</label>
                        <InputText 
                            value={ dataFields.fields.titles } 
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, titles: e.target.value } }) } } 
                        />
                    </div>

                    <div className="field col-6">
                        <label htmlFor="name" className="font-semibold">It Team</label>

                        <Dropdown 
                            value={selDesigner || ""}
                            options={__it} 
                            optionLabel="name" 
                            filter 
                            filterBy="name" 
                            placeholder="Select a Member"
                            onChange={ (e) => handleValues(e) }
                            itemTemplate={ designerOptionTemplate }
                            valueTemplate={ selectedDesignerTemplate }
                        />
                    </div>
                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-6" >
                        <label htmlFor="target" className="font-semibold">Languages</label>
                        <MultiSelect 
                            value={ dataFields.fields.languages } 
                            options={ RequestLanguages } 
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, languages: e.value } }) } } 
                            optionLabel="label" 
                            placeholder="Choose..." 
                            display="chip"
                        />
                    </div>

                    <div className="field col-6">
                        <label htmlFor="concept" className="font-semibold">Delivery date</label>
                        <DatePicker 
                            onId="date"
                            onName="date"
                            onValue={ dataFields.fields.date ? dataFields.fields.date : '' }
                            onChanges={ (e) => { convertDate(e) }}
                        />
                    </div>

                </div>

                <div className="p-fluid formgrid grid">

                    <div className="field col-12">
                        <label htmlFor="objective" className="font-semibold">Description</label>
                        <InputTextarea 
                            rows={3} 
                            cols={3}  
                            value={ dataFields.fields.description }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, description: e.target.value } }) }} />
                    </div>

                </div>    

                <Accordion multiple activeIndex={ activeIndex } onTabChange={ (e) => setActiveIndex(e.index)} className="mt-4">
                    <AccordionTab header="Online Resources">
                        <AddResources 
                            formResources={ formResources }
                            setFormResources={ setFormResources }
                            onDeleteresource={ (values) => deleteResources(values) }
                        />
                    </AccordionTab>

                    <AccordionTab header="Files">
                        {
                            (currentFiles.length > 0) ?
                                (currentFiles).map( (f, i) => {
                                    let fileExt = f.filename;
                                    const [ext, ...fileName] = fileExt.split('.').reverse();
                                    return (
                                        <div className="p-fluid formgrid grid p-3" key={i}>
                                            <div className="flex align-items-center" >
                                                {
                                                    ( ext === 'mp4' || ext === 'jpg' || ext === 'png' ) ?
                                                        ( ext === 'mp4' ?
                                                            <ReactPlayer 
                                                                url={ f.pathUrl } 
                                                                width={100} 
                                                                height={60} 
                                                                style={{ borderRadius: '10px' }} 
                                                                className="test" /> : 
                                                            <Image 
                                                                src={ f.pathUrl } 
                                                                alt={ fileExt } 
                                                                preview 
                                                                width={100} 
                                                                height={60} 
                                                                imageStyle={{ borderRadius: '10px' }} /> ) : 
                                                                
                                                            ( ext === 'pdf' ? 
                                                                <div className='cursor-pointer' onClick={ () => downloadFiles(f.pathUrl, f.filename) }>
                                                                    <i className="pi pi-file-pdf" style={{'fontSize': '3em'}}></i></div> : 
                                                                <div className='cursor-pointer' onClick={ () => downloadFiles(f.pathUrl, f.filename) }>
                                                                    <i className="pi pi-file-word" style={{'fontSize': '3em'}}></i></div> )
                                           
                                                }
                                                <span className="flex flex-column text-left ml-3">
                                                    { f.filename }
                                                    <small>{ new Date().toLocaleDateString() }</small>
                                                </span>
                                            </div>
                                            
                                            <Button 
                                                type="button" 
                                                icon="pi pi-times" 
                                                className="p-button-outlined p-button-rounded p-button-danger ml-auto"  
                                                onClick={ () => confirmDeleteDataFile(f) }
                                            />
                                        </div>
                                    )
                                })
                                : null
                        } 
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12">
                                <ResourcesUpload 
                                    selectedFiles={ selectedFiles }
                                    setSelectedFiles={ setSelectedFiles }
                                    itemsFile={ items._FILES }
                                    inAlert = { (success, message) => onAlert(success, message) }
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>

                <div className="grid mt-8 flex justify-content-end">
                    {
                        Can('requests.create') ? 
                            <ButtonsActions
                                cancel={ () => hide() }
                                submit={ () => sendData() }
                                disabled={ loadingButton }
                                loading={ loadingButton }
                                isResent={ false }
                                setSent={ (s) => sendData(s) }
                            /> : null
                    }

                </div>

            </LeftSidebar>

            <Dialog visible={deleteDataDialogFile} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooterFile} onHide={hideDeleteDataDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    { dataFile && <span>Are you sure you want to delete <b>{ dataFile.filename }</b>?</span> }
                </div>
            </Dialog>

            <Dialog visible={loadingButton} style={{ width: '450px' }} showHeader={false} modal onHide={loadingButton}>
                <div className="text-center">
                    <div className="grid">
                        <div className="col-12">
                            {
                                __text &&
                                <p className="text-2xl p-4"> 
                                    <b>{__text} request </b>, please wait...
                                </p>
                            }
                        </div>
                        <div className="col-12">
                            <ProgressBar mode="indeterminate" className='w-full text-color h-1rem' ></ProgressBar>
                        </div>
                    </div>
                </div>
            </Dialog>

            

        </div>
    )
}
