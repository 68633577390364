import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { toastAlert } from '../../core/utils';
import { useLocation } from 'react-router-dom';
import { useNotifications } from '../../hooks/useNotifications';
import { NotificationService } from '../../service/NotificationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NotifyList() {
    const dataService = new NotificationService();

    const location = useLocation();
    const { 0:notifications, 1:setNotifications, 3:setCounter } = useNotifications();

    const [dataList, setDataList] = useState([]);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [dataId, setDataId] = useState(null);
    const [markAll, setMarkAll] = useState(false);
    const [deleteAll, setDeleteAll] = useState(false);

    useEffect( () => {
        setDataList(notifications);

        setTimeout( () => {
            setLoading(false);
        }, 1500);

    }, [notifications]);

    const rowClass = (data) => {
        return {
            'surface-200': data._READ === null
        }
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Message</span>
                { rowData._MESSAGE }
            </>
        );
    }

    const startDatetBodyTemplate = (rowData) => {

        const date = (
            <div style={{ color: '#D9187D' }}>{ rowData._DATE }</div>
        )

        return (
            <>
                <span className="p-column-title">Start date</span>
                { date }
            </>
        );
    }

    const iconCheck = (data) => {
        if ( data._READ === null ) {
            return <FontAwesomeIcon icon="check" className='text-gray-600' /> ;
        } else {
            return <FontAwesomeIcon icon="check-double"  className='text-green-700' />;
        }
    } 

    const actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
        
                <Button
                    icon={ iconCheck(rowData) }
                    className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                    tooltip='Mark as read'
                    tooltipOptions={{ position: 'top' }} 
                    onClick={ () => readNotification(rowData) }    
                    disabled={ rowData._READ !== null }
                />

                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                    tooltip='Delete'
                    tooltipOptions={{ position: 'top' }}
                    onClick={ () => setOpenDialogDelete(rowData._ID) }
                />
            </div>
        );
    }

    const setOpenDialogDelete = (row) => {
        setDataId(row);
        setDeleteDataDialog(true);
    }

    const setOpenDialogMarkAll = () => {
        setMarkAll(true);
    }

    const setOpenDialogDeleteAll = () => {
        setDeleteAll(true);
    }

    const readNotification = async(row) => {
        setLoading(true);
        try {
            const data = {
                '_ID': row._ID,
            }
            await dataService.setMarkAsRead(data).then( data => {
                setLoading(false);
                if (data._SUCCESS) {
                    setNotifications(data._ITEMS);
                    let filtered = data._ITEMS.filter( (item) => item._READ === null );
                    setCounter(filtered.length);

                    toastAlert('success', data._MESSAGE, toast);
                } else {
                    toastAlert('warn', data._MESSAGE, toast);
                }
                
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data);
        }
    }
        
    const deleteUnitNotification = async () => {
        setLoading(true);
        setLoadingButton(true);
        try {
            const data = {
                '_ID': dataId,
            }

            await dataService.deleteUnitNotification(data).then( data => {
                setLoading(false);
                setLoadingButton(false);
                if (data._SUCCESS) {
                    setNotifications(data._ITEMS);
                    let filtered = data._ITEMS.filter( (item) => item._READ === null );
                    setCounter(filtered.length);
                    setDeleteDataDialog(false);

                    toastAlert('success', data._MESSAGE, toast);
                } else {
                    toastAlert('warn', data._MESSAGE, toast);
                }
                
            });
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data);
        }
	}

    const deleteAllsNotifications = async() => {
        setLoading(true);
        setLoadingButton(true);
        try {

            await dataService.deleteAllNotifications().then( data => {
                setLoading(false);
                setLoadingButton(false);
                if (data._SUCCESS) {
                    setNotifications(data._ITEMS);
                    let filtered = data._ITEMS.filter( (item) => item._READ === null );
                    setCounter(filtered.length);
                    setDeleteAll(false);

                    toastAlert('success', data._MESSAGE, toast);
                } else {
                    toastAlert('warn', data._MESSAGE, toast);
                }
                
            });
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data);
        }
    }

    const markAllsNotifications = async() => {
        setLoading(true);
        setLoadingButton(true);
        try {

            await dataService.setMarkAllNotifications().then( data => {
                setLoading(false);
                setLoadingButton(false);
                if (data._SUCCESS) {
                    setNotifications(data._ITEMS);
                    let filtered = data._ITEMS.filter( (item) => item._READ === null );
                    setCounter(filtered.length);
                    setMarkAll(false);

                    toastAlert('success', data._MESSAGE, toast);
                } else {
                    toastAlert('warn', data._MESSAGE, toast);
                }
                
            });
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data);
        }
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const hideDeleteDataDialogMark = () => {
        setMarkAll(false);
    }

    const hideDeleteAllDataDialog = () => {
        setDeleteAll(false);
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Notifications</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" /> 
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteUnitNotification } />
        </>
    );

    const markAllDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialogMark } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ markAllsNotifications } />
        </>
    );

    const deleteAllDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteAllDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteAllsNotifications } />
        </>
    );

    const rightToolbarTemplate = () => {

        return (
            <React.Fragment>
                <Button 
                    label="Read all" 
                    icon="pi pi-check" 
                    className="p-button-success mr-2" 
                    onClick={ () => setOpenDialogMarkAll() }
                />

                <Button 
                    label="Delete all" 
                    icon="pi pi-trash" 
                    className="p-button-danger mr-2" 
                    onClick={ () => setOpenDialogDeleteAll() }
                />
            </React.Fragment>
        )
    }
 
    return (
        <div className="crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={ rightToolbarTemplate }></Toolbar>
                    <DataTable ref={dt} value={ dataList } stripedRows rowHover  rowClassName={rowClass}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} notifications"
                        globalFilter={globalFilter} emptyMessage="No notifications found." header={header} responsiveLayout="scroll" loading={loading}>
                        {/*<Column field="_id" header="#" body={ idBodyTemplate } style={{ width: '3%' }} ></Column>*/}
                        <Column field="name" header="Message" body={ nameBodyTemplate }></Column>
                        <Column field="start" header="Date" body={ startDatetBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '20%' }}></Column>
                    </DataTable>

                </div>

                <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                    <div className="flex align-items-center ">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Do you want to delete this notification?</span>
                    </div>
                </Dialog>

                <Dialog visible={markAll} style={{ width: '450px' }} header="Mark all notifications!" modal footer={markAllDialogFooter} onHide={hideDeleteDataDialogMark}>
                    <div className="flex align-items-center ">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Do you want to mark all notifications as read?</span>
                    </div>
                </Dialog>

                <Dialog visible={deleteAll} style={{ width: '450px' }} header="Delete all notifications!" modal footer={deleteAllDialogFooter} onHide={hideDeleteAllDataDialog}>
                    <div className="flex align-items-center ">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Do you want to clear all notifications?</span>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default NotifyList;