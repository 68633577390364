import Connection from "../core/ApiConnection";

export class CampaignsService {
    async getList () {
        return await Connection.get('/campaigns').then( res => res.data.items );
    }

    async selected(data) {
        return await Connection.post('/campaigns/selected', data).then( res => res.data );
    }

    async saveData(data, {onUploadProgress}) {
        return await Connection.post('/campaigns', data, {onUploadProgress}).then( res => res.data );
    }

    async updateData(data, {onUploadProgress}) {
        return await Connection.post('/campaigns/update', data, {onUploadProgress}).then( res => res.data );
    }

    async deleteData(data, {onUploadProgress}) {
        return await Connection.delete('/campaigns/' + data, {onUploadProgress}).then( res => res.data );
    }

    async deleteResource(data) {
        return await Connection.post('/campaigns/delete-resource', data).then( res => res.data );
    }

    async deleteFile(data) {
        return await Connection.post('/campaigns/delete-file', data).then( res => res.data );
    }

    async getStatusChange(data) {
        return await Connection.post('/campaigns/change-status', data).then( res => res.data );
    }

    async getRequestCampaign() {
        return await Connection.post('/campaigns/campaigns').then( res => res.data );
    }
}