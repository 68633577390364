import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import ButtonsActions from './ButtonsActions';

function LeftSidebar({ children, onVisible, onHide, onWidth }) {

    return (
        <div>

            <Sidebar className='fadeinleft animation-duration-500 shadow-3' 
                visible={ onVisible } 
                onHide={ onHide } 
                baseZIndex={1000} 
                style={{ width: (onWidth) ? onWidth : null }}>
                { children }
            </Sidebar>
        </div>
    )
}

export default LeftSidebar;