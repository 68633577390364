import React, { useState, useEffect, useRef, memo } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import EditAdd from './EditAdd';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { AccountService } from '../../service/AccountService';
import { toastAlert } from '../../core/utils';

function AccountList() {

    const dataService = new AccountService();

    const [dataList, setDataList] = useState(null);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState("Add");
    const [filters2, setFilters2] = useState(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    useEffect(() => {
        getData();
        initFilters1();
    }, []);

    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'urgent': { value: null, matchMode: FilterMatchMode.IN },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'types': { value: null, matchMode: FilterMatchMode.IN },
        });
        setGlobalFilterValue2('');
    }

    const getData = () => {
        setLoading(true);
        dataService.getList().then(data => { setLoading(false); setDataList(data); });
    }

    const openNew = () => {
        setModalTitle("Add");
        setItem([]);
        setShowAdd(true);
    }

    const hideNew = () => {
        setShowAdd(false);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const confirmDeleteData = (data) => {

        setDataId(data._ID);
        setDataGlobal(data);
        setDeleteDataDialog(true); 
    }

    const addItemList = (row) => {
        let data = [...dataList];
        data.splice(0, 0, row);
        setDataList(data);
    }

    const updateItemList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index] = {
            _ID: row._ID,
            _NAME: row._NAME,
        };
        
        setDataList(data);
        
    }

    const removeItemList = (row) => {

        let filtered = dataList.filter( function(value, index, arr) { 
            return value._NAME !== row._NAME;
        });
        setDataList(filtered);
    }

    const deleteDataSelected = async() => {
        try {
            setLoading(true);
            await dataService.deleteData(dataId).then(data => {
                setLoading(false);
                if (data._SUCCESS) {
                    removeItemList(dataGlobal);
                    setDeleteDataDialog(false);
                    toastAlert("success", data._MESSAGE, toast);
                }
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setShowAdd(true);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={ openNew } />
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                { rowData._NAME }
            </>
        );
    }


    const statusBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Status</span>
                {
                    (rowData.account_active === 1) ?
                    <span className="p-tag p-component p-tag-success mr-1">
                        <span className="p-tag-icon pi pi-check"></span>
                        <span className="p-tag-value">Active</span>
                        <span></span>
                    </span> :
                    <span className="p-tag p-component p-tag-warning mr-1">
                        <span className="p-tag-icon pi pi-times"></span>
                        <span className="p-tag-value">Inactive</span>
                        <span></span>
                    </span>
                }
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <Button
                    tooltip='Edit'
                    tooltipOptions={{ position: 'top' }}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                    onClick={ () => editData(rowData) }
                />
                <Button
                    tooltip='Delete'
                    tooltipOptions={{ position: 'top' }}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                    onClick={ () => confirmDeleteData(rowData) }
                />
            </div>
        );
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Accounts Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} loading={loading} value={dataList} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} accounts"
                        emptyMessage="No accounts found." header={header} responsiveLayout="scroll"
                        filters={filters2} 
                        filterDisplay="menu" 
                        globalFilterFields={['_NAME']}
                    >
                        {/*<Column field="_id" header="ID" body={ idBodyTemplate }></Column>*/}
                        <Column field="social_name" header="Name" body={ nameBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '20%' }}></Column>
                    </DataTable>

                    <EditAdd
                        visible={ showAdd }
                        hide={ hideNew }
                        title={ modalTitle }
                        items={ item }
                        setItems={ setItem }
                        addItemList={ addItemList }
                        updateItemList={ updateItemList }
                        setLoading={ setLoading }
                    />

                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Are you sure you want to delete <b>{dataGlobal._NAME}</b>?</span> }
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(AccountList, comparisonFn);
