import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RequestService } from '../service/RequestService';
import { InputTextarea } from 'primereact/inputtextarea';

function ButtonsApprovals({ id, onAlert, isResent, dataList, setDataList, closeViewData }) {
    const dataService = new RequestService();

    const [changeStatus, setChangeStatus] = useState(null);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [observation, setObservation] = useState(null);
    const [isReview, setIsReview] = useState(false);

    const handleChangeStatus = (e) => {
        setChangeStatus(e);
        setVisibleDialog(true);
    }

    const isHiden = () => {
        setVisibleDialog(false);
    } 

    const dataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ isHiden } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ () => sendChangeStatus() } />
        </>
    );

    const sendChangeStatus = async () => {
        try {
            setLoadingButton(true);

            const data = {
                _ID: id,
                _STATUS: changeStatus,
                _OBSERVATION: observation,
            }
            
            await dataService.getStatusChange(data).then( response => {
                if ( response._SUCCESS === true ) {
                    updateStatusList(response._ID, JSON.stringify(response._STATUS) );
                    closeViewData();
                    onAlert( 'success', response._MESSAGE );
                } else {
                    onAlert( 'warn', response._MESSAGE );
                }
            });

        } catch (error) {
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    const updateStatusList = (id, status) => {
        let data = [...dataList];

        data.filter( st => st._ID === id ).map( (s, i) => {
            s._STATUS.request_status.push( JSON.parse(status) );
        });

        setDataList(data);
    }

    return (
        <div>
            
            <div className='absolute z-2 bottom-0 right-0 w-full surface-200 border-top-1 border-400'>
                <div className='flex justify-content-end p-3'>
                    <Button label="Disapprove" className="p-button-sm mr-2 p-button-cancel" onClick={ () => handleChangeStatus('NOT APPROVED') }  />
                    <Button label="Approve" className='p-button-sm' onClick={ () => handleChangeStatus('APPROVED') } />
                </div>
            </div>

            { isResent && 
                <div className="field col-12">
                    <Button label="SAVED & SENT" id='sfr' onClick={ (e) => { 
                            handleChangeStatus('SENT FOR REVIEW');
                            setIsReview(true);
                        }} 
                    />
                </div>
            }
            
            <Dialog visible={ visibleDialog } style={{ width: '450px' }} header={ changeStatus + ` Design Request!` } modal footer={ dataDialogFooter } onHide={ isHiden }>
                <div className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    { changeStatus && <span>Do you want to <b>{ changeStatus }</b> this design request?</span> }
                </div>
                
                <div className="p-fluid formgrid grid mt-5">
                    <div className="field col-12">
                        <label htmlFor="observation" className="font-semibold">Observation</label>
                        <InputTextarea 
                            rows={1} 
                            cols={2}  
                            value={ observation || ''}
                            onChange={ (e) => setObservation(e.target.value) }
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default ButtonsApprovals;