import React, { useState, useRef } from 'react';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ImageUpload from '../../components/ImageUpload';
import { toastAlert } from '../../core/utils';
import ChangePassword from '../auth/ChangePassword';
import { UserService } from '../../service/UserService';

function ViewData({ items, onEdit, onDelete, dataList, setDataList }) {

    const dataService = new UserService();

    const [isShown, setIsShown] = useState(false);
    const [showAvatarDialog, setShowAvatarDialog] = useState(false);
    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [isAvatar, setIsAvatar] = useState(items._AVATAR);
    const [loadingButton, setLoadingButton] = useState(false);
    const [fieldPassword, setFieldPassword] = useState("");
    const toast = useRef(null);

    const openModalEdit = () => {

        let values = {
            _ID       : items._ID,
            _NAME     : items._NAME,
            _LASTNAME : items._LASTNAME,
            _EMAIL    : items._EMAIL,
            _PHONE    : items._PHONE,
            _AVATAR   : isAvatar,
            _ROLID    : items._ROLID,
            _POSITION : items._POSITION
        };

        onEdit(values);
    }

    const showModal = () => {
        setShowAvatarDialog(true);
    }

    const hideModal = () => {
        setShowAvatarDialog(false);
    }

    const showModalPassword = () => {
        setShowPasswordDialog(true);
    }

    const hideModalPassword = () => {
        setShowPasswordDialog(false);
    }

    const updateAvatarList = (avatar) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(items._ID);
        data[index]._AVATAR = avatar;
        
        setDataList(data);
        
    }

    const OpenAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const sendData = async () => {
        setLoadingButton(true);
        try {

            const data = {
                _ID       : items._ID,
                _PASSWORD : fieldPassword,
            };

            await dataService.changePassword(data).then( data => {
               hideModalPassword();
               setLoadingButton(false);

                if ( data._SUCCESS ) {
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }

            });

        } catch (error) {
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }

    }

    const modalDialogPasswordFooter = (
        <>
            <Button label="Cancel" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ () => { hideModalPassword(); }} />
            <Button label="Save" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ () => { sendData(); }}  />
        </>
    );

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "10px" }}>
           <Toast ref={toast} />
            <div className='grid'>
            
                <div className='col-12 flex justify-content-center'>
                    <div 
                        style={{ position: "relative", width: "40%" }}
                        onMouseEnter={ () => setIsShown(true) } 
                        onMouseLeave={ () => setIsShown(false) }
                    >

                        <Avatar image={ isAvatar } className="mr-2" size="xlarge" shape="circle" style={{ width: "150px", height: "150px" }} >
                            { isShown &&
                                <div className='overlay' onClick={showModal}>
                                    <div style={{ color: '#FFF', textAlign: 'center' }}>
                                        <i className="pi pi-camera"></i>
                                        <p style={{ fontSize: '12px' }}>Change</p>
                                    </div>
                                </div> 
                            }
                        </Avatar>
                    </div>
                </div>

                <div className='col-12 mt-3 text-center title-h'>
                    <h2>{ items._POSITION }</h2>
                </div>

                <div className='div-container'>

                    <div className='grid'>
                        <div className='col-6'>
                            <p className='p-text'>Name</p>
                        </div>

                        <div className='col-6 text-right'>
                            <p className='p-text'>{ items._NAME }</p>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-6'>
                            <p className='p-text'>Lastname</p>
                        </div>

                        <div className='col-6 text-right'>
                            <p className='p-text'>{ items._LASTNAME }</p>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-6'>
                            <p className='p-text'>Email</p>
                        </div>

                        <div className='col-6 text-right'>
                            <p className='p-text'>{ items._EMAIL }</p>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-6'>
                            <p className='p-text'>Phone</p>
                        </div>

                        <div className='col-6 text-right'>
                            <p className='p-text'>{ items._PHONE }</p>
                        </div>
                    </div>

                    <Divider />

                    <div className='container-button'>

                        <div className='p-1'>
                            <Button 
                                icon="pi pi-pencil" 
                                tooltip='Edit' 
                                tooltipOptions={{ position: 'bottom' }} 
                                onClick={ () => openModalEdit(items) } />
                        </div>

                        <div className='p-1'>
                            <Button 
                                icon="pi pi-key" 
                                className='p-button-info' 
                                tooltip='Change password' 
                                tooltipOptions={{ position: 'bottom' }} 
                                onClick={ () => showModalPassword() } />
                        </div>

                        <div className='p-1'>
                            <Button 
                                icon="pi pi-trash" 
                                className="p-button-danger" 
                                tooltip='Delete' 
                                tooltipOptions={{ position: 'bottom' }} 
                                onClick={ () => onDelete(items) } />
                        </div>
                    </div>

                </div> 

            </div>

            <Dialog visible={showAvatarDialog} style={{ width: '500px' }} header="Change avatar" modal  onHide={hideModal}>
                <div className="flex align-items-center justify-content-center">
                    <ImageUpload 
                        id={ items._ID } 
                        setIsAvatar={ setIsAvatar } 
                        updateAvatarList={ updateAvatarList }
                        onHideModal={ hideModal }
                        setAlert={ OpenAlert }
                    />
                </div>
            </Dialog>

            <Dialog 
                visible={showPasswordDialog} 
                style={{ width: '500px' }} 
                header="Change password" 
                modal  
                footer={ modalDialogPasswordFooter }
                onHide={hideModalPassword}>
                <div className="flex align-items-center justify-content-center">
                    <ChangePassword 
                        setFieldPassword={setFieldPassword}
                    />
                </div>
            </Dialog>        

        </div>
    )
}

export default ViewData;