import React  from 'react';
import { Button } from 'primereact/button';

function ButtonsActions({ cancel, 
    submit, 
    disabled = false, 
    loading, text, 
    isView = true, 
    isResent = false, 
    setSent, 
    isPaused, 
    isDesign,
    isNotify = false }) {

    return (
        <div className='absolute z-2 bottom-0 right-0 w-full surface-200 border-top-1 border-400'>
            <div className='flex justify-content-end p-3'>
                <Button 
                    label="Cancel" 
                    disabled={disabled} 
                    className='p-button-sm mr-2 p-button-cancel' onClick={ () => cancel() } />
                {
                    (isView) ?
                        <Button 
                            label={ (text) ? text : 'Save' } 
                            loading={loading} 
                            className='p-button-sm mr-2' 
                            onClick={ () => submit() } 
                            disabled={ isPaused || isDesign || isNotify }
                        /> : <></>
                }
                
                {
                    (isResent) ? 
                        <Button 
                            label="Sent for review" 
                            loading={loading} 
                            className='p-button-success p-button-sm' 
                            onClick={ () => setSent(true) } 
                        /> : <></>
                }

                {
                    (isPaused) ?
                        <Button 
                            label="Restart Campaign" 
                            loading={loading} 
                            className='p-button-success p-button-sm' 
                            onClick={ () => setSent(true) } 
                        />  :<></>
                }
            


            </div>
        </div>
    )
}

export default ButtonsActions;