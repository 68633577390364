import { Image } from 'primereact/image';
import React, { memo } from 'react';
import LogoDark from '../../assets/images/logo-dark.png';
import Unauthorized from '../../assets/images/unauthorized.jpg'

function Page401() {
    return (
        <div className='grid text-center'>
            <div className='col-12'>
                <Image src={Unauthorized} imageClassName="w-full p-5" />
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo( Page401, comparisonFn );