import React from 'react';
import { Sidebar } from 'primereact/sidebar';

function RightSidebar({ children, onVisible, onHide, onWidth }) {
    return (
        <Sidebar className='fadeinright animation-duration-500 shadow-3' position='right' visible={ onVisible } onHide={ onHide } baseZIndex={1000} style={{ width: (onWidth) ? onWidth : null }}>
            { children }
        </Sidebar>
    )
}

export default RightSidebar;