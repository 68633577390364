import { saveAs } from "file-saver";

export function toastAlert (variant, message, ref) {
    let type = "";
    switch (variant) {
        case 'success':
            type = "Success!";
            break;
        case 'info':
            type = "Info!";
            break;
        case 'warn':
            type = "Warning!";
            break;
        case 'error':
            type = "Error!";
            break;
    
        default:
            break;
    }
    
    ref.current.show({ severity: variant, summary: type, detail: message, life: 3000 });
}

export function createId () {
    let id = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 15; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createAction(type, payload) {
    return {
        type,
        payload,
    };
}

export function convertTimestamp(f) {
    let date = new Date(f * 1000);
    const year   = date.getFullYear();
    const month  = String(date.getMonth() + 1).padStart(2, '0');
    const day    = String(date.getDate()).padStart(2, '0');

    let timestand = day + '-' + 
    month + '-' + 
    year + '-' + " "+ 
    date.getHours() + ":" + 
    date.getMinutes() + ":" + 
    date.getSeconds();

    return timestand;
}

export function dateFormat(inputDate, format) {
    const date = new Date(inputDate + 'T00:00:00');

    const day = (date.getDate());
    const month = (date.getMonth() + 1);
    const year = date.getFullYear();

    format = format.replace("MM", month.toString().padStart(2,"0"));        

    format = format.replace("dd", day.toString().padStart(2,"0"));

    if ( format.indexOf("yyyy") > -1 ) {
        format = format.replace("yyyy", year.toString());
    } else if ( format.indexOf("yy") > -1 ) {
        format = format.replace("yy", year.toString().substr(2,2));
    }

    return format;
}

export const iconStatus = (st) => {
    let icon; 
    switch (st) {
        case 'SAVED':
            icon = 'pi pi-save';
            break;

        case 'IN PROCESS':
            icon = 'pi pi-cog';
            break;
        
        case 'IN PROGRESS':
            icon = 'pi pi-wifi';
            break;
        
        case 'FINISHED':
            icon = 'pi pi-lock';
            break;
        
        case 'CANCELED':
            icon = 'pi pi-times';
            break;

        case 'ARCHIVED':
            icon = 'pi pi-box';
            break;
        
        case 'PAUSED':
            icon = 'pi pi-pause';
            break;

        case 'SENT FOR REVIEW':
            icon = 'pi pi-upload';
            break;

        case 'APPROVED':
            icon = 'pi pi-check-circle';
            break;

        case 'NOT APPROVED':
            icon = 'pi pi-times-circle';
            break;
        
        default:
            break;
    }

    return icon;
}

export const colorStatus = (st) => {
    let color; 
    switch (st) {
        case 'SAVED':
            color = '#fec810';
            break;

        case 'IN PROCESS':
            color = '#1ca345';
            break;
        
        case 'IN PROGRESS':
            color = '#d9187d';
            break;
        
        case 'FINISHED':
            color = '#006699';
            break;
        
        case 'CANCELED':
            color = '#de3f44';
            break;

        case 'ARCHIVED':
            color = '#595a5d';
            break;
        
        case 'PAUSED':
            color = '#cccccc';
            break;

        case 'SENT FOR REVIEW':
            color = '#1C80CF';
            break;

        case 'APPROVED':
            color = '#D06900';
            break;

        case 'NOT APPROVED':
            color = '#FF0000';
            break;
            
        default:
            break;
    }

    return color;
}

export const stringToFirstUppercase = (str) => {
    if (str) {    
        let string = str.charAt(0).toUpperCase() + str.slice(1);
        return string;
    }
}

export const stringToLowerCase = (str) => {
    if (str) {
        let string = str.toLowerCase();
        return string;
    }
}


export const iconsTemplate = (option) => {
    let icon;
    switch (option) {
        case "FACEBOOK":
            icon = "pi-facebook";
            break;
        case "INSTAGRAM":
            icon = "pi-instagram";
            break;
        case "YOUTUBE":
            icon = "pi-youtube";
            break;
        case "TWITTER":
            icon = "pi-twitter";
            break;
        case "LINKEDIN":
            icon = "pi-linkedin";
            break;
        case "VIMEO":
            icon = "pi-vimeo";
            break;
        case "TIKTOK":
            icon = "fas-tiktok";
            break;
        default:
            icon = "pi-tag";
            break;
    }

    return icon;
}

export const iconsColor =  (option) => {
    let color;
    switch (option) {
        case "FACEBOOK":
            color = "#1877F2";
            break;
        case "INSTAGRAM":
            color = "#F1049B";
            break;
        case "YOUTUBE":
            color = "#ff0000";
            break;
        case "TWITTER":
            color = "#1C93E4";
            break;
        case "LINKEDIN":
            color = "#0A66C2";
            break;
        case "VIMEO":
            color = "#00A4E3";
            break;
        case "TIKTOK":
            color = "#ff0050";
            break;
        default:
            color = "#000";
            break;
    }

    return color;
}

export const backgroundColor =  (option) => {
    let color;
    switch (option) {
        case "FACEBOOK":
            color = "background-fb";
            break;
            case "INSTAGRAM":
                color = "background-ig";
                break;
                case "YOUTUBE":
                    color = "background-yt";
                    break;
                    case "TWITTER":
                        color = "background-tw";
                        break;
                        case "LINKEDIN":
                            color = "background-lk";
                            break;
                            case "VIMEO":
                                color = "background-vm";
                                break;
                                case "TIKTOK":
                                    color = "background-tk";
                                    break;
                                    default:
                                        color = "surface-border";
                                        break;
    }

    return color;
}

export const downloadFiles = (url, name) => {
    saveAs(url, name);
}

export function converFromBytes(bytes, decimalLength) {
    if(bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalLength || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const yearTemplate = () => {
    const d = new Date();
    let year = d.getFullYear();

    return year;
}

export const separateString = (text) => {
    let newString;
    let oldString = text.split(' ', 5);

    if ( oldString.length === 5 ) {
        newString = oldString.join(' ') + '...';
    } else {
        newString = text;
    }
    
    return newString;
}

export const campaignStatuses = [
    'SAVED', 'IN PROCESS', 'IN PROGRESS', 'FINISHED', 'CANCELED', 'PAUSED'
];

export const requestStatuses = [
    'SAVED', 'SENT FOR REVIEW', 'APPROVED', 'NOT APPROVED'
];

export const tab = '\u00A0';
