import React, { useState, useEffect, useRef, memo } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import EditAdd from './EditAdd';
import ReactTooltip from "react-tooltip";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { RolService } from '../../service/RolService';
import LeftSidebar from '../../components/LeftSidebar';
import { toastAlert } from '../../core/utils';
import ViewData from './ViewData';
import { Can } from '../../components/PermissionGate';

function RolsList() {

    const dataService = new RolService(); 

    const [dataList, setDataList] = useState(null);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [modalTitle, setModalTitle] = useState("Add");
    const [loading, setLoading] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [filters2, setFilters2] = useState(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    useEffect(() => {
        getData();
        initFilters1();
    }, []);

    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'urgent': { value: null, matchMode: FilterMatchMode.IN },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'types': { value: null, matchMode: FilterMatchMode.IN },
        });
        setGlobalFilterValue2('');
    }

    const getData = async() => {
        try {
            setLoading(true);
            await dataService.getList().then(data => {
                setDataList(data);
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    }

    const viewData = async(data) => {
        setItem(data);
        setVisibleLeft(true);
    }

    const closeViewData = () => {
        setVisibleLeft(false);
    }

    const addItemList = (row) => {
        let data = [...dataList];
        data.splice(0, 0, row);
        setDataList(data);
    }

    const updateItemList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index] = {
            _ID: row._ID,
            _NAME: row._NAME,
            _PERMISSIONS: row._PERMISSIONS
        };
        
        setDataList(data);
        
    }

    const onAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const openNew = () => {
        setModalTitle("Add");
        setItem([]);
        setShowAdd(true);
    }

    const hideNew = () => {
        setShowAdd(false);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const confirmDeleteData = (data) => {

        setDataId(data._id);
        setDataGlobal(data);
        setDeleteDataDialog(true);
    }

    const deleteDataSelected = async() => {

        await dataService.deleteData(dataId).then(data => {
            if (data.success) {
                getData();
                setDeleteDataDialog(false);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data.message, life: 3000 });
            }
        }).catch( error => {
            toast.current.show({ severity: 'error', summary: 'Error!', detail: error.message, life: 3000 });
        });
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setShowAdd(true);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {
                    Can('roles.update') ?
                        <Button 
                            label="New" 
                            icon="pi pi-plus" 
                            className="p-button-success mr-2" 
                            onClick={ openNew } /> : <></>
                }
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                { rowData._NAME }
            </>
        );
    }



    const actionBodyTemplate = (rowData, props) => {
        return (
            <div className="actions">
                {
                    Can('roles.update') ?
                        <Button
                            icon="pi pi-pencil"
                            tooltip='Edit'
                            tooltipOptions={{ position: 'top' }}
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            onClick={ () => editData(rowData) }
                        /> : <></>
                }

                {
                    Can('roles.delete') ?
                        <Button
                            icon="pi pi-trash"
                            tooltip='Delete'
                            tooltipOptions={{ position: 'top' }}
                            className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                            onClick={ () => confirmDeleteData(rowData) }
                            disabled={ rowData._NAME === "Administrator" }
                        /> : <></>
                }

            </div>
        );
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Rols Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={dataList} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} roles"
                        emptyMessage="No roles found." header={header} responsiveLayout="scroll" loading={loading}
                        filters={filters2} 
                        filterDisplay="menu" 
                        globalFilterFields={['_NAME']}
                    >
                        {/*<Column field="_id" header="ID" body={ idBodyTemplate } ></Column>*/}
                        <Column field="social_name" header="Name" body={ nameBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '30%' }}></Column>
                    </DataTable>

                    <LeftSidebar
                        onVisible={ showAdd }
                        onHide={ hideNew }
                        onWidth={ 530 }
                    >
                        <EditAdd
                            title={ modalTitle }
                            items={ item }
                            addItemList={ addItemList }
                            updateItemList={ updateItemList }
                            hide={ hideNew }
                            onAlert={ onAlert }
                            onItems={ setItem }
                            setLoading={ setLoading }
                        />

                    </LeftSidebar>

                    <LeftSidebar
                        onVisible={ visibleLeft }
                        onHide={ closeViewData }
                        onWidth={ 530 }
                    >
                        <ViewData 
                            items={ item }
                        />

                    </LeftSidebar>

                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Are you sure you want to delete <b>{dataGlobal.rol_name}</b>?</span> }
                        </div>
                    </Dialog>
                    
                </div>
            </div>
            <ReactTooltip id="tooltip" place="top" effect="solid" />
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(RolsList, comparisonFn);
