import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const FieldInput = ({ indexParent, placeholder, name, value, onChange }) => (
    <div>
        <InputText
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={(event) => onChange(indexParent, event)}
        />
    </div>
);

function TraditionalMethods({ selectedFormSocial, setSelectedFormaSocial }) {
    
    
    const [data, setData] = useState([]);

    const onDelete = (indexToDelete) => {
        const newFields = selectedFormSocial.filter((d, index) => index !== indexToDelete);
        setSelectedFormaSocial([...newFields]);
    };
    
    const onAdd = () => {
        const defaultFields = {
            socialMedia: "",
            socialFormat: ""
        };

        setSelectedFormaSocial([...selectedFormSocial, { ...defaultFields }]);
    };
    
    const onChange = (indexParent, event) => {
        const newData = selectedFormSocial.map((d, index) => {
            //console.log(Object.keys(d));
            let addArray;
            if (index === indexParent) {
                if ( event.target.name === "socialFormat" ) {
                    addArray = [event.target.value];
                } else {
                    addArray = event.target.value;
                }
                
                d[event.target.name] = addArray;
            }

            return d;
        });

        setSelectedFormaSocial([...newData]);
    };

     
    
    return (
        <div>
            <div className="p-fluid formgrid grid">
                <div className="field col-8">
                    <h5>Traditional Formats & dimension</h5>
                </div>
                <div className="field col-4">
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <span style={{ background: "#22C55E", borderRadius: '5px', padding: '5px', cursor: 'pointer', color: '#fff' }}  onClick={onAdd}>
                            <i className="pi pi-plus" style={{ fontSize: '.8rem', color: "#fff" }}></i>
                            add
                        </span>
                    </div>
                </div>
            </div>

            <div className="formgrid grid">
                    
                {
                    (selectedFormSocial !== undefined && selectedFormSocial.length > 0 && selectedFormSocial !== null) ?
                        selectedFormSocial.map((d, index) => {
                            return (
                                <React.Fragment key={`field-${index}`}>
                                    <div className="field col-5">
                                        <label htmlFor="name">Format</label>
                                        <FieldInput
                                            indexParent={index}
                                            placeholder="Flyer"
                                            name="socialMedia"
                                            value={d.socialMedia}
                                            onChange={onChange}
                                        />
                                    </div>

                                    <div className="field col-5">
                                        <label htmlFor="name">Dimensions</label>
                                        <FieldInput
                                            indexParent={index}
                                            placeholder="1080 x 1080"
                                            name="socialFormat"
                                            value={d.socialFormat}
                                            onChange={onChange}
                                        />
                                    </div>

                                    <div className="field col-2 md:my-4" style={{ textAlign: 'right' }}>
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-text p-button-danger"
                                            onClick={ (e) => { onDelete(index); }}>
                                        </Button>
                                    </div>
                
                                </React.Fragment>
                            );
                        }
                    ) : <div className="w-full text-center"><h5>Empty</h5></div>
                }
            </div>
        </div>
    );
}

export default TraditionalMethods;