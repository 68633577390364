import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { AccountService } from "../../service/AccountService";
import LeftSidebar from "../../components/LeftSidebar";
import ButtonsActions from "../../components/ButtonsActions";
import { toastAlert } from "../../core/utils";

function EditAdd({ items, visible, hide, title, setLoading, loading, setItems, addItemList, updateItemList }) {

    const dataService = new AccountService(); //Call Service

    const toast = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        setSelectedOption(items._NAME);
    }, [items]);

    const clearData = () => {
        setSelectedOption(null);
    }

    const sendData = async () => {
        setLoading(true);
        try {

            let route;

            if ( title === "Edit" ) {
                route = dataService.updateData;
            } else {
                route = dataService.saveData;
            }

            const data = {
                _ID   : (title === "Edit") ? items._ID : 0,
                _NAME : selectedOption,
            };

            await route(data).then( data => {
                setLoading(false);
                if (data._SUCCESS) {
                    hide();
                    clearData();

                    if ( data._OPERATION === "add" ) {
                        addItemList(data._ITEMS);
                    } else {
                        setItems(data._ITEMS);
                        updateItemList(data._ITEMS);
                    }

                    toastAlert('success', data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }

    }

    const modalDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={ () => { hide(); clearData(); }  } />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={ () => { sendData(); } } />
        </>
    );

    return (
        <div>
            <Toast ref={toast} />
            <LeftSidebar
                onVisible={visible}
                onHide={ () => hide() }
                onWidth={ 300 }
            >
                <h2 className="text-color">Account {title}</h2>
                <div className="field"> 
                    <label htmlFor="account">Account Name</label>
                    <InputText
                        required
                        name='account'
                        placeholder='Let Us Claim'
                        value={ selectedOption || '' }
                        onChange={ event => setSelectedOption(event.target.value) }
                    />
                </div>

                <ButtonsActions 
                    cancel={ () => hide() }
                    submit={ () => sendData() }
                    disabled={loading}
                    loading={loading}

                />
            </LeftSidebar>



            
        </div>
    )
}

export default EditAdd;
