import Connection from "../core/ApiConnection";

export class ModuleService {

    async getList() {
        return await Connection.get('/modules').then(res => res.data.items);
    }

    async saveData(data) {
        return await Connection.post('/modules', data).then(res => res.data);
    }

    async deleteData(data) {
        return await Connection.delete('/modules/' + data).then(res => res.data);
    }


}
