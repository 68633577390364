import React, { useState, useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ButtonsActions from '../../components/ButtonsActions';
import { RequestItService } from '../../service/RequestItService';
import { Dialog } from "primereact/dialog";

export default function RequestUpload ({ hide, items, updateData, onAlert, setLoading, loading }) {

    const dataService = new RequestItService(); 

    const [__urlEnglish, setUrlEnglish] = useState('');
    const [__urlSpanish, setUrlSpanish] = useState('');
    const [_language, setLanguage] = useState([]);

    useEffect( () => {
        countLanguage();
    }, [items._LANGUAGES]);
    
    const countLanguage = () => {
        let ld = items._LANGUAGES;
        let split = ld.split(',');
        setLanguage(split);
    }

    const sendData = async() => {
        setLoading(true);
        try {
            const datas = {
                _ID: items._ID,
                _FILEURLENGLISH: __urlEnglish,
                _FILEURLSPANISH: __urlSpanish,
            }
            
            await dataService.addRequestIt(datas).then( res => {
                if ( res._SUCCESS ) {
                    let resp = res._ITEMS;
                    //let up = resp._UPLOADED;
                    updateData(resp);
                    onAlert("success", res._MESSAGE);
                } else {
                    onAlert("warn", res._MESSAGE);
                }
            }).finally( () => {
                setLoading(false);
                hide();
            });
        } catch (error) {
            onAlert(error.variant, error.data);
        }
    }

    
    return (
        <div>

            {
                (_language[0] === 'ENGLISH' || _language[1] === 'ENGLISH' || items._LANGUAGES === "ENGLISH") ? 
                    <div className={`field ${(items._LANGUAGES === "ENGLISH") ? 'mb-8' : ""}`}>
                        <label htmlFor="url" className="block">Url from Request <b>ENGLISH</b> </label>
                        <span className="p-input-icon-left w-full">
                            <i className="pi pi-globe text-pink-500" />
                            <InputText 
                                id="url" 
                                aria-describedby="url-help" 
                                value={__urlEnglish}
                                onChange={ (text) => setUrlEnglish(text.target.value) }
                                className="block w-full" 
                                placeholder='https://letusclaimcons.sharepoint.com' 
                            />
                        </span>
                        <small id="url-help" className="block">Enter the url of the request.</small>
                    </div> : null
            }

            {
                (_language[1] === 'SPANISH' || _language[0] === 'SPANISH' || items._LANGUAGES === 'SPANISH') ? 
                    <div className="field mb-8">
                        <label htmlFor="url" className="block">Url from Request <b>SPANISH</b></label>
                        <span className="p-input-icon-left w-full">
                            <i className="pi pi-globe text-pink-500" />
                            <InputText 
                                id="url" 
                                aria-describedby="url-help" 
                                value={__urlSpanish}
                                onChange={ (text) => setUrlSpanish(text.target.value) }
                                className="block w-full" 
                                placeholder='https://letusclaimcons.sharepoint.com' 
                            />
                        </span>
                        <small id="url-help" className="block">Enter the url of the request.</small>
                    </div> : null
            }

            <Dialog visible={loading} style={{ width: '450px' }} showHeader={false} modal onHide={loading}>
                <div className="text-center">
                    <div className="grid">
                        <div className="col-12">
                            <p className="text-2xl p-4"> 
                                <b>Upload request </b>, please wait...
                            </p>
                        </div>
                        <div className="col-12">
                            <ProgressBar mode="indeterminate" className='w-full text-color h-1rem' ></ProgressBar>
                        </div>
                    </div>
                </div>
            </Dialog>

            <ButtonsActions 
                cancel={ () => hide() }
                submit={ () => sendData() }
                loading={loading}
            />
        </div>
    )
}
