import React, { useState, useEffect, useRef, memo } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import EditAdd from './EditAdd';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FormatService } from '../../service/FormatService';
import { toastAlert } from '../../core/utils';
import LeftSidebar from '../../components/LeftSidebar';
import { Can } from '../../components/PermissionGate';

function Lists() {

    const dataService = new FormatService();

    const [dataList, setDataList] = useState(null);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [modalTitle, setModalTitle] = useState("Add");
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [itemsDropdows, setItemsDropdows] = useState([]);
    const [filters2, setFilters2] = useState(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    useEffect(() => {
        getData();
        initFilters1();
    }, []);

    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'urgent': { value: null, matchMode: FilterMatchMode.IN },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'types': { value: null, matchMode: FilterMatchMode.IN },
        });
        setGlobalFilterValue2('');
    }

    const getData = () => {
        try {
            setLoading(true);
            dataService.getList().then( data => {
                setDataList(data);
                setLoading(false);

                const ff = [];
                data.map( (format) => {
                    ff.push(format._NAME);
                });
                
                setItemsDropdows(ff);
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }
        
    }

    const addItemList = (row) => {
        let data = [...dataList];
        let drop = [...itemsDropdows];
        drop.splice(0, 0, row._NAME);
        data.splice(0, 0, row);
        setDataList(data);
        setItemsDropdows(drop);
    }

    const updateItemList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index] = {
            _ID: row._ID,
            _NAME: row._NAME,
            _DIMENSIONS: row._DIMENSIONS
        };
        
        setDataList(data);
        
    }

    const openNew = () => {
        setModalTitle("Add");
        setItem([]);
        setShowAdd(true);
    }

    const hideNew = () => {
        setShowAdd(false);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const confirmDeleteData = (data) => {
        setDataId(data._ID);
        setDataGlobal(data);
        setDeleteDataDialog(true);
    }

    const removeItemList = (row) => {

        let filtered = dataList.filter( function(value, index, arr) { 
            return value._NAME !== row._NAME;
        });
        setDataList(filtered);
    }

    const deleteDataSelected = async() => {
        
        try {
            setLoading(true);
            setLoadingButton(true);
            await dataService.deleteData(dataId).then(data => {
                if (data._SUCCESS) {
                    removeItemList(dataGlobal);
                    setLoading(false);
                    setLoadingButton(false);
                    setDeleteDataDialog(false);
                    setVisibleLeft(false);

                    let filtered = itemsDropdows.filter( function(value, index, arr) { 
                        return value !== dataGlobal._NAME;
                    });

                    setItemsDropdows(filtered);

                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setShowAdd(true);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {
                    Can('formats.create') ? 
                        <Button 
                            label="New" 
                            icon="pi pi-plus" 
                            className="p-button-success mr-2" 
                            onClick={ () => openNew() } /> : <></>
                }
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );

        return (
            <>
                <span className="p-column-title">#</span>
                { index }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                { rowData._NAME }
            </>
        );
    }

    const formatBodyTemplate = (rowData) => {

        const _dimensions = rowData._DIMENSIONS.map( (value, index) =>
            <div key={ index }>{ value.dimension }</div>
        );

        return (
            <>
                <span className="p-column-title">Dimension</span>
                { _dimensions }
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Status</span>
                {
                    (rowData._STATUS === 1) ?
                    <span className="p-tag p-component p-tag-success mr-1">
                        <span className="p-tag-icon pi pi-check"></span>
                        <span className="p-tag-value">Active</span>
                        <span></span>
                    </span> :
                    <span className="p-tag p-component p-tag-warning mr-1">
                        <span className="p-tag-icon pi pi-times"></span>
                        <span className="p-tag-value">Inactive</span>
                        <span></span>
                    </span>
                }
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {
                    Can('formats.update') ? 
                        <Button
                            tooltip='Edit'
                            tooltipOptions={{ position: 'top' }}
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            onClick={ () => editData(rowData) }
                        /> : <></>
                }

                {
                    Can('formats.delete') ?
                        <Button
                            tooltip='Delete'
                            tooltipOptions={{ position: 'top' }}
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                            onClick={ () => confirmDeleteData(rowData) }
                        /> : <></>
                }
            </div>
        );
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Formats Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );


    return (
       <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={dataList} loading={loading}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} formats"
                        emptyMessage="No formats found." header={header} responsiveLayout="scroll"
                        filters={filters2} 
                        filterDisplay="menu" 
                        globalFilterFields={['_NAME']}
                    >
                        {/*<Column field="_id" header="#" body={ idBodyTemplate } style={{ width: '10%' }}></Column>*/}
                        <Column field="social_name" header="Name" body={ nameBodyTemplate }></Column>
                        <Column field="dimension" header="Dimensions" body={ formatBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '20%' }}></Column>
                    </DataTable>

                    
                    <EditAdd
                        visible={showAdd}
                        hide={ hideNew }
                        title={ modalTitle }
                        items={ item }
                        addItemList={ addItemList }
                        updateItemList={ updateItemList }
                        setLoading={ setLoading }
                        setItems={ setItem }
                        itemsDropdows={ itemsDropdows }
                    />


                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Are you sure you want to delete <b>{dataGlobal._NAME}</b>?</span> }
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(Lists, comparisonFn);
