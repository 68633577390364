import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import EditAdd from '../modules/assignments/EditAdd';
import { AssignmentService } from '../service/AssignmentService';
import { Tooltip } from 'primereact/tooltip';

function ButtonsAssigned({ id, hidePrevious, dataList, setAssignment, updateDesigner, disable }) {

    const dataService = new AssignmentService();

    const [visible, setVisible] = useState(false);
    const [designers, setDesigners] = useState([]);

    const open = () => {
        setVisible(true);
    }

    const hide = () => {
        setVisible(false);
    }

    useEffect( () => {
        getDesigners();
    }, []);

    const getDesigners = () => {
        try {
            dataService.getDesigners().then( data => {
                setDesigners(data);
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <div className='absolute z-2 bottom-0 right-0 w-full surface-200 border-top-1 border-400'>
                <div className='flex justify-content-end p-3'>
                    <Button label="Cancel" className="p-button-sm mr-2 p-button-cancel" onClick={ () => hidePrevious() } />
         
                    <Tooltip target=".disabled-button" />
                    <span 
                        className="disabled-button mr-2" 
                        data-pr-tooltip={ (disable) ? "The campaign is not IN PROCESS yet" : null }>
                        <Button 
                            label="To Assign" 
                            className="p-button-sm"
                            onClick={ () => open() }
                            disabled={ disable } 
                        />
                    </span>
                </div>
            </div>

            <EditAdd 
                onVisible={ visible }
                onHide={ hide }
                designers={ designers.items || [] }
                dataList={ dataList || [] }
                idRequest={ id }
                setAssignment={ setAssignment }
                updateDesignerAssignment={updateDesigner}
            />
        </div>
    )
}

export default ButtonsAssigned;