import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Divider } from 'primereact/divider';
import { AuthService } from '../../service/AuthService';
import { toastAlert } from '../../core/utils';
import Connection from '../../core/ApiConnection';
import LogoDark from '../../assets/images/logo-dark.png';


function Login({ alertCallback }) {

    const dataService = new AuthService();

    const [userEmail, setUserEmail] = useState(null);
    const [userPassword, setUserPassword] = useState(null);
    const [checked, setChecked] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const toast = useRef(null);

    const handleLogin = async () => {
        setLoadingButton(true);

        const data = {
            'email'    : userEmail,
            'password' : userPassword,
            'remenber' : checked,
        }

        try {
            
            await Connection.get('/sanctum/csrf-cookie').then( response => {
    
                const csrf = JSON.stringify(response.config);

                if (csrf) {
                    dataService.getLogin(data).then( datas => {
                        setLoadingButton(false);
                        if (datas.success) {
                            toastAlert("success", datas.message, toast );
                            
                            setTimeout( () => {
                                localStorage.setItem( 'user', JSON.stringify(datas.user) );
                                localStorage.setItem( 'token', datas.token );
                                window.location.replace("/");
                            }, 1000);
                        } else {
                            setLoadingButton(false);
                            toastAlert("warn", datas.message, toast );
                        }
            
                    }).catch((error) => {
                        setLoadingButton(false);
                        toastAlert( error.variant, error.data, toast );
                    });
                } else {
                    setLoadingButton(false);
                    toastAlert("warn", 'CSRF Inavalid!', toast );
                }
            });

        } catch (error) {
            setLoadingButton(false);
            toastAlert( error.variant, error.data, toast );
        }

    }

    return (
        <div className='bg-cover bg-center bg-no-repeat w-full h-screen flex justify-content-center align-items-center' style={{ backgroundImage: "url(images/general/light-bg.jpg" }} >
            <Toast ref={ toast } style={{ fontSize: '14px' }} />
            <div className='grid w-full flex justify-content-center align-items-center'>
                <div className='col-8'>
                    <div className="card">
                        <div className="formgrid grid">
                            <div className="field col-5 flex justify-content-center align-items-center">
                                <img  src={ LogoDark } alt='logo-dark' />
                            </div>

                            <div className="field col-2">
                                <Divider layout='vertical' />
                            </div>

                            <div className="field col-5 flex flex-column justify-content-center align-items-center">
                                <div className="col-12 text-center mt-5">
                                    <h1>Login</h1>
                                </div> 

                                <div className="p-fluid grid pt-4 pr-6">

                                    <div className="field col-12">
                                        <label htmlFor="email" className='text-2xl'>Email</label>
                                        <InputText
                                            type="email"
                                            required
                                            className='p-3 text-xl'
                                            placeholder='letusclaim@letusclaim.com'
                                            onChange={ (e) => { setUserEmail(e.target.value) }}
                                        />
                                    </div>

                                    <div className="field col-12">
                                        <label htmlFor="password" className='text-2xl'>Password</label>
                                        <Password 
                                            toggleMask 
                                            className='text-xl'
                                            inputClassName='p-3 text-xl'
                                            placeholder='************'
                                            onChange={ (e) => { setUserPassword(e.target.value) }}
                                        />
                                    </div>

                                    <div className="field-checkbox col-12">
                                        <Checkbox inputId="check" checked={checked} onChange={ (e) => setChecked(e.checked) } />
                                        <label htmlFor="check" className='text-lg'>Remember Me</label>
                                    </div>
                                </div>

                                <div className="p-fluid grid pt-3 w-full">
                                    <div className="field col-12">
                                        <Button
                                            label='Login'
                                            className="text-2xl btn"
                                            loading={ loadingButton }
                                            onClick={ () => handleLogin() }
                                        >
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-12 text-center">
                                <h5>Digital Marketing System v2.0</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Login;