import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import ReactPlayer from 'react-player/lazy';
import { Chip } from 'primereact/chip';
import { Link } from 'react-router-dom';
import FlagEEUU from '../../assets/images/flag-eeuu.png';
import FlgSpain from '../../assets/images/flag-spain.png';
import { iconsTemplate } from '../../core/utils';
import { useAuth } from '../../hooks/useAuth';
import { downloadFiles } from '../../core/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeftSidebar from '../../components/LeftSidebar';
import { Tag } from 'primereact/tag';
import ButtonsActions from '../../components/ButtonsActions';
import RequestUpload from './RequestUpload';

function ViewData({visible, closeViewData, items, dataUpload, alert, loading, setLoading}) {

    const [openModalUpload, setOpenModalUpload] = useState(false);

    const handleLanguages = () => {
        if (items._LANGUAGES !== undefined && items._LANGUAGES !== null) {
            const languages = items._LANGUAGES;
            const medios = languages.split(',');
    
            return (
                medios.map( (language, i) => (
                    <Chip label={ language } image={ (language === "ENGLISH") ? FlagEEUU : FlgSpain } className="ml-2 mr-2 w-max"  key={ i } />
                ))
            )
        }
    }

    const socialResources = (
        (items._RESOURCES !== undefined) ?
            Object.values(items._RESOURCES).map( (resource, x) => {
                let url  = resource.resource;
                let segm = url.split('.');
                return (
                    <li className="py-2 surface-border" key={x}>
                        <a 
                            className="
                                p-ripple 
                                cursor-pointer 
                                flex 
                                align-items-center 
                                justify-content-between 
                                hover:surface-200 
                                transition-colors 
                                transition-duration-150 
                                border-round p-2"
                                onClick={ () => window.open(resource.resource, '_blank') } >
                            { 
                                (resource.type === "Video") ? 
                                <ReactPlayer url={ resource.resource } width={100} height={60} style={{ borderRadius: '10px' }} className="test" /> :
                                    ( segm[0] !== 'https://letusclaimcons' ) ? 
                                        <Image 
                                            src={ resource.resource } 
                                            alt={ resource.resource } 
                                            width={100} height={60} 
                                            imageStyle={{ borderRadius: '10px' }} /> : 
                                            <i className="pi pi-image" style={{'fontSize': '3em'}}></i>
                            }
                                <div style={{ marginLeft: '20px' }}>
                                    <span className="block text-900 mb-1">{ resource._id }</span>
                                </div>
                                <i className="ml-auto pi pi-chevron-right text-600 text-sm"></i>
                                <span role="presentation" className="p-ink" style={{ height: '255px', width: '255px', top: '-98.5px', left: '-89.5px' }}></span>
                        </a>
                    </li>
                )
            }) : null
    );

    const requestFiles = (
        (items._FILES !== undefined) ?
            (items._FILES).map( (file, i) => {
                let fileExt = file.filename;
                const [ext, ...fileName] = fileExt.split('.').reverse();
                return (
                    <li className="py-2 surface-border" key={i}>
                        <a 
                            className="
                                p-ripple 
                                cursor-pointer 
                                flex 
                                align-items-center 
                                justify-content-between 
                                hover:surface-200 
                                transition-colors 
                                transition-duration-150 
                                border-round p-2"
                            >
                                {
                                    ( ext === 'mp4' || ext === 'jpg' || ext === 'png' ) ?
                                        ( ext === 'mp4' ?
                                            <ReactPlayer 
                                                url={ file.pathUrl } 
                                                width={100} 
                                                height={60} 
                                                style={{ borderRadius: '10px' }} 
                                                className="test" /> : 
                                            <Image 
                                                src={ file.pathUrl } 
                                                alt={ fileExt } 
                                                preview 
                                                width={100} 
                                                height={60} 
                                                imageStyle={{ borderRadius: '10px' }} /> ) : 
                                                
                                            ( ext === 'pdf' ? 
                                                <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                                    <i className="pi pi-file-pdf" style={{'fontSize': '3em'}}></i></div> : 
                                                <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                                    <i className="pi pi-file-word" style={{'fontSize': '3em'}}></i></div> )
                                }
                               
                                <div style={{ marginLeft: '20px' }}>
                                    <span className="block text-900 mb-1">{ file._id }</span>
                                </div>
                                
                                <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                    <i className="ml-auto mr-5 pi pi-download text-600 text-xl"></i>
                                </div>
                                
                        </a>
                    </li>
                )
            }) : null
    );  

    const openModal = () => {
        setOpenModalUpload(true);
    }

    const hideModal = () => {
        setOpenModalUpload(false);
    }

    return (
        <LeftSidebar
            onVisible={ visible }
            onHide={ () => closeViewData() }
            onWidth={ 560 }
        >
          
            <div className="surface-0 pb-8">
                <div className="font-medium text-3xl mb-3 text-color">Request It Information</div>
            
                <ul className="list-none p-0 m-0">
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Status</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {
                                (items._NOTIFY === false) ?
                                    <Tag className="mr-2" severity="warning" value="SAVED"></Tag> :
                                    <Tag className="mr-2" severity="success" value="NOTIFIED"></Tag>
                            }
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Title</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._TITLE }</div>
                    </li>
                    
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Description</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {items._DESCRIPTION}
                        </div>
                    </li> 
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Languages</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            { handleLanguages() }
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Delivery Date</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {items._DATEDELIVERY}
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Register date</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {items._DATEASSIGN}
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Register by</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {items._REGISTER}
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Assigned</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {items._ASSIGNED}
                        </div>
                    </li>

                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <h5>Online Resources</h5>
                        <ul className="list-none p-0 m-0 w-full">
                            {socialResources}
                        </ul>
                    </li>

                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <h5>Files</h5>
                        <ul className="list-none p-0 m-0 w-full">
                            {requestFiles}
                        </ul>
                    </li> 
                </ul>
            </div>

            <Dialog 
                visible={openModalUpload} 
                style={{ width: '550px' }} 
                header="Request Upload" modal  onHide={hideModal}>

                <RequestUpload 
                    items={items}
                    hide={hideModal}
                    updateData={dataUpload}
                    onAlert={alert}
                    loading={loading}
                    setLoading={setLoading}
                />
            </Dialog>


            {
                (items._NOTIFY === false) ?
                    <ButtonsActions
                        cancel={ () => closeViewData() }
                        text={'Upload'}
                        submit={ () => openModal() }
                    /> : null
            }               

        
        </LeftSidebar>
        
    )
}

export default ViewData;