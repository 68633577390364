import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { CampaignsService } from '../service/CampaignsService';
import { iconStatus, colorStatus } from '../core/utils';
import ButtonsActions from './ButtonsActions';

function StatusTimeline({ id, items, setLoading, onAlert, dataList, setDataList, hide, records }) {

    const dataService = new CampaignsService();

    const statuses = [
        { _STATUS: 'SAVED', key: 0 }, 
        { _STATUS: 'IN PROCESS', key: 1 }, 
        { _STATUS: 'IN PROGRESS', key: 2 }, 
        { _STATUS: 'FINISHED', key: 3 },
        { _STATUS: 'PAUSED', key: 5 },
        { _STATUS: 'CANCELED', key: 6 },
    ];

    const [loadingButton, setLoadingButton] = useState(false);
    const [modalChangeStatus, setModalChangeStatus] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState(records);
    const [selectedCurrent, setSelectedCurrent] = useState(records);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [checked, setChecked] = useState( selectedStatuses.filter( (rt) => rt._STATUS !== "PAUSED") );
    const [_isPaused, setIsPaused] = useState(false);

    useEffect( () => {
        if ( items._STATUS === "PAUSED" ) {
            setIsPaused(true);
        }
        
    }, [checkPaused]);

    const allStatus = (
        records.map( (s, i) => {
            return (
                <div className="my-5 flex" key={i}>
                    <div className="flex flex-column align-items-center" style={{ width: "2rem" }}>
                        <span className="text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: "2.5rem", minHeight: "2.5rem", background: colorStatus(s._STATUS) }}>
	                        <i className={ iconStatus(s._STATUS) }></i>
                        </span>
                        <div className="h-full" style={{ width: "2px", minHeight: "4rem", background: colorStatus(s._STATUS) }}></div>
                    </div>

                    <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                        <div className="mb-3">
                            <span className="text-900 font-medium inline-block mr-3">{ s._USER }</span>
                            <span className="text-500 text-sm">{ s._DATE }</span> 
                        </div>

                        <div className="line-height-3 text-700 mb-3">
                            <h5 className='font-semibold'>{ s._STATUS }</h5>
                        </div>
                    </div>
                </div>
            )
        })
    );

    const openChangeStatus = (data) => {
        setModalChangeStatus(true);
    }

    const hideModalStatusDialog = () => {
        setModalChangeStatus(false);
    }
    
    const modalStatusFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ () => { hideModalStatusDialog(); reject(); } } />
            <Button label="Yes" loading={loadingButton} disabled={ buttonDisabled }  icon="pi pi-check" className="p-button-text" onClick={ (e) => confirm(e) } />
        </>
    );

    const confirm = (event) => {

        let selected  = selectedStatuses[ selectedStatuses.length - 1 ];
        
        confirmPopup({
            target: event.currentTarget,
            message: (selected.status === "CANCELED") ? 'Are you sure to cancel this campaign? This action is irreversible!' : 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };

    const reject = () => {
        setButtonDisabled(true);
        let current = records[ records.length - 1 ];
        let reject = [...selectedStatuses];
        let selected  = selectedStatuses[ selectedStatuses.length - 1 ];
        let index = reject.indexOf(selected.status);
        if ( current.status !== selected.status ) {
            reject.splice(index, 1);
            setSelectedStatuses(reject);
        }
    };

    const accept = () => {
        onStatusChange();
    };
    
    var checkPaused = false;
    const checkIsPaused = (last) => {
        if ( last === "PAUSED" ) {
            checkPaused = true;
        } else {
            checkPaused = false;
        }
        
    }


    const restartCampaing = async() => {
        setLoading(true);
        setLoadingButton(true);
        try {
            let last = selectedStatuses[ selectedStatuses.length - 2 ];

            const data = {
                _ID: id,
                _STATUS: last._STATUS
            }
            
            await dataService.getStatusChange(data).then( response => {
                if ( response._SUCCESS === true ) {
                    const newItem = response._STATUS;
                    updateStatusList(response._ID,  JSON.stringify(response._STATUS));
                    hideModalStatusDialog();
                    setIsPaused(false);
                    onAlert( 'success', response._MESSAGE );
                } else {
                    onAlert( 'warn', response._MESSAGE );
                }
            }).finally( () => {
                setLoading(false);
                setLoadingButton(false);
            });

        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    const CheckboxStatus =  (
        statuses.map( (st, i) => {
            let current  = items._STATUS;
            const sel    = [...selectedStatuses];
            
            if ( current === "PAUSED" ) {
               checkIsPaused("PAUSED");
            }

            const isChecked  = checked.some( (item) => item._STATUS === st._STATUS );
            const isDisabled = !isChecked && ( sel.filter((i) => i._STATUS) === current );

            return (
                
                <div key={i} className="field-checkbox" >
                    <Checkbox inputId={ st.key } 
                        name={ st._STATUS } 
                        value={ st }
                        className="check"
                        onChange={ (e) => { 
                            onStatusValueChange(e); 
                            
                        }} 
                        checked={ isChecked } 
                        disabled={ ( current === "SAVED" ) ?
                                (selectedCurrent.length !== i) 
                            :  isDisabled  } 

                    />
                    <label htmlFor={ st.key }>{ st._STATUS }</label>
                </div>
            
            )
        })
    );

    const onStatusValueChange = (e) => {

        let _selectedStatuses = [...selectedStatuses];
        const filter = _selectedStatuses.filter( (rt) => rt._STATUS !== "PAUSED" );

        if (e.checked) {
            setButtonDisabled(false);
            _selectedStatuses.push(e.value);
            filter.push(e.value);
        }
        else {
            setButtonDisabled(true);
            for (let i = 0; i < _selectedStatuses.length; i++) {
                const selectedST = _selectedStatuses[i];
                
                if (selectedST.key === e.value.key) {
                    _selectedStatuses.splice(i, 1);
                    filter.splice(i, 1);
                    break;
                }
            }
        }
        
        setChecked(filter);
        setSelectedStatuses(_selectedStatuses);
    }

    const updateStatusList = (id, status) => {
        let data = [...dataList];
        let newItem = [...records];

        const index = dataList.map( function(x) { return x._ID; } ).indexOf(id);

        data[index]._STATUS = JSON.parse(status);

        data.filter( st => st._ID === id ).map( (s, i) => {
            s._RECORDS.push( JSON.parse(status) );
        });

        newItem.push(JSON.parse(status));
        
        setSelectedStatuses(newItem);
        setDataList(data);
    }

    const onStatusChange = async () => {
        setLoading(true);
        setLoadingButton(true);
        try {
            let last = selectedStatuses.pop();

            const data = {
                _ID: id,
                _STATUS: last._STATUS
            }
            
            await dataService.getStatusChange(data).then( response => {
                setLoading(false);
                setLoadingButton(false);
                if ( response._SUCCESS === true ) {
                    const newItem = response._STATUS;
                    updateStatusList(response._ID,  JSON.stringify(response._STATUS));
                    hideModalStatusDialog();
                    if ( newItem['_STATUS'] === "PAUSED" ) {
                        setIsPaused(true);
                    }
                    onAlert( 'success', response._MESSAGE );
                } else {
                    onAlert( 'warn', response._MESSAGE );
                }
            });

        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    const renderButtonActions = (
        ( items._STATUS !== "FINISHED" && items._STATUS !== "CANCELED" ) ? 
            <ButtonsActions 
                submit={ () => openChangeStatus() }
                cancel={ () => hide() }
                text="Change"
                isPaused={ _isPaused }
                setSent={ () => restartCampaing() }
            /> : <></>
    )

    return (
        <div>
            <h3 className='text-color'>Status</h3>
            <div className='mb-8'>
                { allStatus }
            </div>

            {renderButtonActions}

            <ConfirmPopup />
            
            <Dialog visible={modalChangeStatus} style={{ width: '35vw' }} header="Change status" modal footer={modalStatusFooter} onHide={hideModalStatusDialog}>
                { CheckboxStatus }
            </Dialog>
        </div>
    )
}

export default StatusTimeline;