import Connection from "../core/ApiConnection";

export class RequestService {
    async getList (data) {
        return await Connection.get('/request', data).then( res => res.data.items );
    }

    async saveData(data) {
        return await Connection.post('/request', data).then( res => res.data );
    }

    async updateData(data) {
        return await Connection.post('/request/update', data).then( res => res.data );
    }

    async deleteData(data) {
        return await Connection.delete('/request/' + data).then( res => res.data );
    }

    async searchFormats(data) {
        return await Connection.post('/search-format', data).then( res => res.data );
    }

    async deleteResource(data) {
        return await Connection.post('/request/delete-resource', data).then( res => res.data );
    }

    async deleteFile(data) {
        return await Connection.post('/request/delete-file', data).then( res => res.data );
    }

    async getStatusChange(data) {
        return await Connection.post('/request/change-status', data).then( res => res.data );
    }

    async getDashboard() {
        return await Connection.get('/dashboard').then( res => res.data );
    }

    async getDashboardRequest() {
        return await Connection.get('/dashboard').then( res => res.data );
    }

}