import React, { useState, useEffect, useRef, memo } from 'react';
import EditAdd from './EditAdd';
import ViewRequest from './ViewData';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import LeftSidebar from '../../components/LeftSidebar';
import { FormatService } from '../../service/FormatService';
import { separateString, toastAlert } from '../../core/utils';
import { RequestService } from '../../service/RequestService';
import { AccountService } from '../../service/AccountService';
import { CampaignsService } from '../../service/CampaignsService';
import { SocialService } from '../../service/SocialService';
import TimeLineRequests from '../../components/TimeLineRequests';
import { Can } from '../../components/PermissionGate';
import { useAuth } from '../../hooks/useAuth';
import { Badge } from 'primereact/badge';
import { Chip } from 'primereact/chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { requestStatuses } from '../../core/utils';
 

function RequestList() {
    const dataService     = new RequestService();
    const mediosService   = new FormatService();
    const accountService  = new AccountService();
    const campaignService = new CampaignsService();
    const socialService   = new SocialService();

    const { 0:auth } = useAuth();

    const [dataList, setDataList] = useState([]);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [updateDataDialog, setUpdateDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [modalTitle, setModalTitle] = useState("Add");
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(null);
    const [medios, setMedios] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [campaign, setCampaign] = useState([]);
    const [visibleStatus, setVisibleStatus] = useState(false);
    const [allsStatus, setAllsStatus] = useState([]);
    const [social, setSocial] = useState([]);
    const [isUrgent, setIsUrgent] = useState(false);
    const [campaignRequest, setCampaignRequest] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const [filters2, setFilters2] = useState(null);
    const [optionsUrgent, setOptionsUrgent] = useState(""); 

    /*const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });*/

    const urgent = [
        {label: 'YES', value: 'YES'},
        {label: 'NO', value: 'NO'}
    ];

    const types = [
        'INTERNAL', 'EXTERNAL'
    ];

    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    
    useEffect(() => {
        initFilters1();
        let isMounted = true; 

        if (isMounted) {
            getData();
            getMedios();
            getAccounts();
            getSocial();
            getCampaignsRequest();
        }

        return () => { isMounted = false };
        
    }, []);

    const getData = async () => {
        try {         
            setLoading(true);
            const datas = { _STATUS: 'SAVED' };
            await dataService.getList(datas).then(data => {
                setDataList(data);
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const getMedios = async () => {
        try {
            await mediosService.getList().then( data => {
                setMedios(data);
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const getAccounts = async () => {
        try {
            await accountService.getList().then( data => {
                setAccounts(data);
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const getCampaignsRequest = async() => {
        try {
            await campaignService.getRequestCampaign().then( response =>  {
                const filter = response.items.filter( (item) => item._STATUS._STATUS === "IN PROCESS" || item._STATUS._STATUS === "IN PROGRESS" );
                setCampaign(filter);
            });
        } catch (error) {
            onAlert(error.variant, error.data);
        }
    }

    const getSocial = async() => {
        try {
            await socialService.getList().then( response => {
                setSocial(response);
            });
            
        } catch (error) {
            onAlert(error.variant, error.data);
        }
    }

    const openNew = () => {
        setModalTitle("Add");
        setItem([]);
        setShowAdd(true);
    }

    const hideNew = () => {
        setShowAdd(false);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const hideUpdateDataDialog = () => {
        setUpdateDataDialog(false);
    }

    const confirmDeleteData = (data) => {
        setDataId(data._ID);
        setDataGlobal(data);
        setDeleteDataDialog(true);
    }

    const addItemList = (row) => {
        let data = [...dataList];
        data.splice(0, 0, row);
        setDataList(data);
    }

    const updateItemList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);

        data[index] = {
            _ID: row._ID,
            _IDCAMPAIGN: row._IDCAMPAIGN,
            _CAMPAIGN: row._CAMPAIGN,
            _TYPE: row._TYPE,
            _CONCEPT: row._CONCEPT,
            _LANGUAGES: row._LANGUAGES,
            _ACCOUNT: row._ACCOUNT,
            _DHANDLE: row._DHANDLE,
            _DPUBLIC: row._DPUBLIC,
            _CAPTION: row._CAPTION,
            _DESCRIPTION: row._DESCRIPTION,
            _RESOURCES: row._RESOURCES,
            _FILES: row._FILES,
            _TYPEFORMAT: row._TYPEFORMAT,
            _FORMATS: row._FORMATS,
            _STATUS: row._STATUS,
            _URGENT: row._URGENT,
            _REGISTER: row._REGISTER,
            _ASSIGNED: row._ASSIGNED,
            _STCAMPAIGN: row._STCAMPAIGN,
            _ISVIDEO: row._ISVIDEO,
            _TIME: row._TIME,
            _SUBTITLE: row._SUBTITLE,
        };
        
        setDataList(data);
    }

    const updateDesignerAssignment = (row) => {
        let data = [...dataList];
        
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);

        data[index]._ASSIGNED = row._DESIGNER;

        setDataList(data);
    }

    const removeItemList = (row) => {
        let data = [...dataList];
        data.splice(row, 1);
        setDataList(data);
    } 

    const deleteDataSelected = async() => {
        
        try {
            setLoading(true);
            setLoadingButton(true);
            await dataService.deleteData(dataId).then(data => {
                if (data._SUCCESS) {
                    removeItemList(dataGlobal);
                    setLoading(false);
                    setLoadingButton(false);
                    setDeleteDataDialog(false);
                    setVisibleLeft(false);
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setShowAdd(true);
    }

    const onStatusChange = async (e) => {
        try {
            setLoading(true);
            setLoadingButton(true);
            const data = {
                _ID:    dataId,
                _CHECK: checked
            }

            await dataService.changeStatus(data).then( data => {

                if (data._SUCCESS) {
                    setLoading(false);
                    setLoadingButton(false);
                    setUpdateDataDialog(false);
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
                
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }


    const viewData = async(data) => {
        setItem(data);
        setVisibleLeft(true);
    }

    const closeViewData = () => {
        setItem([]);
        setVisibleLeft(false);
    }

    const viewStatus = async(id, data, urgent, cpn) => {
        setDataId(id);
        setAllsStatus(data);
        setIsUrgent(urgent);
        setCampaignRequest(cpn);
        setVisibleStatus(true);
    }

    const closeViewstatus = () => {
        setDataId(null);
        setAllsStatus([]);
        setVisibleStatus(false);
    }

    const onAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {
                    Can('requests.create') ? 
                        <Button 
                            label="New" 
                            icon="pi pi-plus" 
                            className="p-button-success mr-2" onClick={ openNew } 
                        /> : <></>
                }
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className={ (rowData._TYPE === 'INTERNAL') ? `custom-badge bg-bluegray-500` : `custom-badge bg-teal-500` } >
                    { rowData._TYPE }
                </span>
            </>
        );
    }

    const startDatetBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                { separateString(rowData._CONCEPT) }
            </>
        );
    }

    const endDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                { rowData._DPUBLIC }
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        const status     = rowData._STATUS;
        const allStatus  = status.request_status;
        const lastStatus = allStatus[ allStatus.length - 1 ];

        return (
            <>
                <span className="p-column-title">Status</span>
                <Button label={ lastStatus.status } className="p-button-text" onClick={ () => viewStatus(rowData._ID, allStatus, rowData._URGENT, rowData._CAMPAIGN) } />
            </>
        )
    }

    const urgentBodyTemplate = (rowData) => {
        
        let className;
        let text;
        let icon;
        
        if ( rowData._URGENT === true ) {
            className = "bg-green-600";
            icon= "pi pi-check-circle";
            text = 'YES';
        } else {
            className = "bg-cyan-600";
            icon= "pi pi-times-circle";
            text = 'NO';
        }

        return (
            <> 
                <Tag value={ text } icon={ icon } className={ `text-sm ${className}` }></Tag>
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Register</span>
                { rowData._REGISTER }
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        const status     = rowData._STATUS;
        const allStatus  = status.request_status;
        const lastStatus = allStatus[ allStatus.length - 1 ];


        return (
            <div className="actions">
                {
                    Can('requests.read') ? 
                        <Button
                            icon="pi pi-eye"
                            id={ rowData._ID }
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Show'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => viewData(rowData) }
                        /> : <></>
                }

                {
                    Can('requests.update') ? 
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Edit'
                            tooltipOptions={{ position: 'top' }}
                            disabled={ 
                                rowData._AUTHOR === false &&
                                (auth._ROLNAME !== "Design Supervisor") &&
                                (auth._ROLNAME !== "Supervisor") &&
                                (auth._ROLNAME !== "Administrator") ||
                                lastStatus.status === 'SENT FOR REVIEW' || 
                                lastStatus.status === 'APPROVED'
                            }
                            onClick={ () => editData(rowData) }
                        /> : <></>
                }

                {
                    Can('requests.delete') ?
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                            tooltip='Delete'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => confirmDeleteData(rowData) }
                            /*disabled={ 
                                allStatus.some( (st) => st.status === 'SENT FOR REVIEW' ) || 
                                auth._ROLNAME === "Community Manager" && rowData._AUTHOR === false 
                            }*/
                        /> : <></>
                }

            </div>
        );
    }

    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'urgent': { value: null, matchMode: FilterMatchMode.IN },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'types': { value: null, matchMode: FilterMatchMode.IN },
        });
        setGlobalFilterValue2('');
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Design Request Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );

    
    const updateDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideUpdateDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ onStatusChange } />
        </>
    );
 
    const statusHeaderSaved = (data) => {
        let saved = 0;
        let sent = 0;
        let approved = 0;
        
        if (dataList) {
            for (let list of dataList) {
                if (list._CAMPAIGN === data) {
                    const status     = list._STATUS;
                    const allStatus  = status.request_status;
                    const lastStatus = allStatus[ allStatus.length - 1 ];

                    if ( lastStatus.status === "SAVED" ) {
                        saved++;
                    }

                    if ( lastStatus.status === "SENT FOR REVIEW" ) {
                        sent++;
                    }

                    if ( lastStatus.status === "APPROVED" ) {
                        approved++;
                    }
                }
            }

            return(
                <React.Fragment>
                    <div className="flex align-items-center flex-wrap">
                        <Chip className='mr-3' template={ 
                                <span className='p-chip p-component'> 
                                    <FontAwesomeIcon icon="save"  className=' p-chip-icon' /> 
                                    <span className='p-chip-text'>SAVED</span> 
                                    <Badge value={`${saved}`} className="ml-2" ></Badge>
                                </span> 
                            } 
                        />
                        <Chip className='mr-3' template={ 
                                <span className='p-chip p-component'> 
                                    <FontAwesomeIcon icon="check"  className=' p-chip-icon' /> 
                                    <span className='p-chip-text'>SENT FOR REVIEW</span> 
                                    <Badge value={`${sent}`} className="ml-2" ></Badge>
                                </span> 
                            } 
                        />
                        <Chip template={ 
                                <span className='p-chip p-component'> 
                                    <FontAwesomeIcon icon="check-double"  className=' p-chip-icon' /> 
                                    <span className='p-chip-text'>APPROVED</span> 
                                    <Badge value={`${approved}`} className="ml-2" ></Badge>
                                </span> 
                            } 
                        />
                    </div>
                </React.Fragment>
            )
        }
    }

    const headerTemplate = (data) => {
       
        return (
            <React.Fragment>
                {
                    (data._IDCAMPAIGN !== null) ?
                        <React.Fragment>
                            <span className="font-semibold capitalize text-lg text-green-700">{data._CAMPAIGN}</span> 
                            {/*<span>{statusHeaderSaved(data._CAMPAIGN)}</span>*/}
                        </React.Fragment>
                        : 
                        <React.Fragment>
                            <span className="font-semibold capitalize text-lg text-blue-700">External Design Requests</span>
                            {/*<span>{statusHeaderSaved(data._CAMPAIGN)}</span>*/}
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }

    const templateAssigned = (rowData) => {
        let assigned = "Not Assigned";

        if (rowData._ASSIGNED !== null) {
            assigned = rowData._ASSIGNED;
        }
        return (
            <>
                <span className="p-column-title">Assigned</span>
                { assigned }
            </>
        );
    }

    const rowClass = (rowData) => {
        return {
            'row-assigned': rowData._ASSIGNED !== null
        }
    }

    const urgentFilterTemplate = (options) => {
       // console.log(options);
        return (
            <Dropdown 
                value={options.value} 
                options={urgent} 
                optionLabel="label"
                optionValue="value"
                onChange={ (e) => options.filterCallback(e.value) } 
                itemTemplate={urgentItemTemplate} 
                placeholder="Select" className="p-column-filter" showClear />
        );
    }

    const urgentItemTemplate = (option) => {
        return (
            <span className={`customer-badge status-${option}`}>{option.label}</span>
        )
    }

    const statusFilterTemplate = (options) => {
        //console.log(options.value);
        return (
            <Dropdown 
                value={options.value} 
                options={requestStatuses} 
                onChange={(e) => options.filterCallback(e.value, options.index)} 
                itemTemplate={statusItemTemplate} 
                placeholder="Select a Status" 
                className="p-column-filter" showClear />
        )
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const typesFilterTemplate = (options) => {
        //console.log(options.value);
        return (
            <Dropdown 
                value={options.value} 
                options={types} 
                onChange={(e) => options.filterCallback(e.value, options.index)} 
                itemTemplate={typesItemTemplate} 
                placeholder="Select a Type" 
                className="p-column-filter" showClear />
        )
    }

    const typesItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={ rightToolbarTemplate }></Toolbar>

                    <DataTable ref={dt} value={dataList} rowGroupMode="subheader" groupRowsBy="_CAMPAIGN"
                        sortMode="single" sortField="_CAMPAIGN" sortOrder={1} responsiveLayout="scroll"
                        expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        rowGroupHeaderTemplate={headerTemplate}
                        header={header}
                        loading={loading }
                        rowClassName={ rowClass }
                        filters={filters2} filterDisplay="menu"
                        globalFilterFields={['_TYPE', '_DPUBLIC', 'urgent', '_STATUS', '_REGISTER', '_ASSIGNED', '_CONCEPT']}
                        
                    >
                            
                        {/*<Column field="_id" header="ID" body={ idBodyTemplate }></Column>*/}
                        <Column field="type"  filterField='types' filterMatchMode={FilterMatchMode.IN} showFilterMatchModes={false} filterElement={typesFilterTemplate} header="Type" body={ nameBodyTemplate }></Column>
                        <Column field="text" header="Text"  body={ startDatetBodyTemplate }></Column>
                        <Column field="publication"  header="Publication date" body={ endDateBodyTemplate }></Column>
                        <Column field="urgent"  filterField='urgent' filterMatchMode={FilterMatchMode.IN} showFilterMatchModes={false} filterElement={urgentFilterTemplate}  header="Urgent" body={ urgentBodyTemplate }></Column>
                        <Column field="status"  filterField='status'  filterElement={statusFilterTemplate} header="Status" body={ statusBodyTemplate }></Column>
                        <Column field="register"  header="Register by" body={ emailBodyTemplate }></Column>
                        <Column field="assigned" header="Assigned" body={ templateAssigned }></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '20%' }}></Column>

                    </DataTable>

                    <EditAdd
                        visible={ showAdd }
                        hide={ hideNew }
                        addItemList={ addItemList }
                        updateItemList={ updateItemList }
                        title={ modalTitle }
                        items={ item }
                        setLoading={ setLoading }
                        medios={ medios }
                        onItems={ setItem }
                        onAlert={ onAlert }
                        accounts={ accounts }
                        campaigns={ campaign }
                        social={ social }
                    />

                    <LeftSidebar
                        onVisible={ visibleLeft }
                        onHide={ closeViewData }
                        onWidth={ 560 }
                    >
                        <ViewRequest 
                            items={ item }
                            onEdit={ editData }
                            closeViewData={ closeViewData }
                            onDelete= { confirmDeleteData }
                            dataList={ dataList }
                            setDataList={ setDataList }
                            setAlert={ toastAlert }
                            setLoading={ setLoading }
                            onAlert={ onAlert }
                            setItems={ setItem }
                            campaigns={ campaign }
                            updateDesignerAssignment={updateDesignerAssignment}
                        />
                    </LeftSidebar>

                    <LeftSidebar
                        onVisible={ visibleStatus }
                        onHide={ closeViewstatus }
                        onWidth={ 450 }
                    >
                        <TimeLineRequests 
                            items={ allsStatus }
                            setLoading={ setLoading }
                            id={ dataId }
                            onAlert={ onAlert }
                            setDataList={ setDataList }
                            dataList={ dataList }
                            urgent={ isUrgent }
                            campaign={ campaignRequest }
                            onHide={ closeViewstatus }
                        />
                    </LeftSidebar>

                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Do you want to remove the <b>Request</b> from the campaign <b>{dataGlobal._CAMPAIGN}</b>?</span> }
                        </div>
                    </Dialog>

                    <Dialog visible={updateDataDialog} style={{ width: '450px' }} header="Update status!" modal footer={updateDataDialogFooter} onHide={hideUpdateDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Do you want to change the status of this user <b>{ dataGlobal._NAME }</b>?</span> }
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(RequestList, comparisonFn);