import React, { memo, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { CampaignsService } from '../../service/CampaignsService';
import { RequestService } from '../../service/RequestService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CountUp from 'react-countup';

function GridRequest() {
    const dataService = new RequestService();

    const [__external, setExternal] = useState(0);
    const [__internal, setInternal] = useState([]);

    useEffect( () => {
        getData();
    }, [])

    const getData = async () => {
        try {         
           // setLoading(true);
            const datas = { _STATUS: 'SAVED' };
            await dataService.getList(datas).then(data => {
                const external = data.filter((item) => item._TYPE === 'EXTERNAL');
                setExternal(external);

                const internal = data.filter((item) => item._TYPE !== 'EXTERNAL');
                console.log(internal);
                
                //setDataList(data);
                //setLoading(false);
            });
        } catch (error) {
            //setLoading(false);
            //toastAlert(error.variant, error.data, toast);
        }
    }

    /*const _internal = (
        (__internal !== undefined) ? 
            __internal.map( (r, i) => {
                return r;
            })
            : null
    );*/

    const cats = __internal.reduce(function (r, a) {
        /*r[a._IDCAMPAIGN] = r[a._IDCAMPAIGN] || [];
        r[a._IDCAMPAIGN].push(a);*/
        return a;
    }, Object.create(null));

      console.log(cats);

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">

                    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 className="">Campaign Group</h5>
                    </div>

                    <div class="border-1 p-4 mb-3 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer surface-card surface-border">
                        <div class="flex align-items-start mr-0 md:mr-8">
                            <div>
                                <div class="font-medium text-900">External Design Requests</div>
                            </div>
                        </div>
                        <div class="ml-0 md:ml-8 mt-2 md:mt-0 border-1 p-1 px-2 border-round flex align-items-center surface-100 text-600 surface-border">
                            <i class="pi pi-users mr-2"></i><span><CountUp end={ __external.length } duration={0.5} /> </span>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(GridRequest, comparisonFn);