import React, { useState, useEffect, useRef, memo } from 'react';
import ViewRequest from '../ViewData';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import LeftSidebar from '../../../components/LeftSidebar';
import { FormatService } from '../../../service/FormatService';
import { toastAlert } from '../../../core/utils';
import { RequestService } from '../../../service/RequestService';
import { AccountService } from '../../../service/AccountService';
import { CampaignsService } from '../../../service/CampaignsService';
import { SocialService } from '../../../service/SocialService';
import TimeLineRequests from '../../../components/TimeLineRequests';

function ApprovalsList() {
    const dataService     = new RequestService();
    const mediosService   = new FormatService();
    const accountService  = new AccountService();
    const campaignService = new CampaignsService();
    const socialService   = new SocialService();

    const [dataList, setDataList] = useState([]);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [updateDataDialog, setUpdateDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [modalTitle, setModalTitle] = useState("Add");
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(null);
    const [medios, setMedios] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [campaign, setCampaign] = useState([]);
    const [visibleStatus, setVisibleStatus] = useState(false);
    const [allsStatus, setAllsStatus] = useState([]);
    const [social, setSocial] = useState([]);
    const [isUrgent, setIsUrgent] = useState(false);
    const [campaignRequest, setCampaignRequest] = useState(null);
    
    useEffect(() => {
        getData();
        getMedios();
        getAccounts();
        getSocial();
        getCampaignsRequest();
    }, []);

    const getData = async () => {
        try {         
            setLoading(true);
            const datas = { _STATUS: 'SENT FOR REVIEW' };
            await dataService.getList(datas).then(data => {
                const arr = new Array();
                Object.values(data).map( (obj) => {
                    arr.push(obj);
                });
                setDataList(arr);
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const getMedios = async () => {
        try {
            await mediosService.getList().then( data => {
                setMedios(data);
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const getAccounts = async () => {
        try {
            await accountService.getList().then( data => {
                setAccounts(data);
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const getCampaignsRequest = async() => {
        try {
            await campaignService.getRequestCampaign().then( response =>  {
                setCampaign(response.items);
            });
        } catch (error) {
            onAlert(error.variant, error.data);
        }
    }

    const getSocial = async() => {
        try {
            await socialService.getList().then( response => {
                setSocial(response);
            });
            
        } catch (error) {
            onAlert(error.variant, error.data);
        }
    }

    const openNew = () => {
        setModalTitle("Add");
        setItem([]);
        setShowAdd(true);
    }

    const hideNew = () => {
        setShowAdd(false);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const hideUpdateDataDialog = () => {
        setUpdateDataDialog(false);
    }

    const confirmDeleteData = (data) => {
        setDataId(data._ID);
        setDataGlobal(data);
        setDeleteDataDialog(true);
    }

    const addItemList = (row) => {
        let data = [...dataList];
        data.splice(0, 0, row);
        setDataList(data);
    }

    const updateItemList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);

        data[index] = {
            _ID: row._ID,
            _IDCAMPAIGN: row._IDCAMPAIGN,
            _CAMPAIGN: row._CAMPAIGN,
            _TYPE: row._TYPE,
            _CONCEPT: row._CONCEPT,
            _LANGUAGES: row._LANGUAGES,
            _ACCOUNT: row._ACCOUNT,
            _DHANDLE: row._DHANDLE,
            _DPUBLIC: row._DPUBLIC,
            _CAPTION: row._CAPTION,
            _DESCRIPTION: row._DESCRIPTION,
            _RESOURCES: row._RESOURCES,
            _FORMATS: row._FORMATS,
            _STATUS: row._STATUS,
            _URGENT: row._URGENT,
            _REGISTER: row._REGISTER,
        };
        
        setDataList(data);
        
    }

    const removeItemList = (row) => {
        let data = [...dataList];
        data.splice(row, 1);
        setDataList(data);
    } 

    const deleteDataSelected = async() => {
        
        try {
            setLoading(true);
            setLoadingButton(true);
            await dataService.deleteData(dataId).then(data => {
                if (data._SUCCESS) {
                    removeItemList(dataGlobal);
                    setLoading(false);
                    setLoadingButton(false);
                    setDeleteDataDialog(false);
                    setVisibleLeft(false);
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setShowAdd(true);
    }

    const onStatusChange = async (e) => {
        try {
            setLoading(true);
            setLoadingButton(true);
            const data = {
                _ID:    dataId,
                _CHECK: checked
            }

            await dataService.changeStatus(data).then( data => {

                if (data._SUCCESS) {
                    setLoading(false);
                    setLoadingButton(false);
                    setUpdateDataDialog(false);
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
                
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }


    const viewData = async(data) => {
        setItem(data);
        setVisibleLeft(true);
    }

    const closeViewData = () => {
        setItem([]);
        setVisibleLeft(false);
    }

    const viewStatus = async(id, data, urgent, cpn) => {
        setDataId(id);
        setAllsStatus(data);
        setIsUrgent(urgent);
        setCampaignRequest(cpn);
        setVisibleStatus(true);
    }

    const closeViewstatus = () => {
        setDataId(null);
        setAllsStatus([]);
        setVisibleStatus(false);
    }

    const onAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={ openNew } />
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const idBodyCampaign = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                { (rowData._TYPE !== 'EXTERNAL') ? rowData._CAMPAIGN : "" }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className={ (rowData._TYPE === 'INTERNAL') ? `custom-badge bg-bluegray-500` : `custom-badge bg-teal-500` } >
                    { rowData._TYPE }
                </span>
            </>
        );
    }

    const startDatetBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                { rowData._DHANDLE }
            </>
        );
    }

    const endDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                { rowData._DPUBLIC }
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        const status     = rowData._STATUS;
        const allStatus  = status.request_status;
        const lastStatus = allStatus[ allStatus.length - 1 ];

        return (
            <>
                <span className="p-column-title">Status</span>
                <Button label={ lastStatus.status } className="p-button-text" onClick={ () => viewStatus(rowData._ID, allStatus, rowData._URGENT, rowData._CAMPAIGN) } />
            </>
        )
    }

    const urgentBodyTemplate = (rowData) => {
        let className;
        let text;

        if ( rowData._URGENT === true ) {
            className = "text-orange-600";
            text = 'YES';
        } else {
            className = "text-green-600";
            text = 'NO';
        }

        return (
            <> 
                <div className={ className }>
                    { text }
                </div>
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Register</span>
                { rowData._REGISTER }
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-eye"
                    id={ rowData._ID }
                    className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                    tooltip='Show'
                    tooltipOptions={{ position: 'top' }}
                    onClick={ () => viewData(rowData) }
                />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">To be approved Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );

    
    const updateDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideUpdateDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ onStatusChange } />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable ref={dt} value={dataList}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} request"
                        globalFilter={globalFilter} emptyMessage="No request found." header={header} responsiveLayout="scroll" loading={loading}>
                        <Column field="_id" header="ID" body={ idBodyTemplate }></Column>
                        <Column field="_campaign" header="Campaign" body={ idBodyCampaign }></Column>
                        <Column field="type" header="Type" body={ nameBodyTemplate }></Column>
                        <Column field="handle" header="Handle date" body={ startDatetBodyTemplate }></Column>
                        <Column field="publication" header="Publication date" body={ endDateBodyTemplate }></Column>
                        <Column field="urgent" header="Urgent" body={ urgentBodyTemplate }></Column>
                        <Column field="status" header="Status" body={ statusBodyTemplate }></Column>
                        <Column field="register" header="Register by" body={ emailBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } ></Column>
                    </DataTable>

                    <LeftSidebar
                        onVisible={ visibleLeft }
                        onHide={ closeViewData }
                        onWidth={ 560 }
                    >
                        <ViewRequest 
                            items={ item }
                            onEdit={ editData }
                            onDelete= { confirmDeleteData }
                            dataList={ dataList }
                            setDataList={ setDataList }
                            setAlert={ toastAlert }
                            setLoading={ setLoading }
                            onAlert={ onAlert }
                            setItems={ setItem }
                            campaigns={ campaign }
                            toApproval={ true }
                        />
                    </LeftSidebar>

                    <LeftSidebar
                        onVisible={ visibleStatus }
                        onHide={ closeViewstatus }
                        onWidth={ 450 }
                    >
                        <TimeLineRequests 
                            items={ allsStatus }
                            setLoading={ setLoading }
                            id={ dataId }
                            onAlert={ onAlert }
                            setDataList={ setDataList }
                            dataList={ dataList }
                            urgent={ isUrgent }
                            campaign={ campaignRequest }
                            isApproved={true}
                        />
                    </LeftSidebar>

                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Do you want to remove the <b>Request</b> from the campaign <b>{dataGlobal._CAMPAIGN}</b>?</span> }
                        </div>
                    </Dialog>

                    <Dialog visible={updateDataDialog} style={{ width: '450px' }} header="Update status!" modal footer={updateDataDialogFooter} onHide={hideUpdateDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Do you want to change the status of this user <b>{ dataGlobal._NAME }</b>?</span> }
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(ApprovalsList, comparisonFn);