import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown } from "primereact/dropdown";
import { Button } from 'primereact/button';
import SelectFormats from './SelectFormats';
import { iconsTemplate } from '../core/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SelectFormatSocial({ social, selectedFormSocial, setSelectedFormaSocial, onSocialMedia, onEdit }) {

    const [placeholder, setPlaceholder] = useState('Select a social network');

    const [__items, setItemsFormats] = useState([]);

    useEffect( () => {
        const size = onEdit.map( (x) => x.socialFormat).length;

        if (size > 0) {
            renderFormatsEdits();
        }
    }, []);

    const __social = social.map( (u, i) => {
        const data = [];

        return data[i] = {
            label: u._NAME, value: u._NAME
        };
    });

    const addFields = () => {
        let dataSocial = {
            socialMedia: "",
            socialFormat: ""
        }

        setSelectedFormaSocial([...selectedFormSocial, dataSocial]);
    }

    const removeFields = (index) => {
        let data = [...selectedFormSocial];
        data.splice(index, 1);
        setSelectedFormaSocial(data);
    }

    const handleSocial = (e, index) => {
        onSocialMedia(e, index);
    } 

    const renderFormats = (value, index) => {
        let container = document.getElementById('child_two_' + index);
        
        const __selected = social.filter( (item) => item._NAME === value ).map( (formats, i) => formats._FORMATS )[0];
        
        const ele = (
            <SelectFormats 
                formats={ __selected }
                index={ index }
                formSocial={ selectedFormSocial }
                handleSocial= { (value, index) => { handleSocial(value, index) }}
            />
        );
        
        setTimeout(() => {
            setPlaceholder('Loading...');
        }, 100);

        setTimeout(() => {
            ReactDOM.render( ele, container );
        }, 400);
        
    }

    const renderFormatsEdits = () => {
        setPlaceholder('Loading...');
        selectedFormSocial.map( (socials, index) => {
            let container = document.getElementById('child_two_' + index);
            const __selected = social.filter( (item) => item._NAME === socials.socialMedia ).map( (formats, i) => formats._FORMATS )[0];
           
            const ele = (
                <SelectFormats 
                    formats={ __selected }
                    index={ index }
                    isEdit={ socials.socialFormat }
                    handleSocial= { (value, index) => { handleSocial(value, index) }}
                />
            );
            
            setTimeout(() => {
                ReactDOM.render( ele, container);
            }, 400);
        });

    }

    const optionTemplate = (option) => {

        const icon = iconsTemplate(option.label);
        
        return (
           
            <div className="flex align-items-center">
                {
                    (option.label === 'TIKTOK') ? 
                        <FontAwesomeIcon icon={['fab', 'tiktok']} /> :
                        <i className={ `pi` +' '+ icon }></i>
                }
                
                <div className='ml-2'>{option.label}</div>
            </div>
        );
    }

    const optionSelectedTemplate = (option, props) => {

        if (option) {

            const icon = iconsTemplate(option.label); 
            
            return (
                <div className="flex align-items-center">
                    {
                        (option.label === 'TIKTOK') ? 
                            <FontAwesomeIcon icon={['fab', 'tiktok']} /> :
                            <i className={ `pi` +' '+ icon }></i>
                    }
                    <div className='ml-2'>{option.label}</div>
                </div>
            );
        }

        return (
            <span>{ props.placeholder }</span>
        );
    }

    return (
        <React.Fragment>
            <div className="p-fluid formgrid grid">
                <div className="field col-6">
                    <h5>Social media & formats</h5>
                </div>
                <div className="field col-6">
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <span style={{ background: "#22C55E", borderRadius: '5px', padding: '5px', cursor: 'pointer', color: '#fff' }}  onClick={addFields}>
                            <i className="pi pi-plus" style={{ fontSize: '.8rem', color: "#fff" }}></i>
                            add
                        </span>
                    </div>
                </div>
            </div>

            <div className="p-fluid formgrid grid">
                {
                    (selectedFormSocial !== undefined && selectedFormSocial.length > 0 && selectedFormSocial !== null) ?
                        selectedFormSocial.map( (form, index) => {
                           
                            return (
                                <React.Fragment key={index} >
                                    <div className="field col-5">
                                        <label htmlFor="name">Social media</label>
                                        <Dropdown
                                            id={ `child_one_` + index }
                                            options={ __social }
                                            filter 
                                            name='socialMedia'
                                            optionDisabled={ (e) => selectedFormSocial.some( (item) => item.socialMedia === e.value ) }
                                            value={ form.socialMedia }
                                            optionLabel="label"
                                            optionValue="label"
                                            itemTemplate={ optionTemplate }
                                            valueTemplate={ optionSelectedTemplate }
                                            onChange={ (e) => { 
                                                handleSocial(e, index); 
                                                renderFormats(e.value, index); 
                                            }}
                                            placeholder="Choose..."
                                        />
                                    </div>

                                    <div className="field col-6">
                                        <label htmlFor="position">Formats</label>
                                        
                                        <div id={ `child_two_` + index } dangerouslySetInnerHTML={{ __html: form.socialMedia ? placeholder : `Select a social network` }}></div>
                                        
                                    </div> 

                                    <div className="field col-1 md:my-4" style={{ textAlign: 'right' }}>
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-text p-button-danger"
                                            id={ form._id }
                                            onClick={ (e) => { removeFields(index); }}>
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    : <div className="w-full text-center"><h5>Empty</h5></div>
                }

            </div>
        </React.Fragment>
    )
}

export default SelectFormatSocial;