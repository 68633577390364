import React from "react";
import Axios from "axios";

const Connection = Axios.create({
   //baseURL: 'http://localhost:8000/api',
     baseURL: 'https://www.itmktbckoi.appsluc.com/api',
    withCredentials: true,
});

Connection.interceptors.request.use(
    function(config) {
        const token = localStorage.getItem('token');

        if (token) {
            config.headers["Accept"] = "application/json";
            config.headers["Authorization"] = 'Bearer ' + token;
            config.headers["X-Requested-With"] =  "XMLHttpRequest";
            config.headers["Access-Control-Allow-Origin"] = "*";
        }

        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

Connection.interceptors.response.use(
    (response) => response,
    (error) => {

        if (error.message === "Network Error") {
            return Promise.reject({
                variant: "error",
                data: error.message
            });
        }

        if (error.response.status === 400) {
            return Promise.reject({
                variant: "error",
                data: error.message
            });
        } 

        if (error.response.status === 401) {

           // const authService = new AuthService();
            //authService.getLogout();
            localStorage.clear();
            window.location.replace("/");

            return Promise.reject({
                variant: "error",
                data: error.message
            });
        } 

        if (error.response.status === 403) {
            return Promise.reject({
                variant: "error",
                data: error.message
            });
        } 
        
        if (error.response.status === 404) {
            return Promise.reject({
                variant: "error",
                data: error.message
            });
        } 
        
        if (error.response.status === 422) {
 
            let errors  = error.response.data;

            const errorView = Object.keys( errors.errors ).map( ( key, i ) => {
                const errores = errors.errors[key];
                return (
                    <div key={i}>{ errores }<br/></div>
                );
            }); 

            return Promise.reject({
                variant: "error",
                data: errorView
            });
        } 

        if (error.response.status === 429) {
            return Promise.reject({
                variant: "error",
                data: error.message
            });
        } 

        if (error.response.status === 500) {
            return Promise.reject({
                variant: "error",
                data: error.message
            });
        } 

        if (error.response.status === 502) {
            return Promise.reject({
                variant: "error",
                data: error.message
            });
        } 

        if ( error.response.status === 419 ) {
            return Promise.reject({
                variant: "error",
                data: error.message + " Session Expired!"
            });
        }
        
        return Promise.reject(error);
    }
);

export default Connection;
