import Connection from "../core/ApiConnection";

export class RolService {

    async getList() {
        return await Connection.get('/rols').then(res => res.data.items);
    }

    async saveData(data) {
        return await Connection.post('/rols', data).then(res => res.data);
    }

    async updateDate(data) {
        return await Connection.post('/role-update', data).then(res => res.data); 
    }

    async deleteData(data) {
        return await Connection.delete('/rols/' + data).then(res => res.data);
    }

    async getPermissions(data) {
        return await Connection.post('/permissions/', data).then(res => res.data);
    }

    async showData(data) {
        return await Connection.get('/rols' + data).then(res => res.data);
    }

} 

