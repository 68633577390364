import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { CampaignsService } from "../../service/CampaignsService";
import ResourcesUpload from "../../components/ResourcesUpload";
import { Accordion, AccordionTab } from 'primereact/accordion';
import LeftSidebar from "../../components/LeftSidebar";
import DatePicker from "../../components/DatePicker";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Image } from 'primereact/image';
import AddResources from "../../components/AddResources";
import ButtonsActions from "../../components/ButtonsActions";
import { Can } from "../../components/PermissionGate";
import { ProgressBar } from "primereact/progressbar";
import ReactPlayer from "react-player";

function EditAdd({ title, items, setItems, onItems, medios, addItemList, updateItemList, visible, hide, setLoading, onAlert }) {

    const dataService = new CampaignsService(); //Call Service

    const [loadingButton, setLoadingButton] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [itemsMedios, setItemsMedios] = useState(null);
    const [dataFile, setDataFile] = useState([]);
    const [deleteDataDialogFile, setDeleteDataDialogFile] = useState(false);

    const [formResources, setFormResources] = useState([]);

    const [currentFiles, setCurrentFiles] = useState([]);

    const [__percentage, setPercentageProgress] = useState(0);

    const [__text, setTextModal] = useState('Name');

    const [dataFields, setDatafields] = useState({
        fields: {
            name: "",
            target: "",
            concept: "",
            objetive: "",
            start: "",
            end: ""
        }
    });

    useEffect(() => {
        if (title === "Edit") {
            setDatafields({
                fields: {
                    name: items._NAME || '',
                    target:  items._TARGET || '',
                    concept:  items._CONCEPT || '',
                    objetive: items._OBJETIVE || '',
                    start: items._START || '',
                    end: items._END || ''
                }
            });

            if ( items._RESOURCES !== null && items._RESOURCES !== undefined ) {
                setFormResources(items._RESOURCES);
            }

            if ( items._RRSS !== null && items._RRSS !== undefined ) {
                const rrss = items._RRSS;
                const medios = rrss.split(',');
                setItemsMedios(medios);
            }

            if ( items._FILES !== null && items._FILES !== undefined ) {
                setCurrentFiles(items._FILES);
            }
            
        } else {
            setDatafields({
                fields: {
                    name: "",
                    target: "",
                    media: "",
                    concept: "",
                    objetive: "",
                    start: "",
                    end: ""
                }
            });

            setItemsMedios(null);
            setCurrentFiles([]);
            setFormResources([]);
        }

    }, [items]);

    const __items = medios.map( ( m, i ) => {
        const data = [];
        const items = [];

        m._TYPES.map( (t, p) => (
            items[p]= {
                label: t.types, value: t.types 
            }
        ));

        return data[i] = {
            label: m._NAME, value: m._NAME,
            items
        };
    });

    let today = new Date();
    let minDate = new Date();
    minDate.getDate(today);

    const clearData = () => {
        setDatafields({
            fields: {
                name: "",
                target: "",
                media: "",
                concept: "",
                objetive: "",
                start: "",
                end: ""
            }
        });

        setItemsMedios(null);
        setSelectedFiles([]);
        setFormResources([]);

    }

    const convertDate = (e) => {
        const date   = e.value;
        const year   = date.getFullYear();
        const month  = String(date.getMonth() + 1).padStart(2, '0');
        const day    = String(date.getDate()).padStart(2, '0');
        const joined = [day, month, year].join('-');

        setDatafields({ fields: { ...dataFields.fields, [e.target.name]: joined } });
    }
    
    const sendData = async () => {

        setLoading(true);
        setLoadingButton(true); 

        const config = {
            onUploadProgress: progressEvent => {
                let { progress } = __percentage;
                progress = (progressEvent.loaded / progressEvent.total) * 100;
                setPercentageProgress(progress);
            }
        }

        let route;

        if (title === "Edit") {
            route = dataService.updateData;
            setTextModal('Updating');
        } else {
            route = dataService.saveData;
            setTextModal('Creating');
        }

        const filterResource = formResources.filter( item => item.type !== "" && item.resource !== "" );

        try {
            const formData = new FormData();

            formData.append('_ID', (title === 'Edit') ? items._ID : 0 );
            formData.append('_NAME', dataFields.fields.name);
            formData.append('_TARGET', dataFields.fields.target);
            formData.append('_MEDIA', (itemsMedios !== null) ? itemsMedios : '' );
            formData.append('_CONCEPT', dataFields.fields.concept);
            formData.append('_OBJETIVE', dataFields.fields.objetive);
            formData.append('_START', dataFields.fields.start);
            formData.append('_END', dataFields.fields.end);
            formData.append('_RESOURCES', JSON.stringify(filterResource) ); 

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("_FILES[]", selectedFiles[i]);
            } 

            await route(formData, config).then( data => {

                if ( data._SUCCESS ) {
                    hide();
                    clearData();

                    if ( data._OPERATION === "add" ) {
                        addItemList(data._ITEMS);
                    } else {
                        onItems(data._ITEMS);
                        updateItemList(data._ITEMS);
                    }

                    onAlert("success", data._MESSAGE);

                } else {

                    onAlert("warn", data._MESSAGE);
                }
            }).finally( () => {
                setPercentageProgress(0);
                setLoading(false);
                setLoadingButton(false);
            });

        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }

    }

    const confirmDeleteDataFile = (e) => {
        setDataFile(e);
        setDeleteDataDialogFile(true);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialogFile(false);
    }

    const deleteDataDialogFooterFile = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ () => deleteFiles() } />
        </>
    );

    const deleteFiles = async() => {
        setLoading(true);
        setLoadingButton(true); 

        const data = {
            _ID: items._ID,
            _FILE : dataFile.filename,
            _IDFILE: dataFile.pathId,
            _URLFILE: dataFile.pathUrl,
            _DOWNLOAD: dataFile.download
        }

        try {
            await dataService.deleteFile(data).then( response => {
                setLoading(false);
                setLoadingButton(false); 

                if ( response._MESSAGE ) {
                    hideDeleteDataDialog();
                    setDataFile([]);
                    setItems(response._ITEMS);
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('warn', response._MESSAGE);
                }
            });
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }  

    const deleteResources = async(resource) => {
        try {
            const data = {
                _ID: items._ID,
                _IDFILE: resource._id,
                _TYPE: resource.type,
                _RESOURCE: resource.resource
            }

            await dataService.deleteResource(data).then( response => {
                if ( response._MESSAGE ) {
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('success', response._MESSAGE);
                }
            });

        } catch (error) {
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    const RenderFiles = ({ files }) => {

        (files).map( (f, i) => {
            let fileExt = f.filename;
            const [ext, ...fileName] = fileExt.split('.').reverse();
            return (
                <div className="p-fluid formgrid grid p-3" key={i}>
                    <div className="flex align-items-center" >
                        {
                            (ext === 'mp4') ?
                                <ReactPlayer 
                                    url={ f.pathUrl } 
                                    width={100} 
                                    height={60} 
                                    style={{ borderRadius: '10px' }} 
                                    className="test" /> :
                                    <Image 
                                        preview alt={ f.filename } 
                                        role="presentation" 
                                        src={ f.pathUrl } width={80} className="border-round-sm" />
                        }
                        <span className="flex flex-column text-left ml-3">
                            { f.filename }
                            <small>{ new Date().toLocaleDateString() }</small>
                        </span>
                    </div>
                    
                    <Button 
                        type="button" 
                        icon="pi pi-times" 
                        className="p-button-outlined p-button-rounded p-button-danger ml-auto"  
                        onClick={ () => confirmDeleteDataFile(f) }
                    />
                </div>
            )
        })
    }

    return (
        <div>
            <LeftSidebar
                onVisible={ visible }
                onHide={ () => hide() }
                onWidth={ 600 }
            >
                <h2 className="text-color">Campaign {title}</h2>
                
                <Divider align="center" />

                <div className="p-fluid formgrid grid mt-3">
                    <div className="field col-6">
                        <label htmlFor="name" className="font-semibold">Name</label>
                        <InputText
                            id="name"
                            type="text"
                            name="name"
                            value={ dataFields.fields.name || '' }
                            required
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, name: e.target.value } }) }}
                        />
                    </div>

                    <div className="field col-6" >
                        <label htmlFor="target" className="font-semibold">Target</label>
                        <InputText
                            id="target"
                            type="text"
                            name="target"
                            value={ dataFields.fields.target || '' }
                            required
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, target: e.target.value } }) }}
                        />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="position" className="font-semibold">Media plan</label>
                        <MultiSelect  
                            options={ __items }  
                            optionLabel="label" 
                            optionGroupLabel="label" 
                            optionGroupChildren="items"
                            placeholder="Choose..." 
                            value={ itemsMedios || '' }
                            filter
                            onChange={ (e) => setItemsMedios(e.value) }
                        />
                    </div>

                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-12">
                        <label htmlFor="concept" className="font-semibold">Concept</label>
                        <InputTextarea 
                            rows={1} 
                            cols={3}  
                            value={ dataFields.fields.concept }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, concept: e.target.value } }) }} />
                        
                    </div>

                    <div className="field col-12">
                        <label htmlFor="objective" className="font-semibold">Objective</label>
                        <InputTextarea 
                            rows={1} 
                            cols={3}  
                            value={ dataFields.fields.objetive || '' }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, objetive: e.target.value } }) }} />
                    </div>

                </div>
                
                <div className="p-fluid formgrid grid">

                    <div className="field col-6">
                        <label htmlFor="concept" className="font-semibold">Start date</label>
                        <DatePicker 
                            onId="start"
                            onName="start"
                            onValue={ dataFields.fields.start || '' }
                            onChanges={ (e) => { convertDate(e) }}
                        />
                        
                    </div>

                    <div className="field col-6">
                        <label htmlFor="objective" className="font-semibold">End date</label>
                        <DatePicker 
                            onId="end"
                            onName="end"
                            onValue={ dataFields.fields.end || '' }
                            onChanges={ (e) => { convertDate(e) }}
                        />
                    </div>

                </div>

                <div className="p-fluid formgrid grid mb-6">
                    <div className="field col-12">
                        <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="mt-3">
                            <AccordionTab header="Online Resources">
                                <AddResources 
                                    formResources={ formResources }
                                    setFormResources={ setFormResources }
                                    onDeleteresource={ (values) => deleteResources(values) }
                                />
                            </AccordionTab>
                            <AccordionTab header="Files">
                                {
                                    (currentFiles.length > 0) ?
                                        (currentFiles).map( (f, i) => {
                                            let fileExt = f.filename;
                                            const [ext, ...fileName] = fileExt.split('.');
                                            return (
                                                <div className="p-fluid formgrid grid p-3" key={i}>
                                                    <div className="flex align-items-center" >
                                                        {
                                                            (ext === 'mp4') ?
                                                                <ReactPlayer 
                                                                    url={ f.pathUrl } 
                                                                    width={100} 
                                                                    height={60} 
                                                                    style={{ borderRadius: '10px' }} 
                                                                    className="test" /> :
                                                                    <Image 
                                                                        preview alt={ f.filename } 
                                                                        role="presentation" 
                                                                        src={ f.pathUrl } width={80} className="border-round-sm" />
                                                        }
                                                        <span className="flex flex-column text-left ml-3">
                                                            { f.filename }
                                                            <small>{ new Date().toLocaleDateString() }</small>
                                                        </span>
                                                    </div>
                                                    
                                                    <Button 
                                                        type="button" 
                                                        icon="pi pi-times" 
                                                        className="p-button-outlined p-button-rounded p-button-danger ml-auto"  
                                                        onClick={ () => confirmDeleteDataFile(f) }
                                                    />
                                                </div>
                                            )
                                        })
                                        : null
                                } 
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12">
                                        <ResourcesUpload 
                                            selectedFiles={ selectedFiles }
                                            setSelectedFiles={ setSelectedFiles }
                                            itemsFile={ items._FILES }
                                            inAlert={ (success, message) => onAlert(success, message) }
                                        />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>

                </div>

                {
                    Can('campaigns.create') ? 
                        <ButtonsActions
                            cancel={ () => hide() }
                            submit={ () => sendData() }
                            disabled={ loadingButton }
                            loading={ loadingButton }
                        /> : null
                }

            </LeftSidebar> 

            <Dialog visible={deleteDataDialogFile} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooterFile} onHide={hideDeleteDataDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    { dataFile && <span>Are you sure you want to delete <b>{ dataFile.filename }</b>?</span> }
                </div>
            </Dialog>

            <Dialog visible={loadingButton} style={{ width: '450px' }} showHeader={false} modal onHide={loadingButton}>
                <div className="text-center">
                    <div className="grid">
                        <div className="col-12">
                            {
                                __text &&
                                <p className="text-2xl p-4"> 
                                    {__text} campaign <b>{ dataFields.fields.name }</b>, please wait...
                                </p>
                            }
                        </div>
                        <div className="col-12">
                            <ProgressBar mode="indeterminate" className='w-full text-color h-1rem' ></ProgressBar>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EditAdd