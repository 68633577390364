import Connection from "../core/ApiConnection";

export class AssignmentService {
    async getList() {
        return await Connection.get('/assignments').then( res => res.data.items );
    }

    async saveData(data) {
        return await Connection.post('/assignments', data).then( res => res.data );
    }

    async updateData(data) {
        return await Connection.post('/assignments/reasign', data).then( res => res.data );
    }

    async deleteData(data) {
        return await Connection.delete('/assignments/' + data).then( res => res.data );
    }

    async getDesigners() {
        return await Connection.post('/users/designer').then( res => res.data );
    }

    async designUpload(data) {
        return await Connection.post('/assignments/upload', data).then( res => res.data );
    }

    async designDelete (data) {
        return await Connection.post('/assignments/delete/design', data).then( res => res.data );
    }

    async approveDesign(data) {
        return await Connection.post('/assignments/approve/design', data).then( res => res.data );
    }

    async addComments(data) {
        return await Connection.post('/assignments/add/comments', data).then( res => res.data );
    }

    async addDesigns(data) {
        return await Connection.post('/assignments/add/design', data).then( res => res.data );
    }

    async notifyDesigns(data) {
        return await Connection.post('/assignments/notify/design', data).then( res => res.data );
    }

    async updateDesigns(data) {
        return await Connection.post('/assignments/update/design', data).then( res => res.data );
    }

}