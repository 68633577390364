import Connection from "../core/ApiConnection";

export class FormatService {

    async getList() {
        return await Connection.get('/formats').then(res => res.data.items);
    }

    async saveData(data) {
        return await Connection.post('/formats', data).then(res => res.data);
    }

    async updateData(data) {
        return await Connection.put('/formats/' + data._ID, data).then(res => res.data);
    }

    async deleteData(data) {
        return await Connection.delete('/formats/' + data).then(res => res.data);
    }

    async deleteDimension(data) {
        return await Connection.post('/formats/delete-dimension', data).then(res => res.data);
    }
}
