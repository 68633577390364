import React, { useState, useEffect } from "react";
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import { RequestService } from "../../service/RequestService";
import { Accordion, AccordionTab } from 'primereact/accordion';
import LeftSidebar from "../../components/LeftSidebar";
import DatePicker from "../../components/DatePicker";
import { useAuth } from "../../hooks/useAuth";
import SelectFormatSocial from "../../components/SelectFormatSocial";
import AddResources from "../../components/AddResources";
import ButtonsActions from "../../components/ButtonsActions";
import { Can } from "../../components/PermissionGate";
import ResourcesUpload from "../../components/ResourcesUpload";
import ReactPlayer from "react-player";
import { Image } from 'primereact/image';
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { RadioButton } from 'primereact/radiobutton';
import { downloadFiles } from "../../core/utils";
import TraditionalMethods from "../../components/TraditionalMethods";
 
function EditAdd({ title, items, onItems, addItemList, updateItemList, visible, hide, setLoading, onAlert, accounts, campaigns = [], social, isResent }) {

    const dataService = new RequestService(); //Call Service

    const { 0:auth } = useAuth();

    const [loadingButton, setLoadingButton] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [formResources, setFormResources] = useState([]);
    const [selectedFormSocial, setSelectedFormaSocial] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [dataFile, setDataFile] = useState([]);
    const [deleteDataDialogFile, setDeleteDataDialogFile] = useState(false);
    const [__text, setTextModal] = useState('Name');
    const [isVideo, setIsVideo] = useState('NO');
    const [isDigital, setIsDigital] = useState('traditional');

    const [dataFields, setDatafields] = useState({ 
        fields: {
            type: "",
            campaign: "",
            account: "",
            languages: "",
            concept: "",
            description: "",
            caption: "",
            dhandle: "",
            dpublication: "",
            urgent: false,
            timeVideo: "",
            subTitleVideo: ""
        }
    }); 

    useEffect(() => {
        if (title === "Edit") {

            let language;
            if ( items._LANGUAGES !== undefined && items._LANGUAGES !== null) {
                const languages = items._LANGUAGES;
                language = languages.split(',');
            }

            setIsVideo(items._ISVIDEO);
            setIsDigital(items._TYPEFORMAT);

            setDatafields({
                fields: {
                    type: items._TYPE,
                    campaign: items._IDCAMPAIGN,
                    account: items._ACCOUNT,
                    languages: language,
                    concept: items._CONCEPT,
                    description: items._DESCRIPTION,
                    caption: items._CAPTION,
                    dhandle: items._DHANDLE || '',
                    dpublication: items._DPUBLIC ,
                    urgent: items._URGENT,
                    timeVideo: items._TIME,
                    subTitleVideo: items._SUBTITLE,
                }
            });

            setFormResources(items._RESOURCES);
            setSelectedFormaSocial(items._FORMATS);

            if ( items._FILES !== null && items._FILES !== undefined ) {
                setCurrentFiles(items._FILES);
            }
            
        } else {

            setIsVideo('NO');
            setIsDigital('traditional');
            setDatafields({
                fields: {
                    type: "",
                    campaign: "",
                    account: "",
                    languages: "",
                    concept: "",
                    description: "",
                    caption: "",
                    dhandle: "",
                    dpublication: "",
                    urgent: false,
                    timeVideo: "",
                    subTitleVideo: ""
                }
            });

            setFormResources([]);
            setCurrentFiles([]);
            setSelectedFormaSocial([]);
        }

    }, [items]);

    const __accounts = accounts.map( ( m, i ) => {
        const data = [];

        return data[i] = {
            label: m._NAME, value: m._NAME
        };
    });

    const __campaigns = campaigns.map( (c, i) => {
        const data = [];
        
        return data[i] = {
            label: c._NAME, value: c._ID
        }

    });

    let today = new Date();
    let minDate = new Date();
    minDate.getDate(today);

    const clearData = () => {
        setDatafields({
            fields: {
                type: "",
                campaign: "",
                account: "",
                languages: "",
                concept: "",
                description: "",
                caption: "",
                dhandle: "",
                dpublication: "",
                urgent: false
            }
        });
        
        setFormResources([
            {
                _id: "",
                type: "",
                resource: ""
            }
        ]);
        setSelectedFiles([]);
    }

    const convertDate = (e) => {
        const date   = e.value;
        const year   = date.getFullYear();
        const month  = String(date.getMonth() + 1).padStart(2, '0');
        const day    = String(date.getDate()).padStart(2, '0');
        const joined = [day, month, year].join('-');

        setDatafields({ fields: { ...dataFields.fields, [e.target.name]: joined } });
    }

    const handleSocialMedia = ( e, index ) => {
        const { name, value } = e.target;
        const list = [...selectedFormSocial];
        list[index][name] = value;

        setSelectedFormaSocial(list);
    }

    const sendData = async (__sent) => {

        setLoading(true);
        setLoadingButton(true);

        let route;

        if (title === "Edit") {
            route = dataService.updateData;
            setTextModal('Updating');
        } else {
            route = dataService.saveData;
            setTextModal('Creating');
        }

        const filterSocial = selectedFormSocial.filter( item => item.socialMedia !== "" && item.socialFormat !== "" );

        const filterResource = formResources.filter( item => item.type !== "" && item.resource !== "" );

        try {
            const formData = new FormData();

            formData.append( '_ID', (items._ID !== undefined) ? items._ID : 0 );
            formData.append( '_URGENT', new Boolean(dataFields.fields.urgent) );
            formData.append( '_IDCAMPAIGN', dataFields.fields.campaign );
            formData.append( '_TYPE', dataFields.fields.type );
            formData.append( '_CONCEPT', dataFields.fields.concept );
            formData.append( '_LANGUAGES', dataFields.fields.languages );
            formData.append( '_ACCOUNT', dataFields.fields.account );
            formData.append( '_DESCRIPTION', dataFields.fields.description );
            formData.append( '_CAPTION', dataFields.fields.caption );
            formData.append( '_HANDLE', dataFields.fields.dhandle );
            formData.append( '_PUBLICATION', dataFields.fields.dpublication );
            formData.append( '_RESOURCES', JSON.stringify(filterResource) ); 
            formData.append( '_TYPEFORMAT', isDigital ); 
            formData.append( '_FORMATS', JSON.stringify(filterSocial) );
            formData.append( '_REGISTER', auth._ID ); 
            formData.append( '_SENT', new Boolean(__sent !== undefined ? true : false ));
            formData.append( '_ISVIDEO', isVideo );
            formData.append( '_TIME', dataFields.fields.timeVideo );
            formData.append( '_SUBTITLE', dataFields.fields.subTitleVideo );

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("_FILES[]", selectedFiles[i]);
            } 

            await route(formData).then( data => {
                setLoading(false);
                setLoadingButton(false);

                if ( data._SUCCESS ) {
                    hide();
                    clearData();

                    if ( data._OPERATION === "add" ) {
                        addItemList(data._ITEMS);
                    } else {
                        onItems(data._ITEMS);
                        updateItemList(data._ITEMS);
                    }

                    onAlert("success", data._MESSAGE);

                } else {
                    onAlert("warn", data._MESSAGE);
                }
            }).finally( () => {
                setLoadingButton(false);
            });

        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }

    }

    const deleteResources = async(resource) => {
        
        try {
            const data = {
                _ID: items._ID,
                _IDFILE: resource._id,
                _TYPE: resource.type,
                _RESOURCE: resource.resource
            }

            await dataService.deleteResource(data).then( response => {
                if ( response._MESSAGE ) {
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('success', response._MESSAGE);
                }
            });

        } catch (error) {
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    const RequestTypes = [
        {label: 'INTERNAL', value: 'INTERNAL'},
        {label: 'EXTERNAL', value: 'EXTERNAL'}
    ];

    const RequestLanguages = [
        {label: 'ENGLISH', value: 'ENGLISH'},
        {label: 'SPANISH', value: 'SPANISH'}
    ];

    const isChecked = () => {
        return false && setDatafields({ fields: { urgent: "" } });
    }

    const deleteFiles = async() => {
  
        setLoadingButton(true); 

        const data = {
            _ID: items._ID,
            _FILE : dataFile.filename,
            _IDFILE: dataFile.pathId,
            _URLFILE: dataFile.pathUrl
        }

        try {
            await dataService.deleteFile(data).then( response => {
 
                setLoadingButton(false); 

                if ( response._MESSAGE ) {
                    hideDeleteDataDialog();
                    setDataFile([]);
                    onItems(response._ITEMS);
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('warn', response._MESSAGE);
                }
            });
        } catch (error) {
 
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }  

    const confirmDeleteDataFile = (e) => {
        setDataFile(e);
        setDeleteDataDialogFile(true);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialogFile(false);
    }

    const deleteDataDialogFooterFile = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ () => deleteFiles() } />
        </>
    );

    return (
        <div>
            <LeftSidebar
                onVisible={ visible }
                onHide={ () => hide() }
                onWidth={ 600 }
            >
                <div className="p-fluid formgrid grid">
                    <div className="field col-7">
                        <h2 className="text-color">Design Request {title}</h2>
                    </div>

                    <div className="field col-5 text-right">
                        <h5 className="font-semibold">Urgent?</h5>
                        <InputSwitch 
                            checked={ (dataFields.fields.type === 'EXTERNAL') ? isChecked() : dataFields.fields.urgent } 
                            disabled={ dataFields.fields.type === 'EXTERNAL' }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, urgent: e.target.value } }) } } 
                        />
                    </div>
                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-4">
                        <label htmlFor="name" className="font-semibold">Type</label>
                        <Dropdown
                            options={ RequestTypes }
                            value={ dataFields.fields.type }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, type: e.target.value } }) } }
                            placeholder="Choose..."
                        />
                    </div>

                    <div className="field col-4">
                        <label htmlFor="name" className="font-semibold">Campaign</label>
                        <Dropdown
                            options={ dataFields.fields.type === 'EXTERNAL' ? null : __campaigns }
                            filter 
                            value={ dataFields.fields.campaign }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, campaign: e.target.value } }) } }
                            disabled={ dataFields.fields.type === 'EXTERNAL' }
                            placeholder="Choose..."
                        />
                    </div>

                    <div className="field col-4" >
                        <label htmlFor="target" className="font-semibold">Languages</label>
                        <MultiSelect 
                            value={ dataFields.fields.languages } 
                            options={ RequestLanguages } 
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, languages: e.value } }) } } 
                            optionLabel="label" 
                            placeholder="Choose..." 
                            display="chip"
                        />
                    </div>
                    
                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-4">
                        <label htmlFor="name" className="font-semibold">Account</label>
                        <Dropdown
                            options={ __accounts }
                            value={ dataFields.fields.account }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, account: e.target.value } }) } }
                            placeholder="Choose..."
                        />
                    </div>

                    <div className="field col-4">
                        <label htmlFor="concept" className="font-semibold">Handle date</label>
                        <DatePicker 
                            onId="dhandle"
                            onName="dhandle"
                            onValue={ dataFields.fields.dhandle ? dataFields.fields.dhandle : '' }
                            onChanges={ (e) => { convertDate(e) }}
                        />
                    </div>

                    <div className="field col-4">
                        <label htmlFor="objective" className="font-semibold">Publication date</label>
                        <DatePicker 
                            onId="dpublication"
                            onName="dpublication"
                            onValue={ dataFields.fields.dpublication ? dataFields.fields.dpublication : '' }
                            onChanges={ (e) => { convertDate(e) }}
                        />
                    </div>
                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-12">
                        <label htmlFor="text" className="font-semibold">Text</label>
                        <InputTextarea 
                            rows={3} 
                            cols={3}  
                            value={ dataFields.fields.concept }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, concept: e.target.value } }) }} />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="objective" className="font-semibold">Description</label>
                        <InputTextarea 
                            rows={3} 
                            cols={3}  
                            value={ dataFields.fields.description }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, description: e.target.value } }) }} />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="objective" className="font-semibold">Caption</label>
                        <InputTextarea 
                            rows={3} 
                            cols={3}  
                            value={ dataFields.fields.caption }
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, caption: e.target.value } }) }} />
                    </div>

                    <div className="field col-12">
                        <h5>Is the request a video?</h5>

                        <div className="grid">
                            <div className="field col-2">
                                <div className="field-radiobutton">
                                    <RadioButton 
                                        inputId="no" 
                                        name="isvideo" 
                                        value="NO" 
                                        onChange={(e) => setIsVideo(e.value)} 
                                        checked={ isVideo === 'NO' } />
                                    <label htmlFor="no">NO</label>
                                </div>
                            </div>

                            <div className="field col-2">
                                <div className="field-radiobutton">
                                    <RadioButton 
                                        inputId="yes" 
                                        name="isvideo" 
                                        value="YES" 
                                        onChange={(e) => setIsVideo(e.value)} 
                                        checked={isVideo === 'YES'} />
                                    <label htmlFor="yes">YES</label>
                                </div>
                            </div>
                        </div>

                        {
                            (isVideo === 'YES') ?
                                <React.Fragment>
                                    <div className="grid surface-300 pt-3 pb-3 border-round">
                                        <div className="field col-6">
                                            <label htmlFor="videotime" className="block">Video Time</label>
                                            <InputText 
                                                id="videotime" 
                                                aria-describedby="videotime-help" 
                                                className="block"
                                                value={dataFields.fields.timeVideo}
                                                placeholder="20 seg."
                                                onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, timeVideo: e.target.value } }) } }
                                            />
                                            <small id="videotime-help" className="block">Add the duration for the video.</small>
                                        </div> 
                                        <div className="field col-6">
                                            <label htmlFor="videosubtitle" className="block">Video Subtitle</label>
                                            <InputText 
                                                id="videosubtitle" 
                                                aria-describedby="videosubtitle-help" 
                                                className="block"
                                                value={dataFields.fields.subTitleVideo}
                                                placeholder="Lorem Ipsum"
                                                onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, subTitleVideo: e.target.value } }) } }
                                            />
                                            <small id="videosubtitle-help" className="block">Add the subtitle for the video.</small>
                                        </div> 
                                    </div>
                                </React.Fragment>
                                : null
                        }
                    </div>

                    <div className="field col-12">
                        <h5>Is this request in Traditional or Digital format?</h5>

                        <div className="grid">
                            <div className="field col-3">
                                <div className="field-radiobutton">
                                    <RadioButton 
                                        inputId="traditional" 
                                        name="isDigital" 
                                        value="traditional" 
                                        onChange={(e) => { 
                                            setIsDigital(e.value) 
                                            setSelectedFormaSocial([])
                                        }}
                                        onClick={ () => setSelectedFormaSocial([]) }
                                        checked={ isDigital === 'traditional' } 
                                        disabled={ items._TYPEFORMAT === 'digital' }
                                    />
                                    <label htmlFor="traditional">TRADITIONAL</label>
                                </div>
                            </div>

                            <div className="field col-2">
                                <div className="field-radiobutton">
                                    <RadioButton 
                                        inputId="digital" 
                                        name="isDigital" 
                                        value="digital" 
                                        onChange={(e) => { 
                                            setIsDigital(e.value) 
                                            setSelectedFormaSocial([])
                                        }}
                                        checked={isDigital === 'digital'} 
                                        disabled={ items._TYPEFORMAT === 'traditional' }
                                    />
                                    <label htmlFor="digital">DIGITAL</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    

                {
                    ( isDigital === 'digital' || items._TYPEFORMAT === 'digital' || items._TYPEFORMAT === null ) ?
                        <SelectFormatSocial
                            social={ social }
                            selectedFormSocial={ selectedFormSocial }
                            setSelectedFormaSocial={ setSelectedFormaSocial }
                            onEdit={ items._FORMATS || [] }
                            onSocialMedia={ (values, index) => { handleSocialMedia( values, index ) }}
                        /> : 

                        <TraditionalMethods 
                            selectedFormSocial={ selectedFormSocial }
                            setSelectedFormaSocial={ setSelectedFormaSocial }
                        />
                }


                <Accordion multiple activeIndex={ activeIndex } onTabChange={ (e) => setActiveIndex(e.index)} className="mt-4">
                    <AccordionTab header="Online Resources">
                        <AddResources 
                            formResources={ formResources }
                            setFormResources={ setFormResources }
                            onDeleteresource={ (values) => deleteResources(values) }
                        />
                    </AccordionTab>

                    <AccordionTab header="Files">
                        {
                            (currentFiles.length > 0) ?
                                (currentFiles).map( (f, i) => {
                                    let fileExt = f.filename;
                                    const [ext, ...fileName] = fileExt.split('.').reverse();
                                    return (
                                        <div className="p-fluid formgrid grid p-3" key={i}>
                                            <div className="flex align-items-center" >
                                                {
                                                    ( ext === 'mp4' || ext === 'jpg' || ext === 'png' ) ?
                                                        ( ext === 'mp4' ?
                                                            <ReactPlayer 
                                                                url={ f.pathUrl } 
                                                                width={100} 
                                                                height={60} 
                                                                style={{ borderRadius: '10px' }} 
                                                                className="test" /> : 
                                                            <Image 
                                                                src={ f.pathUrl } 
                                                                alt={ fileExt } 
                                                                preview 
                                                                width={100} 
                                                                height={60} 
                                                                imageStyle={{ borderRadius: '10px' }} /> ) : 
                                                                
                                                            ( ext === 'pdf' ? 
                                                                <div className='cursor-pointer' onClick={ () => downloadFiles(f.pathUrl, f.filename) }>
                                                                    <i className="pi pi-file-pdf" style={{'fontSize': '3em'}}></i></div> : 
                                                                <div className='cursor-pointer' onClick={ () => downloadFiles(f.pathUrl, f.filename) }>
                                                                    <i className="pi pi-file-word" style={{'fontSize': '3em'}}></i></div> )
                                           
                                                }
                                                <span className="flex flex-column text-left ml-3">
                                                    { f.filename }
                                                    <small>{ new Date().toLocaleDateString() }</small>
                                                </span>
                                            </div>
                                            
                                            <Button 
                                                type="button" 
                                                icon="pi pi-times" 
                                                className="p-button-outlined p-button-rounded p-button-danger ml-auto"  
                                                onClick={ () => confirmDeleteDataFile(f) }
                                            />
                                        </div>
                                    )
                                })
                                : null
                        } 
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12">
                                <ResourcesUpload 
                                    selectedFiles={ selectedFiles }
                                    setSelectedFiles={ setSelectedFiles }
                                    itemsFile={ items._FILES }
                                    inAlert = { (success, message) => onAlert(success, message) }
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>

                <div className="grid mt-8 flex justify-content-end">
                    {
                        Can('requests.create') ? 
                            <ButtonsActions
                                cancel={ () => hide() }
                                submit={ () => sendData() }
                                disabled={ loadingButton }
                                loading={ loadingButton }
                                isResent={ true }
                                setSent={ (s) => sendData(s) }
                            /> : null
                    }

                </div>

            </LeftSidebar>

            <Dialog visible={deleteDataDialogFile} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooterFile} onHide={hideDeleteDataDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    { dataFile && <span>Are you sure you want to delete <b>{ dataFile.filename }</b>?</span> }
                </div>
            </Dialog>

            <Dialog visible={loadingButton} style={{ width: '450px' }} showHeader={false} modal onHide={loadingButton}>
                <div className="text-center">
                    <div className="grid">
                        <div className="col-12">
                            {
                                __text &&
                                <p className="text-2xl p-4"> 
                                    <b>{__text} request </b>, please wait...
                                </p>
                            }
                        </div>
                        <div className="col-12">
                            <ProgressBar mode="indeterminate" className='w-full text-color h-1rem' ></ProgressBar>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EditAdd