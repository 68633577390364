import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Toast } from "primereact/toast";
import { ModuleService } from "../../service/ModuleService";
import { Divider } from "primereact/divider";
import { stringToFirstUppercase, toastAlert } from "../../core/utils";
import ButtonsActions from "../../components/ButtonsActions";

function EditAdd({ title, items, onHide, onItems, addItemList, updateItemList, onAlert }) {

    const dataService = new ModuleService(); //Call Service

    const toast = useRef(null);
    const [selectedOption, setSelectedOption] = useState('');

    useEffect( () => {
        setSelectedOption(stringToFirstUppercase(items._MODULE));
    }, []);

    const clearData = () => {
        setSelectedOption('');
    }

    const handleChange = (e) => {
        let v = e.target.value || '';
        setSelectedOption(stringToFirstUppercase(v));
    }

    const sendData = async() => {
        try {
            const data = {
                _ID   : (title === "Edit") ? items._id : 0,
                _NAME : selectedOption,
            };

            await dataService.saveData(data).then( data => {
                if (data._SUCCESS) {
                    onHide();
                    clearData();

                    if ( data._OPERATION === "add" ) {
                        addItemList(data._ITEMS);
                    } else {
                        onItems(data._ITEMS);
                        updateItemList(data._ITEMS);
                    }

                    onAlert('success', data._MESSAGE, toast);
                }
            });
        } catch (error) {
            onAlert(error.variant, error.data, toast);
        }
    }

    return (
        <div>
            <h2 className="text-color">Modules {title}</h2>
            <Divider align="center" />
            <Toast ref={toast} />

            <div className="p-fluid formgrid grid mt-3">
                <div className="field col-12">
                    <label htmlFor="module" className="font-semibold">Module Name</label>
                    <InputText
                        id="module"
                        type="text"
                        name="module"
                        required
                        placeholder="Ex. Users"
                        value={ selectedOption || '' }
                        onChange={ handleChange }
                    />
                </div>
            </div>

            <ButtonsActions 
                cancel={ () => onHide() }
                submit={ () => sendData() }
            />
        </div>
    )
}

export default EditAdd;
