import Connection from "../core/ApiConnection";

export class RequestItService {
    async getList () {
        return await Connection.get('/requestit').then( res => res.data.items );
    }

    async saveData(data) {
        return await Connection.post('/requestit', data).then( res => res.data );
    }

    async updateData(data) {
        return await Connection.post('/requestit/update', data).then( res => res.data );
    }

    async deleteData(data) {
        return await Connection.delete('/requestit/' + data).then( res => res.data );
    }

    async searchUsersIt() {
        return await Connection.get('/user/it').then( res => res.data );
    }

    async deleteFile(data) {
        return await Connection.post('/requestit/delete-file', data).then( res => res.data );
    }

    async addRequestIt(data) {
        return await Connection.post('/requestit/add/request', data).then( res => res.data );
    }

    async updateDesigns(data) {
        return await Connection.post('/assignments/update/design', data).then( res => res.data );
    }

}