import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FormatService } from "../../service/FormatService";
import { toastAlert, createId }from "../../core/utils";
import LeftSidebar from "../../components/LeftSidebar";
import { Divider } from "primereact/divider";
import ButtonsActions from "../../components/ButtonsActions";

function EditAdd({ items, title, visible, hide, setLoading, setItems, addItemList, updateItemList, itemsDropdows }) {

    const formatService = new FormatService(); //Call Service

    const [formField, setFormField] = useState([]);

    const toast = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleFormChange = (event, index) => {
        let data = [...formField];
        data[index] = {
            _id: createId(),
            [event.target.name]: event.target.value
        }
        setFormField(data);
    }

    useEffect(() => {
        checkItems(items);
    }, [items]); 
    

    const checkItems = () => {
        if (title === "Edit") {

            setSelectedOption(items._NAME);

            if (items._DIMENSIONS !== undefined) {
                let data = [...formField];
                items._DIMENSIONS.map( ( form, index ) => {
                    data[index] = {
                        _id: form._id,
                        dimension: form.dimension,
                    };
                    setFormField(data);
                });
            } 
        } else {
            setFormField([]);
        }
    }

    const addFields = () => {
        let object = {
            _id: '',
            dimension: '',
        }

        setFormField([...formField, object]);
    }

    const removeFields = (index, e) => {
        let data = [...formField];
        data.splice(index, 1);

        if ( title === 'Edit' ) {
            removeDimension(e);
        }

        setFormField(data);
    }

    const dropdownValues = [
        { name: 'Post', value: 'Post' },
        { name: 'Story', value: 'Story' },
        { name: 'Cover Page', value: 'Cover Page' },
        { name: 'Reels', value: 'Reels' }
    ];

    const clearData = () => {
        setSelectedOption(null);
        setFormField([]);
    }

    const sendData = async () => {

        setLoading(true);
        setLoadingButton(true);

        try {

            let route;

            if ( title === "Edit" ) {
                route = formatService.updateData;
            } else {
                route = formatService.saveData;
            }
            
            const data = {
                _ID         : (title === "Edit") ? items._ID : 0,
                _NAME       : selectedOption,
                _DIMENSIONS : formField,
                _STATUS     : 1
            };

            await route(data).then( data => {

                setLoading(false);
                setLoadingButton(false);
                
                if (data._SUCCESS) {
                    hide();
                    clearData();

                    if ( data._OPERATION === "add" ) {
                        addItemList(data._ITEMS);
                    } else {
                        setItems(data._ITEMS);
                        updateItemList(data._ITEMS);
                    }

                    toastAlert('success', data._MESSAGE, toast);
                    
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }

    }

    const removeDimension = async (e) => {

        setLoading(true);
        setLoadingButton(true);

        try {
            const data = {
                _ID         : items._ID,
                _UUID       : e.target.id,
                _DIMENSIONS : e.target.value,
            };

            await formatService.deleteDimension(data).then( data => {
                setLoading(false);
                setLoadingButton(false);

                if (data._SUCCESS) {
                    hide();
                    clearData();
                    setItems(data._ITEMS);
                    updateItemList(data._ITEMS);

                    setLoading(false);
                    setLoadingButton(false);

                    toastAlert('success', data._MESSAGE, toast);
                    
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }

    }

    const modalDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={ () => { hide(); }  } />
            <Button label="Save" loading={loadingButton} icon="pi pi-check" className="p-button-text" onClick={ () => { sendData(); } } />
        </>
    );

    return (
        <div>
            <Toast ref={toast} />
            
            <LeftSidebar
                onVisible={ visible }
                onHide={ () => hide() }
                onWidth={ 400 }
            >
                <h2 className="text-color">Format {title}</h2>

                <Divider align="center" />
                <div className="p-fluid formgrid grid mt-3">
                    <div className="field col-12">
                        <label htmlFor="formats">Format</label>
                        <Dropdown
                            value={ selectedOption }
                            onChange={ (e) => setSelectedOption(e.value) }
                            options={ dropdownValues }
                            optionDisabled={ (e) => itemsDropdows.some( (item) => item === e.value ) }
                            optionLabel="name"
                            optionValue="value"
                            placeholder="Choose..."
                            disabled={ (title === 'Edit') ? true : false }
                        />
                    </div>
                </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label htmlFor="dimension" className="mb-2">Dimension</label>
                        <span style={{ background: "#22C55E", borderRadius: '5px', padding: '3px', cursor: 'pointer', color: '#fff' }} onClick={ () => { addFields(); }}>
                            <i className="pi pi-plus" style={{ fontSize: '.8rem', color: "#fff" }}></i>
                            add
                        </span>
                    </div>

                    {
                        (formField !== undefined && formField.length > 0 && formField !== null ) ?
                            formField.map((form, index) => {
                                return (
                                    <div key={index}>

                                        <div className="field mt-2">
                                            <div className="grid">
                                                <div className="col-10">
                                                    <InputText
                                                        required
                                                        name='dimension'
                                                        placeholder='1080 x 1080'
                                                        value={ form.dimension }
                                                        onChange={ event => handleFormChange(event, index) }
                                                        className='w-full'
                                                    />
                                                </div>
                                                <div className="col-2" >
                                                    <Button
                                                        icon="pi pi-trash"
                                                        className="p-button-text p-button-danger"
                                                        id={ form._id }
                                                        value={ form.dimension }
                                                        onClick={ (e) => { removeFields(index, e); }}>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="w-full text-center"><h5>No added formats</h5></div>
                    }
                
                <ButtonsActions 
                    cancel={ () => hide() }
                    submit={ () => sendData() }
                    disabled={ loadingButton }
                    loading={ loadingButton }
                />
            </LeftSidebar>

        </div>

    );
}

export default EditAdd;
