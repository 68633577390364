import Connection from "../core/ApiConnection";

export class AccountService {

    async getList() {
        return await Connection.get('/accounts').then(res => res.data.items);
    }

    async saveData(data) {
        return await Connection.post('/accounts', data).then(res => res.data);
    }

    async updateData(data) {
        return await Connection.put('/accounts/' + data._ID, data).then(res => res.data);
    }

    async deleteData(data) {
        return await Connection.delete('/accounts/' + data).then(res => res.data);
    }


}
