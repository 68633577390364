import React, { useState, useEffect, useRef, memo } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import EditAdd from './EditAdd';
import CampignView from './ViewData';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import LeftSidebar from '../../components/LeftSidebar';
import { CampaignsService } from '../../service/CampaignsService';
import { MediosService } from "../../service/MediosService";
import { toastAlert } from '../../core/utils'; 
import StatusTimeline from '../../components/StatusTimeline';
import { useAuth } from '../../hooks/useAuth';
import { Can } from '../../components/PermissionGate';
import { ProgressBar } from 'primereact/progressbar';

function CampaignsList() {
    const dataService = new CampaignsService();
    const mediosService = new MediosService();

    const [dataList, setDataList] = useState([]);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [updateDataDialog, setUpdateDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [modalTitle, setModalTitle] = useState("Add");
    const [loading, setLoading] = useState(false);
    const [medios, setMedios] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [visibleStatus, setVisibleStatus] = useState(false);
    const [allsStatus, setAllsStatus] = useState([]);
    const [__percentage, setPercentageProgress] = useState(0);
    const [_records, setRecords] = useState([]);
    const [filters2, setFilters2] = useState(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    const { 3:checkPermissions } = useAuth();
    
    useEffect(() => {
        getData();
        initFilters1();

        if ( checkPermissions.some( (item) => item === 'medios.read' ) ) {
            getMedios();
        }
        
    }, [checkPermissions]);

    const getData = async () => {
        try {
            
            setLoading(true);
            await dataService.getList().then(data => {
                const filter = data.filter( (item) => item._STATUS._STATUS !== "FINISHED" &&  item._STATUS._STATUS !== "CANCELED" );
                setDataList(filter);
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'urgent': { value: null, matchMode: FilterMatchMode.IN },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'types': { value: null, matchMode: FilterMatchMode.IN },
        });
        setGlobalFilterValue2('');
    }

    const getMedios = async () => {
        try {
            await mediosService.getList().then( data => {
                setMedios(data);
            });
        } catch (error) {
            toastAlert(error.variant, error.data, toast);
        }
    }

    const openNew = () => {
        setModalTitle("Add");
        setItem([]);
        setShowAdd(true);
    }

    const hideNew = () => {
        setShowAdd(false);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const hideUpdateDataDialog = () => {
        setUpdateDataDialog(false);
    }

    const confirmDeleteData = (data) => {
        setDataId(data._ID);
        setDataGlobal(data);
        setDeleteDataDialog(true);
    }

    const addItemList = (row) => {
        let data = [...dataList];
        data.splice(0, 0, row);
        setDataList(data);
    }

    const updateItemList = (row) => {
        let data = [...dataList];
    
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index] = {
            _ID: row._ID,
            _NAME: row._NAME,
            _TARGET: row._TARGET,
            _CONCEPT: row._CONCEPT,
            _OBJETIVE: row._OBJETIVE,
            _START: row._START,
            _END: row._END,
            _STATUS: row._STATUS,
            _RECORDS: row._RECORDS,
            _RESOURCES: row._RESOURCES,
            _FILES: row._FILES,
            _REGISTER: row._REGISTER
        };
        
        setDataList(data);
        
    }

    const removeItemList = (row) => { 
        let data = [...dataList];
        data.splice(row, 1);
        setDataList(data);
    } 

    const deleteDataSelected = async() => {

        const config = {
            onUploadProgress: progressEvent => {
                let { progress } = __percentage;
                progress = (progressEvent.loaded / progressEvent.total) * 100;
                setPercentageProgress(progress);
            }
        }
        
        setLoading(true);
        setLoadingButton(true);
        setDeleteDataDialog(false);

        try {
            await dataService.deleteData(dataId, config).then(data => {
                if (data._SUCCESS) {
                    removeItemList(dataGlobal);
                    
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }

            }).finally( () => {
                setPercentageProgress(0);
                setLoading(false);
                setLoadingButton(false);
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const editData = (data) => {
        setItem(data);
        setModalTitle("Edit");
        setShowAdd(true);
    }

    const viewData = async(data) => {
        setItem(data);
        setVisibleLeft(true);
    }

    const closeViewData = () => {
        setItem([]);
        setVisibleLeft(false);
    }

    const viewStatus = async(id, data, records) => {
        setDataId(id);
        setAllsStatus(data);
        setRecords(records);
        setVisibleStatus(true);
    }

    const closeViewstatus = () => {
        setDataId(null);
        setAllsStatus([]);
        setVisibleStatus(false);
    }

    const onAlert = (variant, message) => {
        toastAlert(variant, message, toast);
    }

    const rightToolbarTemplate = () => {

        return (
            <React.Fragment>
                {
                    (Can('campaigns.create')) ? 
                        <Button 
                            label="New" 
                            icon="pi pi-plus" 
                            className="p-button-success mr-2" 
                            onClick={ openNew } /> : <></>
                }
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                { rowData._NAME }
            </>
        );
    }

    const startDatetBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                { rowData._START }
            </>
        );
    }

    const endDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                { rowData._END }
            </>
        );
    }
    
    const statusBodyTemplate = (rowData) => {

        let statuses  = rowData._STATUS;
        let allStatus = statuses;
        let records   = rowData._RECORDS;

        return (
            <>
                <span className="p-column-title">Status</span>

                {
                    (Can('campaigns.status')) ? 
                        <Button 
                            label={ allStatus._STATUS } 
                            className="p-button-text" 
                            onClick={ () => viewStatus(rowData._ID, allStatus, records) }  
                        /> : <h6 className="text-color">{ allStatus.status }</h6>
                }
            </>
        )
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Register by</span>
                { rowData._REGISTER }
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {

        let statuses   = rowData._STATUS;
        let allStatus  = statuses;

        return (
            <div className="actions">
                {
                    Can('campaigns.read') ? 
                        <Button
                            icon="pi pi-eye"
                            id={ rowData._ID }
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Show'
                            tooltipOptions={{ position: 'top' }}
                            onClick={ () => viewData(rowData) }
                        /> : <></>
                }

                {
                    Can('campaigns.update') ? 
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            tooltip='Edit'
                            tooltipOptions={{ position: 'top' }}
                            //disabled={ (allStatus._STATUS === 'IN PROGRESS') ? true : false }
                            onClick={ () => editData(rowData) }
                        /> : <></>
                }

                {
                    Can('campaigns.delete') ? 
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                            tooltip='Delete'
                            tooltipOptions={{ position: 'top' }}
                            disabled={ (allStatus._STATUS !== 'SAVED') ? true : false }
                            onClick={ () => confirmDeleteData(rowData) }
                        /> : <></>
                }
            </div>
        );
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Campaigns Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );

    
    const updateDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideUpdateDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text"  />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={ rightToolbarTemplate }></Toolbar>

                    <DataTable ref={dt} value={dataList} stripedRows rowHover
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} campaigns"
                        filters={filters2} 
                        filterDisplay="menu" 
                        emptyMessage="No campaigns found." 
                        header={header} responsiveLayout="scroll" loading={loading}
                        globalFilterFields={['_NAME', '_START', '_END', '_REGISTER', '_STATUS']}
                    >
                        {/*<Column field="_id" header="#" body={ idBodyTemplate } style={{ width: '3%' }}></Column>*/}
                        <Column field="name" filterField='name' header="Name" body={ nameBodyTemplate }></Column>
                        <Column field="start" header="Start date" body={ startDatetBodyTemplate }></Column>
                        <Column field="end" header="End date" body={ endDateBodyTemplate }></Column>
                        <Column field="status" header="Status" body={ statusBodyTemplate }></Column>
                        <Column field="register" header="Register by" body={ emailBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '20%' }}></Column>
                    </DataTable>

                    <EditAdd
                        visible={ showAdd }
                        hide={ hideNew }
                        addItemList={ addItemList }
                        updateItemList={ updateItemList }
                        title={ modalTitle }
                        items={ item }
                        setItems={ setItem }
                        setLoading={ setLoading }
                        medios={ medios }
                        onItems={ setItem }
                        onAlert={ onAlert }
                    />
 
                    <LeftSidebar
                        onVisible={ visibleLeft }
                        onHide={ closeViewData }
                        onWidth={ 600 }
                    >
                        <CampignView 
                            items={ item }
                            onEdit={ editData }
                            onDelete= { confirmDeleteData }
                            dataList={ dataList }
                            setDataList={ setDataList }
                            setAlert={ toastAlert }
                            setLoading={ setLoading }
                            onAlert={ onAlert }
                            setItems={ setItem }
                        />
                    </LeftSidebar>

                    <LeftSidebar
                        onVisible={ visibleStatus }
                        onHide={ closeViewstatus }
                        onWidth={ 450 }
                    >
                        <StatusTimeline 
                            items={ allsStatus }
                            setLoading={ setLoading }
                            id={ dataId }
                            onAlert={ onAlert }
                            setDataList={ setDataList }
                            dataList={ dataList }
                            hide={ closeViewstatus }
                            records={ _records }
                        />
                    </LeftSidebar>

                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Are you sure you want to delete <b>{dataGlobal._NAME}</b>?</span> }
                        </div>
                    </Dialog>

                    <Dialog visible={updateDataDialog} style={{ width: '450px' }} header="Update status!" modal footer={updateDataDialogFooter} onHide={hideUpdateDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { dataGlobal && <span>Do you want to change the status of this user <b>{ dataGlobal._NAME }</b>?</span> }
                        </div>
                    </Dialog>

                    <Dialog visible={loadingButton} style={{ width: '450px' }} showHeader={false} modal onHide={loadingButton}>
                        <div className="text-center">
                            <div className="grid">
                                <div className="col-12">
                                    { dataGlobal && <p className="text-2xl p-4"> 
                                        Deleting campaign data <b>{ dataGlobal._NAME }</b>, please wait...
                                    </p> }
                                </div>
                                <div className="col-12">
                                    <ProgressBar mode='indeterminate' className='w-full text-color h-1rem' ></ProgressBar>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(CampaignsList, comparisonFn);