import React from 'react';
import { yearTemplate } from './core/utils';

export const AppFooter = (props) => {

    const handleOpenTab = () => {
        window.open("https://letusclaim.com/");
    }

    return (
        <div className="layout-footer">
            <img src={ props.layoutColorMode === 'light' ?
                    'favicon.ico' : 'favicon.ico' } alt="Logo" height="20" className="mr-2" />
            <span className="font-medium ml-2">Copyright © All rights reserved { yearTemplate() }. </span>
        </div>
    );
}
