import React, { useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';

function ResourcesUpload({ selectedFiles, setSelectedFiles, itemsFile, inAlert }) {

    const [totalSize, setTotalSize] = useState(0);

    const fileUploadRef = useRef(null);

    const onTemplateSelect = (e) => {
        let data = [...selectedFiles];

        for (let i = 0; i < e.files.length; i++) {  
            const element = e.files[i];
            data.push(element);
        }

        setSelectedFiles(data);
        filesSizes(data, e.files);
    }

    
    const filesSizes = (d, current) => {
        let _totalSize = 0;

        d.map( (f, i) => {
            _totalSize += f.size
        });

        setTotalSize(_totalSize);
    }
    
    const onTemplateRemove = (file, callback) => {
        let data = [...selectedFiles];
        let total = 0;

        for (let f = 0; f < data.length; f++) {
            const element = data[f];

            if ( element.name === file.name ) {
                data.splice(f, 1);

                total = (parseFloat(totalSize) - parseFloat(file.size));
            }
        }

        setTotalSize(total);
        setSelectedFiles(data);
        callback();

    }
    
    const onTemplateClear = () => {
        let data = [...selectedFiles];

        data.splice(0, data.length);

        setTotalSize(0);
        setSelectedFiles(data);
    }

    /*const beforeSelected = (file, props) => {
        console.log(props);
    }*/

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        const value = totalSize / 200000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {cancelButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 20 MB`} style={{width: '300px', height: '20px', marginLeft: 'auto'}}></ProgressBar>
            </div>
        );
    }

    const itemTemplate = (file, props) => {

        if (totalSize > 20000000) {
            inAlert("warn", 'Invalid file size, maximum upload size is 20 MB');
            onTemplateRemove(file, props.onRemove);
        }

        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '50%' }}>
                    <img alt={ file.name } role="presentation" src={ file.objectURL } width={80} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{ new Date().toLocaleDateString() }</small>
                    </span>
                </div>
                <Tag value={ props.formatSize } severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div> 
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Drag and Drop Image or Document(.doc, .pdf) Here</span>
            </div>
        )
    } 

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

    return (
            <div>
                <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                <FileUpload 
                    ref={ fileUploadRef } 
                    name="files[]" 
                    accept="video/*,image/*,.doc,.docx,.pdf" 
                    multiple 
                    maxFileSize={ 20000000 }
                    //onRemove={ onTemplateRemove }
                    onSelect={ onTemplateSelect } 
                    onError={ onTemplateClear } 
                    onClear={ onTemplateClear }
                    headerTemplate={headerTemplate} 
                    itemTemplate={ itemTemplate} 
                    emptyTemplate={emptyTemplate}
                    chooseOptions={chooseOptions} 
                    cancelOptions={cancelOptions} 
                />
            </div>
    )
}

export default ResourcesUpload;