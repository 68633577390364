import Connection from "../core/ApiConnection";

export class UserService {

    async getList() {
        return await Connection.get('/users').then(res => res.data.items);
    }

    async saveData(data) {
        return await Connection.post('/users', data).then(res => res.data);
    }

    async updateData(data) {
        return await Connection.put('/users/' + data._ID, data).then(res => res.data);
    }

    async deleteData(data) {
        return await Connection.delete('/users/' + data).then(res => res.data);
    }

    async changeStatus(data) {
        return await Connection.post('/users/status', data).then(res => res.data);
    }

    async showData(data) {
        return await Connection.get('/users/' + data).then(res => res.data);
    }

    async changeAvatar(data) {
        return await Connection.post('/users/avatar', data).then( res => res.data );
    }

    async changePassword(data) {
        return await Connection.post('/users/password', data).then( res => res.data );
    }

    async getDesigners() {
        return await Connection.post('/users/designer').then( res => res.data );
    }

    async updatePermissions(data) {
        return await Connection.post('/user-permissions', data).then( res => res.data );
    }

}
