import React, { useState, useEffect, useRef, useContext } from "react";
import { Card } from 'primereact/card'
import { Chart } from "primereact/chart";
import { CampaignsService } from "../service/CampaignsService";
import { Toast } from "primereact/toast";
import { toastAlert, yearTemplate } from "../core/utils";
import { RequestService } from "../service/RequestService";
import { AssignmentService } from "../service/AssignmentService";
import CountUp from 'react-countup';
import { Link } from "react-router-dom";

const Dashboard = (props) => {

    const dataService       = new CampaignsService();
    const requestService    = new RequestService();
    const assignmentService = new AssignmentService();

    const [ numCampaigns, setNumCampaigns ] = useState(0);
    const [ numRequestExternal, setNumRequestExternal ] = useState(0);
    const [ numRequestInternal, setNumRequestInternal ] = useState(0);
    const [ numCampaignsAssignments, setNumCampaignsAssignments ] = useState(0);
    const [ numCampaignsArchived, setNumCampaignsArchived ] = useState(0);

    const [chartData, setChartData] = useState({});

    const chart = useRef(null);

    const toast = useRef(null);

    useEffect( () => {
        getCampigns();
        getAssignment();
        getDashboard();
    }, []);

    const getLightTheme = () => {
        let basicOptions = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: .8,
            barThickness: 15,
            maxBarThickness: 10,
            barPercentage: 0.5,
            plugins: {
                legend: {
                    labels: {
                        color: '#A4A4A4',
                    }
                },
                title: {
                    display: true,
                    text: 'Design Requests - ' + yearTemplate(),
                    position: "top",
                    font:{
                        size: 24
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#A4A4A4'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#A4A4A4'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
            }
        };
       
        return {
            basicOptions,
        }
    }

    const { basicOptions } = getLightTheme();

    const getDashboard = async() => {
        try {
            await requestService.getDashboard().then( response => {

                setNumRequestExternal(response._EXTERNAL);
                setNumRequestInternal(response._INTERNAL);
            
                setChartData({
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    datasets: [
                        {
                            type: 'bar',
                            label: 'External',
                            backgroundColor: '#F97316',
                            borderRadius: 10,
                            borderSkipped: false,
                            data: Object.values(response._EXT),
                            fill: false,
                        },
                        {
                            type: 'bar',
                            label: 'Internal',
                            backgroundColor: '#22C55E',
                            borderRadius: 10,
                            borderSkipped: false,
                            data: Object.values(response._INT)
                        },
                        {
                            type: 'bar',
                            label: 'Assignments',
                            backgroundColor: '#A855F7',
                            borderRadius: 10,
                            borderSkipped: false,
                            data: Object.values(response._ASS)
                        },
                    ],
                });
            });
        } catch (error) {
            toastAlert( error.variant, error.data, toast );
        }
    }
    
    const getCampigns = async() => {
        try {
            await dataService.getList().then( response => {
                const archived = response.filter( (item) => item._STATUS._STATUS === "FINISHED" );
                setNumCampaigns(response.length);
                setNumCampaignsArchived(archived.length);
            });
        } catch (error) {
            toastAlert( error.variant, error.data, toast );
        }
    }

    const getAssignment = async() => {
        try {
            await assignmentService.getList().then( response => {
                setNumCampaignsAssignments(response.length);
            });
        } catch (error) {
            toastAlert( error.variant, error.data, toast );
        }
    }

    return (
        <div>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="surface-card shadow-2 border-round flex p-3 flex-column md:flex-row">
                        <div className="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3">
                            <div className="flex align-items-center mb-3">
                                <i className="pi pi-tags text-color text-xl mr-2"></i>
                                <span className="text-500 font-medium">Campaigns</span>
                            </div>
                            <span className="block text-900 font-medium mb-4 text-xl">
                                <div className='p-badge p-component p-badge-no-gutter'> 
                                    <CountUp style={{ fontSize: '0.9rem' }} end={ numCampaigns } duration={1.2} />
                                </div>
                            </span>
                            <div className="flex align-items-center">
                                <i className="pi pi-check text-green-500 text-base mr-2"></i>
                                <span className="text-green-500 font-medium ">
                                    <Link to="/modules/campaigns/" className="text-green-500 font-medium ">
                                        View All
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3">
                            <div className="flex align-items-center mb-3">
                                <i className="pi pi-image text-orange-500 text-xl mr-2"></i>
                                <span className="text-500 font-medium">Design Request</span>
                            </div>
                            <div className="flex justify-content-between">
                                <span className="text-900 font-medium mb-4 text-base">
                                    EXTERNAL: 
                                    <div className='p-badge p-component p-badge-no-gutter bg-orange-500 ml-1'> 
                                        <CountUp style={{ fontSize: '0.9rem' }} end={ numRequestExternal } duration={1.2} />
                                    </div>
                                </span>
                                <span className="text-900 font-medium mb-4 text-base">
                                    INTERNAL: 
                                    <div className='p-badge p-component p-badge-no-gutter bg-green-500 ml-1'> 
                                        <CountUp style={{ fontSize: '0.9rem' }} end={ numRequestInternal } duration={1.2} />
                                    </div>
                                </span>
                            </div>
                            <div className="flex align-items-center">
                                <i className="pi pi-check text-green-500 text-xl mr-2"></i>
                                <span className="text-green-500 font-medium ">
                                    <Link to="/modules/requests/"  className="text-green-500 font-medium ">
                                        View All
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3">
                            <div className="flex align-items-center mb-3">
                                <i className="pi pi-sliders-v text-cyan-500 text-xl mr-2"></i>
                                <span className="text-500 font-medium">Assignments</span>
                            </div>
                            <span className="block text-900 font-medium mb-4 text-xl">
                                <div className='p-badge p-component p-badge-no-gutter bg-cyan-500 ml-1'> 
                                    <CountUp style={{ fontSize: '0.9rem' }} end={ numCampaignsAssignments } duration={1.2} />
                                </div>
                            </span>
                            <div className="flex align-items-center">
                                <i className="pi pi-check text-green-500 text-xl mr-2"></i>
                                <span className="text-green-500 font-medium ">
                                    <Link to="/modules/assignments/"  className="text-green-500 font-medium ">
                                        View All
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="flex-auto p-3">
                            <div className="flex align-items-center mb-3">
                                <i className="pi pi-box text-purple-500 text-xl mr-2"></i>
                                <span className="text-500 font-medium">Archived</span>
                            </div>
                            <span className="block text-900 font-medium mb-4 text-xl">
                                <div className='p-badge p-component p-badge-no-gutter bg-purple-500 ml-1'> 
                                    <CountUp style={{ fontSize: '0.9rem' }} end={ numCampaignsArchived } duration={1.2} />
                                </div>
                            </span>
                            <div className="flex align-items-center">
                                <i className="pi pi-check text-green-500 text-xl mr-2"></i>
                                <span className="text-green-500 font-medium ">
                                    <Link to="/modules/archived/" className="text-green-500 font-medium ">
                                        View All
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <Card>
                        <Chart ref={chart} type="bar" data={chartData} options={basicOptions} />
                    </Card>
                </div>
            </div>

        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
