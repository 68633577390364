import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { CampaignsService } from '../../service/CampaignsService';
import ReactPlayer from 'react-player/lazy';
import { Chip } from 'primereact/chip';
import { saveAs } from 'file-saver';
import { downloadFiles } from '../../core/utils';

function CampignView({ items, setItems, onAlert }) {

    const dataService = new CampaignsService();

    const [loadingButton, setLoadingButton] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [deleteDataDialogFile, setDeleteDataDialogFile] = useState(false);
    const [deleteDataDialogResource, setDeleteDataDialogResource] = useState(false);
    const [dataFile, setDataFile] = useState([]);

    const deleteResources = async() => {
        try {
            const data = {
                _ID: items._ID,
                _IDFILE: dataFile._id,
                _TYPE: dataFile.type,
                _RESOURCE: dataFile.resource
            }

            await dataService.deleteResource(data).then( response => {
                if ( response._MESSAGE ) {
                    hideDeleteDataDialogResource();
                    setDataFile([]);
                    setItems(response._ITEMS);
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('success', response._MESSAGE);
                }
            });

        } catch (error) {
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    }

    const deleteFiles = async() => {
        const data = {
            _ID: items._ID,
            _IDFILE: dataFile._id,
            _FILE : dataFile.filename,
        }

        try {
            await dataService.deleteFile(data).then( response => {
                if ( response._MESSAGE ) {
                    hideDeleteDataDialog();
                    setDataFile([]);
                    setItems(response._ITEMS);
                    onAlert('success', response._MESSAGE);
                } else {
                    onAlert('success', response._MESSAGE);
                }
            });
        } catch (error) {
            setLoadingButton(false);
            onAlert(error.variant, error.data);
        }
    } 

    const confirmDeleteDataFile = (e) => {
        setDataFile(e);
        setDeleteDataDialogFile(true);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialogFile(false);
    }

    const confirmDeleteDataResource = (e) => {
        setDataFile(e);
        setDeleteDataDialogResource(true);
    }

    const hideDeleteDataDialogResource = () => {
        setDeleteDataDialogResource(false);
    }

    const deleteDataDialogFooterFile = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteFiles } />
        </>
    );

    const deleteDataDialogFooterResource = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialogResource } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteResources } />
        </>
    );

    const handleMedios = () => {
        if ( items._RRSS !== null && items._RRSS !== undefined ) {
            const rrss = items._RRSS;
            const medios = rrss.split(',');

            return (
                medios.map( (m, i) => (
                    <Chip label={ m } className="ml-2 mr-2 w-max" /> 
                ))
            )
        }
    }

    const campaignResources = (
        (items._RESOURCES !== undefined) ?
            Object.values(items._RESOURCES).map( (resource, x) => {
                return (
                    <li className="py-2 surface-border" key={x}>
                        <a 
                            className="
                                p-ripple 
                                cursor-pointer 
                                flex 
                                align-items-center 
                                justify-content-between 
                                hover:surface-200 
                                transition-colors 
                                transition-duration-150 
                                border-round p-2" 
                                onClick={ () => window.open(resource.resource, '_blank') }>
                            { 
                                (resource.type === "Video") ? 
                                <ReactPlayer url={ resource.resource } width={100} height={60} style={{ borderRadius: '10px' }} className="test" /> :
                                <Image src={ resource.resource } alt={ resource.resource } width={100} height={60} imageStyle={{ borderRadius: '10px' }} />
                            }
                                <div style={{ marginLeft: '20px' }}>
                                    <span className="block text-900 mb-1">{ resource._id }</span>
                                </div>
                                <i className="ml-auto mr-5 pi pi-chevron-right text-600 text-xl"></i>
                                <span role="presentation" className="p-ink" style={{ height: '255px', width: '255px', top: '-98.5px', left: '-89.5px' }}></span>
                        </a>
                    </li>
                )
            }) : null
    );

    const campaignFiles = (
        (items._FILES !== undefined) ?
            (items._FILES).map( (file, i) => {
                let fileExt = file.filename;
                const [ext, ...fileName] = fileExt.split('.').reverse();
                return (
                    <li className="py-2 surface-border" key={i}>
                        <a 
                            className="
                                p-ripple 
                                cursor-pointer 
                                flex 
                                align-items-center 
                                justify-content-between 
                                hover:surface-200 
                                transition-colors 
                                transition-duration-150 
                                border-round p-2"
                            >
                                {
                                    (ext === 'mp4') ?
                                        <ReactPlayer 
                                            url={ file.pathUrl } 
                                            width={100} 
                                            height={60} 
                                            style={{ borderRadius: '10px' }} 
                                            className="test" /> : 
                                        <Image 
                                            src={ file.pathUrl } 
                                            alt={ fileExt } 
                                            preview 
                                            width={100} 
                                            height={60} 
                                            imageStyle={{ borderRadius: '10px' }} />
                                }
                               
                                <div style={{ marginLeft: '20px' }}>
                                    <span className="block text-900 mb-1">{ file._id }</span>
                                </div>
                                
                                <div className='cursor-pointer' onClick={ () => downloadFiles(file.pathUrl, file.filename) }>
                                    <i className="ml-auto mr-5 pi pi-download text-600 text-xl"></i>
                                </div>
                                
                        </a>
                    </li>
                )
            }) : null
    );          
    
    return (
        <div>
            <div className="surface-0">
                <div className="font-medium text-3xl mb-3 text-color">Campaign Information</div>
               
                <ul className="list-none p-0 m-0">
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Name</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._NAME }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Target</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._TARGET }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Media plan</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ handleMedios() }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Concept</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" style={{ whiteSpace: "break-spaces" }}>{ items._CONCEPT }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Objective</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" style={{ whiteSpace: "break-spaces" }}>{ items._OBJETIVE }</div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Start date</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            { items._START }
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">End date</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            { items._END }
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">Register by</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{ items._REGISTER }</div>
                    </li>

                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <h5>Online Resources</h5>
                        <ul className="list-none p-0 m-0 w-full">
                            { campaignResources }
                        </ul>
                    </li>

                    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <h5>Files</h5>
                        <ul className="list-none p-0 m-0 w-full">
                            { campaignFiles }
                        </ul>
                    </li> 
                </ul>
            </div>

            <Divider className='m-0' />

            <Dialog visible={deleteDataDialogResource} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooterResource} onHide={hideDeleteDataDialogResource}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    { dataFile && <span>Are you sure you want to delete <b>{ dataFile.resource }</b>?</span> }
                </div>
            </Dialog>

            <Dialog visible={deleteDataDialogFile} style={{ width: '450px' }} header="Delete data!" modal footer={deleteDataDialogFooterFile} onHide={hideDeleteDataDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    { dataFile && <span>Are you sure you want to delete <b>{ dataFile.filename }</b>?</span> }
                </div>
            </Dialog>
        </div>
        
    )
}

export default CampignView;