import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Page404 from '../modules/auth/Page404';

function ProtectedRoute({ component: Component, ...rest }) {

    const token = localStorage.getItem('token');

    return (
        <Route
            {...rest}
            render={ (props) => {
                if ( token !== null ) {
                    return ( <Redirect to="/dashboard" /> );
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }
            }}
        />
    )
}

export default ProtectedRoute;