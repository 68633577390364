import React, { useState, useEffect, useRef, memo } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { separateString, toastAlert } from '../../core/utils';
import { AssignmentService } from '../../service/AssignmentService';
import { Badge } from 'primereact/badge';
import { Chip } from 'primereact/chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Can } from '../../components/PermissionGate';
import { useAuth } from '../../hooks/useAuth';
import DesignUpload from './DesignUpload';
import ViewRequest from './ViewRequest';
import DesignReasign from './DesignReasign';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Tag } from "primereact/tag";

function AssignmentsLists() {

    const dataService = new AssignmentService();
 
    const [dataList, setDataList] = useState(null);
    const [deleteDataDialog, setDeleteDataDialog] = useState(false);
    const [item, setItem] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [dataId, setDataId] = useState(null);
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [sidebarUpload, setSidebarUpload] = useState(false);
    const [visible, setVisible] = useState(false);
    const [designers, setDesigners] = useState([]);
    const [currentDesigner, setCurrentDesigner] = useState(null);
    const [filters2, setFilters2] = useState(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    const [expandedRows, setExpandedRows] = useState([]);

    const { 0:auth } = useAuth();

    useEffect(() => {
        getData();
        getDesigners();
        initFilters1();
    }, []);


    const getData = () => {
        try {
            setLoading(true);
            dataService.getList().then( data => {
                console.log(data);
                setDataList(data);
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            toastAlert(error.variant, error.data, toast);
        }
    }

    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'urgent': { value: null, matchMode: FilterMatchMode.IN },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'types': { value: null, matchMode: FilterMatchMode.IN },
        });
        setGlobalFilterValue2('');
    }

    const getDesigners = () => {
        try {
            dataService.getDesigners().then( data => {
                setDesigners(data);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const open = (data) => {
        setDataId(data._IDASSIGNMET);
        setCurrentDesigner(data._DESIGNER);
        setVisible(true);
    }

    const hide = () => {
        setVisible(false);
    }

    const openNew = (data) => {
        setItem(data)
        setVisibleLeft(true);
    }

    const hideNew = () => {
        setVisibleLeft(false);
    }

    const hideDeleteDataDialog = () => {
        setDeleteDataDialog(false);
    }

    const confirmDeleteData = (data) => {
        setDataId(data._IDASSIGNMET);
        setDeleteDataDialog(true);
    }

    const removeItemList = (row) => {
        let data = [...dataList];
        data.splice(row, 1);
        setDataList(data);
    }

    const updateDesignAssignment = (row) => {
        let data = [...dataList];
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index]._UPLOADED = row._UPLOADED;
        setDataList(data);
    }

    const updateDesigner = (row) => {
        let data = [...dataList];
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index]._DESIGNER = row._DESIGNER;
        setDataList(data);
    }

    const updateNotify = (row) => {
        let data = [...dataList];
        const index = dataList.map( function(x) { return x._ID; } ).indexOf(row._ID);
        data[index]._NOTIFY = row._NOTIFY;
        setDataList(data);
    }

    const deleteDataSelected = async() => { 
        
        try {
            setLoading(true);
            setLoadingButton(true);
            await dataService.deleteData(dataId).then(data => {
                
                setLoading(false);
                setLoadingButton(false);
                setDeleteDataDialog(false);

                if (data._SUCCESS) {
                    removeItemList(dataGlobal);
                    toastAlert("success", data._MESSAGE, toast);
                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });
            
        } catch (error) {
            setLoading(false);
            setLoadingButton(false);
            toastAlert(error.variant, error.data, toast);
        }
    }


    const idBodyTemplate = (rowData, props) => {
        let index = parseInt( props.rowIndex + 1 );
        return (
            <>
                <span className="p-column-title">ID</span>
                { index }
            </>
        );
    }

    const approveBodyTemplate = (rowData, prop) => {

        let up = "";
        let variant;
        let text;
        let icon;
        if (rowData._UPLOADED.length > 0) {
            rowData._UPLOADED.map( (u, i) => {
                if ( u._FILESTATUS.length > 0 ) {
                    let p = u._FILESTATUS.slice(-1);
                    up = p;

                    if (up[0] === "SAVED") {
                        text = "SAVED";
                        variant = "info";
                        icon = "pi pi-save";
                    } else if (up[0] === "APPROVED") {
                        text = "APPROVED";
                        variant = "success";
                        icon = "pi pi-check-circle";
                    } else {
                        text = "DISAPPROVED";
                        variant = "danger";
                        icon = "pi pi-times";
                    } 
                } 
            });
        }
        return (
            (up.length > 0) ? 
                <Tag value={text} icon={icon} severity={variant} className="text-sm"></Tag> : ''
        );
    }

    const textBodyTemplate = (rowData) => {
        
        return (
            <>
                <span className="p-column-title">text</span>
                { separateString(rowData._CONCEPT) }
            </>
        );
    }

    const registerBodyTemplate = (rowData) => {
        
        return (
            <>
                <span className="p-column-title">register</span>
                { rowData._REGISTER }
            </>
        );
    }

    const designerBodyTemplate = (rowData) => {
        
        return (
            <>
                <span className="p-column-title">designer</span>
                { rowData._DESIGNER }
            </>
        );
    }

    const assignerBodyTemplate = (rowData) => {
        
        return (
            <>
                <span className="p-column-title">assigner</span>
                { rowData._SUPERVISOR }
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        
        return (
            <>
                <span className="p-column-title">assigner</span>
                <span className='text-color'>{ rowData._DATEASSIGN }</span> 
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">

                <Button
                    tooltip='Re-asign'
                    tooltipOptions={{ position: 'top' }}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                    onClick={ () => open(rowData) }
                />

                <Button
                    tooltip='View'
                    tooltipOptions={{ position: 'top' }}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                    onClick={ () => openNew(rowData) }
                />

                {
                    Can('assignments.read') ?
                        <Button
                            tooltip='Design Upload'
                            tooltipOptions={{ position: 'top' }}
                            icon="pi pi-image"
                            className="p-button-rounded p-button-plain p-button-text mr-1 mb-1"
                            /*disabled={
                                (rowData._IDDESIGNER !== auth._ID) &&
                                (auth._ROLNAME !== ["Design Supervisor"]) &&
                                (auth._ROLNAME !== "Supervisor") &&
                                (auth._ROLNAME !== "Administrator")
                            }*/
                            onClick={ () => showModal(rowData) }
                        /> : null
                }

                {
                    Can('assignments.delete') ?
                        <Button
                            tooltip='Delete'
                            tooltipOptions={{ position: 'top' }}
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger p-button-text mr-1 mb-1"
                            onClick={ () => confirmDeleteData(rowData) }
                            disabled={ rowData._UPLOADED.length > 0 }
                        /> : <></>
                }
            </div>
        );
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Assignments Lists</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteDataDialogFooter = (
        <>
            <Button label="No" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ hideDeleteDataDialog } />
            <Button label="Yes" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ deleteDataSelected } />
        </>
    );

    const statusHeaderSaved = (data) => {
        let total = 0;

        for (let list of dataList) {
            if (list._CAMPAIGN === data) {
                total++;
            }
        }

        return (
            <React.Fragment>
                <div className="flex align-items-center flex-wrap">
                    <Chip className='mr-3' template={ 
                            <span className='p-chip p-component'> 
                                <FontAwesomeIcon icon={faUser}  className=' p-chip-icon' /> 
                                <span className='p-chip-text'>Total Assignments</span> 
                                <Badge value={`${total}`} className="ml-2" ></Badge>
                            </span> 
                        } 
                    />
                </div>
            </React.Fragment>
        )

    }

    const headerTemplate = (data) => {

        return (
            <React.Fragment>
                {
                    (data._IDCAMPAIGN !== null) ?
                        <React.Fragment>
                            <span className="font-semibold capitalize text-lg text-green-700">{data._CAMPAIGN}</span> 
                            <span>{statusHeaderSaved(data._CAMPAIGN)}</span>
                        </React.Fragment>
                        : 
                        <React.Fragment>
                            <span className="font-semibold capitalize text-lg text-blue-700">External Assignments</span>
                            <span>{statusHeaderSaved(data._CAMPAIGN)}</span>
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }

    const showModal = (data) => {
        setItem(data);
        setSidebarUpload(true);
    }

    const hideModal = () => {
        setSidebarUpload(false);
    }

    const rowClass = (rowData) => {
        return {
            'row-assigned': rowData._NOTIFY === true 
        }
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable value={dataList} rowGroupMode="subheader" groupRowsBy="_CAMPAIGN"
                        sortMode="single" sortField="_CAMPAIGN" sortOrder={1} responsiveLayout="scroll"
                        expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        rowGroupHeaderTemplate={headerTemplate}
                        header={header}
                        rowClassName={ rowClass }
                        loading={loading}
                        filters={filters2} 
                        filterDisplay="menu" 
                        globalFilterFields={['_CONCEPT', '_REGISTER', '_DESIGNER', '_SUPERVISOR', '_DATEASSIGN']}
                    >
                        {/*<Column field="_id" header="#" body={ idBodyTemplate } style={{ width: '10%' }}></Column>*/}
                        <Column field="formats" header="Text" style={{ width: '15%' }} body={ textBodyTemplate }></Column>
                        <Column field="formats" header="Register by" style={{ width: '15%' }} body={ registerBodyTemplate }></Column>
                        <Column field="formats" header="Assigned" style={{ width: '15%' }} body={ designerBodyTemplate }></Column>
                        <Column field="supervisor" header="Supervisor" style={{ width: '15%' }} body={ assignerBodyTemplate }></Column>
                        <Column field="status" header="Status"  body={ approveBodyTemplate }></Column>
                        <Column field="date" header="Date Assignment" style={{ width: '15%' }} body={ dateBodyTemplate }></Column>
                        <Column body={ actionBodyTemplate } style={{ width: '25%' }}></Column>
                    </DataTable>
                    
                    <Dialog visible={deleteDataDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDataDialogFooter} onHide={hideDeleteDataDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            <span>Do you want to remove this <b>Assignment</b>?</span> 
                        </div>
                    </Dialog>

                    <ViewRequest 
                        visible={visibleLeft}
                        hide={hideNew}
                        items={item}
                    />

                    <DesignUpload 
                        visible={sidebarUpload}
                        hide={ () => hideModal() }
                        items={ item || [] }
                        updateData={updateDesignAssignment}
                        updateNotify={updateNotify}
                    />

                    <DesignReasign
                        idRequest={dataId}
                        onVisible={visible}
                        onHide={hide}
                        updateDesignerAssignment={updateDesigner}
                        designers={ designers.items || [] }
                        currentDesigner={currentDesigner}
                    />

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default memo(AssignmentsLists, comparisonFn);
