import React, { useState, useRef, useEffect, useCallback } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { UserService } from "../../service/UserService";
import { toastAlert } from "../../core/utils";
import { useAuth } from "../../hooks/useAuth";
import ButtonsActions from "../../components/ButtonsActions";
import LeftSidebar from "../../components/LeftSidebar";

function EditAdd({ title, items, onItems, roles, addItemList, updateItemList, visible, hide, setLoading, onAlert }) {

    const dataService = new UserService(); //Call Service

    const toast = useRef(null);

    const { 1:setAuth } = useAuth(); 
    
    const [dropdownItem, setDropdownItem] = useState(null);
    const [dropdownItems, setDropdownItems] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    
    const [dataFields, setDatafields] = useState({
        fields: {
            name: items._NAME,
            lastname: "",
            email: "",
            phone: "",
            position: "",
            password: ""
        }
    });

    const header = <h6>Pick a password</h6>;

    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 6 characters</li>
            </ul>
        </React.Fragment>
    );

    useEffect(() => {
        itemsDropdown();

        if (title === "Edit") {
            setDatafields({
                fields: {
                    name: items._NAME,
                    lastname: items._LASTNAME,
                    email: items._EMAIL,
                    phone: items._PHONE,
                    position: items._POSITION,
                }
            });

            setDropdownItem(items._ROLNAME);
        } else {
            setDatafields({
                fields: {
                    name: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    position: "",
                }
            });

            setDropdownItem(null);
        }

    }, [items]);

    const itemsDropdown = async() => {
        if (roles !== undefined) {
            let data = [{...dropdownItems}];
            roles.map( (rols, index) => {
                data[index] = {
                    label: rols._NAME, value: rols._ID
                };
                setDropdownItems(data);
            });
        } 
    }

    const clearData = () => {
        setDatafields({
            fields: {
                name: "",
                lastname: "",
                email: "",
                phone: "",
                position: "",
                password: ""
            }
        });
        setDropdownItems(null);
    }

    const sendData = async () => {

        if ( roles !== undefined ) {
            setLoading(true);
        }

        let route;

        if (title === "Edit") {
            route = dataService.updateData;
        } else {
            route = dataService.saveData;
        }

        setLoadingButton(true); 
        try {
            const data = {
                _ID       : (title === "Edit") ? items._ID : 0,
                _NAME     : dataFields.fields.name,
                _LASTNAME : dataFields.fields.lastname,
                _EMAIL    : dataFields.fields.email,
                _PHONE    : dataFields.fields.phone,
                _POSITION : dataFields.fields.position,
                _PASSWORD : dataFields.fields.password,
                _ROL      : dropdownItem
            };

            await route(data).then( data => {
                setLoadingButton(false);
                if (roles !== undefined) {
                    setLoading(false);
                }
                
                if ( data._SUCCESS ) {
                    hide();
                    toastAlert("success", data._MESSAGE, toast);

                    if (roles !== undefined) {
                        clearData();
                        if ( data._OPERATION === "add" ) {
                            addItemList(data._ITEMS);
                        } else {
                            onItems(data._ITEMS);
                            updateItemList(data._ITEMS);
                        }
                    } else {
                        localStorage.setItem( 'user', JSON.stringify(data._ITEMS) );
                        setAuth( JSON.parse( localStorage.getItem('user') ) );

                        toastAlert("success", data._MESSAGE, toast);
                    }

                } else {
                    toastAlert("warn", data._MESSAGE, toast);
                }
            });

        } catch (error) {
            setLoadingButton(false);

            toastAlert(error.variant, error.data, toast);

            if (roles !== undefined) {
               setLoading(false);
            }
            
        }

    }

    const modalDialogFooter = (
        <>
            <Button label="Cancel" disabled={loadingButton} icon="pi pi-times" className="p-button-text" onClick={ () => { hide(); }  } />
            <Button label="Save" loading={loadingButton}  icon="pi pi-check" className="p-button-text" onClick={ () => { sendData(); } } />
        </>
    );

    return (
        <div>
            <LeftSidebar
                onVisible={visible}
                onHide={ () => hide() }
                onWidth={450}
            >
                <Toast ref={toast} />
                <h2 className="text-color mt-0">User {title}</h2> 
                <Divider align="center" />
                <div className="p-fluid formgrid grid">
                    <div className="field col-12">
                        <label htmlFor="name" className="font-semibold">Name</label>
                        <InputText
                            id="name"
                            type="text"
                            name="name"
                            value={dataFields.fields.name || ''}
                            required
                            placeholder="John"
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, name: e.target.value } }) }}
                        />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="lastname2" className="font-semibold">Lastname</label>
                        <InputText
                            id="lastname2"
                            type="text"
                            name="lastname"
                            value={dataFields.fields.lastname || ''}
                            required
                            placeholder="Doe"
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, lastname: e.target.value } }) }}
                        />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="email" className="font-semibold">Email</label>
                        <InputText
                            id="email"
                            type="email"
                            name="email"
                            value={dataFields.fields.email || ''}
                            required
                            placeholder="johndoe@letusclaim.com"
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, email: e.target.value } }) }}
                        />
                    </div>

                    <div className="field col-12" style={{ display: (title !== "Edit") ? "none" : "block" }}>
                        <label htmlFor="phone" className="font-semibold">Phone</label>
                        <InputText
                            id="phone"
                            type="text"
                            name="phone"
                            value={dataFields.fields.phone || ''}
                            required
                            placeholder="123456789"
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, phone: e.target.value } }) }}
                        />
                    </div>

                    <div className="field col-12" style={{ display: (title === "Edit") ? "none" : "block" }}>
                        <label htmlFor="password" className="font-semibold">Password</label>
                        <Password 
                            id="password"
                            type="password"
                            name="password"
                            required
                            toggleMask 
                            header={header}
                            footer={footer} 
                            placeholder='*********'
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, password: e.target.value } }) }}
                        />
                    </div>

                    <div className="field col-12" style={{ display: (roles === undefined) ? 'none' : 'block' }}>
                        <label htmlFor="position" className="font-semibold">Position</label>
                        <InputText
                            id="postion"
                            type="text"
                            name="postion"
                            value={dataFields.fields.position || ''}
                            required
                            placeholder="Chief Designer"
                            onChange={ (e) => { setDatafields({ fields: { ...dataFields.fields, position: e.target.value } }) }}
                        />
                    </div>

                    <div className="field col-12 mb-8" style={{ display: (roles === undefined) ? 'none' : 'block' }}>
                        <label htmlFor="position" className="font-semibold">Rol</label>
                        <Dropdown
                            {...dropdownItem}
                            id="rol"
                            name="rol"
                            value={ dropdownItem  || ''}
                            onChange={ (e) => setDropdownItem(e.value) }
                            options={dropdownItems}
                            optionLabel= "label"
                            optionValue="label"
                            required
                            placeholder="Choose..."></Dropdown>
                    </div>
                </div>
                
                <ButtonsActions 
                    cancel={ () => hide() }
                    submit={ () => sendData() }
                    loading={ loadingButton }
                    disabled={ loadingButton }
                />
            </LeftSidebar>

            

            


        </div>
    )
}

export default EditAdd;
