import React, { memo, useEffect, useState } from "react";
import { MultiSelect } from 'primereact/multiselect';

const SelectFormats = memo( ({ formats, index, handleSocial, isEdit }) => {
    const [selectedFormats, setSelectedFormats] = useState([]);

    useEffect( () => {
        setSelectedFormats(isEdit);
    }, []);

    const __items = formats.map( ( m, i ) => {
        const items = [];

        m.dimensions.map( (t, p) => {
            items[p] = {
                label: t.dimension, value: m.format + ' - ' + t.dimension 
            }
        });

        return  {
            name: m.format, value: m.format,
            items
        };

    });

    const handleFormtas = (e, i) => {
        handleSocial(e, i);
        setSelectedFormats(e.value);
    }

    return (
        <div>
           <MultiSelect  
                id={ index }
                options={ __items }  
                value={ selectedFormats }
                optionLabel="label" 
                name='socialFormat'
                optionGroupLabel="name" 
                optionGroupChildren="items"
                placeholder="Choose..."
                display='chip'
                onChange={ (e) =>{ handleFormtas(e, index); }}
            />
        </div>
    )
}, (prevProps, nextProps) => {
    // evaluate whether the props have changed and if
    // the component should update
    return true;
  });

export default SelectFormats;