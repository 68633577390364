import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { toastAlert }from '../../core/utils';
import { AssignmentService } from '../../service/AssignmentService';
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import ButtonsActions from '../../components/ButtonsActions';

function UpdateDesignUpload({ data, format, updateData, hide, uploadNotify, id, onAlert, closeExpand }) {

    const dataService = new AssignmentService();

    const [totalSize, setTotalSize] = useState(0);
    const toast = useRef(null);
    const fileUploadRef = useRef(null);
    const [upload, setUpload] = useState([]);
    const [__urlEnglish, setUrlEnglish] = useState('');
    const [__urlSpanish, setUrlSpanish] = useState('');
    const [fileName, setFileName] = useState('');
    const [_language, setLanguage] = useState([]);

    const [loadingButton, setLoadingButton] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect( () => {
        if ( data !== undefined ) {
            setFileName(data._FILENAME);
            setUrlEnglish(data._FILEURLENGLISH);
            setUrlSpanish(data._FILEURLSPANISH);
        }
    }, []);
    
    const sendData = async() => {
        setLoading(true);
        try {
            const datas = {
                _FILENAME: fileName,
                _ID: id,
                _FILEID: data._FILEID,
                _FILEURLENGLISH: __urlEnglish,
                _FILEURLSPANISH: __urlSpanish,
            }
            
            await dataService.updateDesigns(datas).then( res => {
                if ( res._SUCCESS ) {
                    let resp = res._ITEMS;
                    let up = resp._UPLOADED;
                    updateData(resp);
                    uploadNotify();
                    onAlert("success", res._MESSAGE);
                } else {
                    onAlert("warn", res._MESSAGE);
                }
            }).finally( () => {
                setLoading(false);
                hide();
                closeExpand();
            });
        } catch (error) {
            onAlert(error.variant, error.data);
        }
    }

    return (
        <div className='w-full'>
                <div className="field">
                    <label htmlFor="name" className="block">Design Name</label>
                    <span className="p-input-icon-left w-full">
                        <i className="pi pi-image text-pink-500" />
                        <InputText 
                            id="name"
                            value={fileName}
                            onChange={ (text) => setFileName(text.target.value) }
                            className="block w-full" 
                            placeholder='Design Campaign 001' 
                        />
                    </span>
                </div>

                {
                    (data._FILEURLENGLISH !== '') ? 
                        <div className={`field ${(data._FILEURLENGLISH !== "") ? 'mb-5' : ""}`}>
                            <label htmlFor="url" className="block">Url from Design <b>ENGLISH</b> </label>
                            <span className="p-input-icon-left w-full">
                                <i className="pi pi-globe text-pink-500" />
                                <InputText 
                                    id="url" 
                                    aria-describedby="url-help" 
                                    value={__urlEnglish}
                                    onChange={ (text) => setUrlEnglish(text.target.value) }
                                    className="block w-full" 
                                    placeholder='https://letusclaimcons.sharepoint.com' 
                                />
                            </span>
                            <small id="url-help" className="block">Enter the url of the design.</small>
                        </div> : null
                }

                {
                    (data._FILEURLSPANISH !== '') ? 
                        <div className="field mb-8">
                            <label htmlFor="url" className="block">Url from Design <b>SPANISH</b></label>
                            <span className="p-input-icon-left w-full">
                                <i className="pi pi-globe text-pink-500" />
                                <InputText 
                                    id="url" 
                                    aria-describedby="url-help" 
                                    value={__urlSpanish}
                                    onChange={ (text) => setUrlSpanish(text.target.value) }
                                    className="block w-full" 
                                    placeholder='https://letusclaimcons.sharepoint.com' 
                                />
                            </span>
                            <small id="url-help" className="block">Enter the url of the design.</small>
                        </div> : null
                }

            <ButtonsActions
                cancel={() => hide() }
                submit={ () => sendData() }
            />

            <Dialog visible={loading} style={{ width: '450px' }} showHeader={false} modal onHide={loading}>
                <div className="text-center">
                    <div className="grid">
                        <div className="col-12">
                            {
                                id &&
                                    <p className="text-2xl p-4"> 
                                        Updating record, please wait...
                                    </p>
                            }
                        </div>
                        <div className="col-12">
                            <ProgressBar mode="indeterminate" className='w-full text-color h-1rem' ></ProgressBar>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default UpdateDesignUpload;