import React from 'react';
import { useProvideModule } from '../../hooks/useModules';

function ViewData({ items, onEdit, onDelete, dataList, setDataList }) {

    const { 0:module } = useProvideModule();

    console.log(module);

    const permissions = (
        <React.Fragment>
            <div className="p-fluid formgrid grid mt-3">
                <p></p>
            </div>
        </React.Fragment>

    );

    return (
        <div>
            <h4>Role: { items._NAME }</h4>

            {
                permissions 
            }
        </div>
    )
}

export default ViewData;